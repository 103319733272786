import {createFetch} from "config/createFetch";

const getCurrentDivineNFT = async (thunkAPI) => {
  return createFetch("TWO", "v1/item/divine", "POST")
        .then(result => result.data)
        .catch(error => thunkAPI.rejectWithValue(error?.message || error))
}

const createNFTBuyOrder = async (thunkAPI, data) => {
   return createFetch("TWO", "v1/item/nft/order", "POST", { authorization:true}, data)
        .then(result => result)
        .catch(error => thunkAPI.rejectWithValue(error?.message || error))
}

export const divineShopService = {
    getCurrentDivineNFT,
    createNFTBuyOrder
}