import React from "react";
import DefaultLayout from "layouts/DefaultLayout";
import PageMain from "components/atoms/PageMain";
import WebsiteRightsContent from "./WebsiteRightsContent";
import Seo from "components/atoms/Seo";

import {png} from "assets/png";
import "./style.scss"

const PrivacyPolicy = () => {

    return (
        <DefaultLayout>
            <Seo
                title="Privacy - KOMPETE"
                description=""
            />
            <PageMain
                page="termsConditionDesktop"
                originalImage={png.HeroPrivacy}
                resizeImage={png.HeroPrivacyResized}
                title="PRIVACY POLICY"
                text={<>Learn more about how <br/> we collect and use your data</>}
                filter={true}
            />
            <WebsiteRightsContent>
                <h3>Privacy Policy</h3>
                <p>Last Revised On July 26, 2023</p>
                <p>
                    <b>Welcome to KOMPETE!</b> This Privacy Policy will explain what information we collect online, why
                    we collect it,
                    and the choices we offer to you.
                </p>
                <p>
                    This privacy policy (“Policy”) describes the data practices of Modernize Marketing LLC (“Company”
                    “we” or “us”), covering the online and other services under its control, including the websites
                    at&nbsp;<a href="https://www.kompete.game" rel="noreferrer" target="_blank">www.kompete.game</a>
                    &nbsp; and &nbsp;<a href="https://www.modernizegames.com" rel="noreferrer" target="_blank">
                    www.modernizegames.com</a> (the “Websites”), the online game known as KOMPETE (the “Game”) and all
                    other applications, content, smart contracts, tools, features, and functionality offered on or
                    through our Website, the Game, or any other related website, functionality or application
                    (collectively "Services").
                </p>
                <p>
                    Please read this Policy carefully. By using the Services, you are consenting to this Policy and the
                    processing of your data in the manner provided herein. If you do not agree with this Policy, please
                    do not use the Services, provided us with any personal information, or purchase any of our products.
                    This Privacy Policy was last updated on July 26, 2023.
                </p>

                <h5>1. INFORMATION WE COLLECT</h5>
                <p>
                    Personally identifiable information (“PII”) is information that can be used to identify or contact
                    you online or offline, such as your name, address, email, phone number, and payment information, or
                    data that is linked to such identifiers. The Services may collect PII when it is provided to us,
                    such as when you use our Services, sign up for updates and mailing lists, complete and submit
                    responses to our surveys, attempt to contact us, email us at legal@modernizegames.com, submit a
                    resume or job application, or connect with us on social media or one of our partners.
                </p>
                <p>
                    The Services and use thereof may also create or collect device-identifiable information (DII), such
                    as cookies, unique device and advertising identifiers, statistical identifiers, usernames, and
                    similar identifiers that are linkable to a browser or device. From these platforms, we may also
                    receive other information, such as your IP address, user agent, timestamps, precise and imprecise
                    geolocation, sensor data, apps, fonts, battery life information, and screen size.
                </p>
                <p>
                    We may also partner with third-parties that collect additional information (i.e. Epic Game Store,
                    Steam or Apple) – please note that we do not control the data processing or information collection
                    practices of these parties. For more information on how third parties use or otherwise process your
                    personal data, please read see the third-parties’ privacy policies.
                </p>
                <p>
                    Information we collect falls into one of two categories: “automatically collected” information and
                    “voluntarily provided information. “Automatically collected” information refers to any information
                    automatically sent by your devices in the course of accessing our Services. “Voluntarily provided”
                    information refers to any information you knowingly and actively provide us when using or
                    participating in any of our Services.
                </p>

                <b>Automatically Collected Information</b>

                <p>
                    When you visit our Services, our servers may automatically log the standard data provided by your
                    web browser. It may include your device’s Internet Protocol (IP) address, geolocation data, your
                    browser type and version, the pages you visit, the time and date of your visit, the time spent on
                    each page, the referring site, website elements clicked, and other details about your visit.
                </p>
                <p>
                    Additionally, if you encounter certain errors while using the Services, we may automatically collect
                    data about the error and the circumstances surroundings its occurrence. This data may include device
                    identifying information (“DII”), such as technical details about your device, what you were trying
                    to do when the error happened, and other technical information relating to the problem. You may or
                    may not receive notice of such errors, even in the moment they occur, that they have occurred, or
                    what the nature of the error is.
                </p>
                <p>
                    Please be aware that while this information may not be personally identifying information (“PII”) by
                    itself, it may be possible to combine DII and PII with other data to personally identify individual
                    persons.
                </p>
                <p>
                    When you visit our Websites or interact with our Services, we may automatically collect data about
                    your device, such as your device type or your operating system.
                </p>

                <b>Voluntarily Provided Information</b>

                <p>
                    We may ask for PII – for example, when you register an account, make a purchase, request support for
                    our Services, or when you otherwise contact us – which may include one or more of the following:
                </p>

                <ul>
                    <li>Name</li>
                    <li>Email Address</li>
                    <li>Username</li>
                    <li>Digital Asset Wallet Address</li>
                    <li>Other information necessary for Anti-Money Laundering (“AML”), Counter Financing of Terrorism
                        (“CFT”), and Know Your Customer (“KYC”) compliance purposes
                    </li>
                    <li>Any other information you choose to provide, such as when you fill in a form, file a support
                        ticket, provide screenshots, conduct a search, update or add information to your account,
                        respond to surveys, participate in promotions, correspond with us, or use other features of our
                        Services.
                    </li>
                </ul>
                <p>
                    In the preceding 12 months, we may have collected and/or shared the following categories PII:
                </p>

                <table>
                    <thead>
                    <tr>
                        <th>Category of Informatio</th>
                        <th>Types of Information</th>
                        <th>To whom we disclose the information for a business purpose</th>
                        <th>To whom we sell information</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Identifiers</td>
                        <td>Name, email address, IP address, username, digital asset wallet address</td>
                        <td></td>
                        <td>N/A</td>
                    </tr>
                    <tr>
                        <td>Geolocation data</td>
                        <td></td>
                        <td></td>
                        <td>N/A</td>
                    </tr>
                    </tbody>
                </table>

                <h5>2. HOW WE SHARE THE INFORMATION WE COLLECT</h5>

                <p>
                    We may disclose some of all of the above listed categories of PII and DII with companies, outside
                    organizations, and individuals in the following circumstances:
                </p>

                <ul>
                    <li>If we have your consent to do so;</li>
                    <li>With our parent, subsidiary, and/or affiliated entities;</li>
                    <li>With service providers for the purpose of enabling them to provide our Services, including IT
                        service providers, data storage, hosting and server providers, analytics, error loggers, debt
                        collectors, maintenance or problem-solving providers, professional advisors, and payment systems
                        operators;
                    </li>
                    <li>Advertising partners;</li>
                    <li>Our employees, contractors, and/or related entities;</li>
                    <li>Credit reporting agencies, courts, tribunals, and regulatory authorities, in the event you fail
                        to pay for goods or services we have provided to you;
                    </li>
                    <li>
                        Courts, tribunals, regulatory authorities, and law enforcement officers, as required by law, or
                        any obligations arising thereunder, or in connection with any actual or prospective legal
                        proceedings, or in order to establish, exercise, or defend our legal rights or those of a third
                        party;
                    </li>
                    <li>To prevent physical harm, financial loss, or fraud on our Services; and</li>
                    <li>An entity that buys, or to which we transfer, all or substantially all of our assets and
                        business.
                    </li>
                </ul>

                <h5>3. THIRD-PARTY INFORMATION</h5>
                <p>
                    We may combine voluntarily provided and automatically collected personal information that is
                    publicly available or that you provide to third parties that they share with us. For example, we may
                    obtain your Digital Asset Transaction History, as it relates to KOMPETE Tokens, from your wallet
                    provider.
                </p>

                <h5>4. COLLECTION AND USE OF INFORMATION</h5>
                <p>We may collect PII from you when you do any of the following on our Services: </p>

                <ul>
                    <li>Register for an account</li>
                    <li>Use a mobile device, personal computer, or web browser to use our Services</li>
                    <li>Contact us via email, social media, chat programs, or on any similar technologies</li>
                    <li>When you mention us on social media</li>
                </ul>
                <p>
                    We may use information for the following purposes, and PII will not be further processed in a manner
                    that is incompatible with these purposes:
                </p>
                <ul>
                    <li>To provide you with our Services</li>
                    <li>To enable you to customize or personalize your experience of our Services</li>
                    <li>To operate, improve, and develop our Services</li>
                    <li>To contact and communicate with you</li>
                    <li>To enable you to access and use our Services, associated interactive features, associated
                        applications, and associated social media or chat platforms
                    </li>
                    <li>To comply with our legal obligations and resolve any disputes that we may have</li>
                    <li>For security and fraud prevention, and to ensure that our sites and applications are safe,
                        secure, and used in line with our Terms of Service
                    </li>
                    <li>For other purposes as permitted by applicable law and not inconsistent with this Policy or any
                        other express statement we make at the point of collection of the PII
                    </li>
                </ul>

                <h5>5. SECURITY OF YOUR PERSONAL INFORMATION</h5>

                <p>
                    When we collect and process PII, and while we retain this information, we will protect it within
                    commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure,
                    copying, use, or modification.
                </p>
                <p>
                    Although we will implement and maintain practices to protect the personal information you provide to
                    us, we advise that no method of electronic transmission or storage is 100% secure, and no one can
                    guarantee absolute data security. We recommend that you do not use unsecure channels to communicate
                    sensitive or confidential information to us. Any information you transmit to us you do at your own
                    risk.
                </p>
                <p>
                    You are responsible for selecting any password, to the extent one is required, and its overall
                    security strength, ensuring the security of your own information within the bounds of our services.
                    For example, you are responsible for ensuring any passwords associated with accessing your personal
                    information and accounts are secure and confidential.
                </p>

                <h5>6. HOW LONG WE KEEP YOUR PERSONAL INFORMATION</h5>
                <p>
                    We keep your personal information only for as long as we need to. This time period may depend on
                    what we are using your information for, in accordance with this Privacy Policy. For example, if you
                    have provided us with personal information as part of creating an account with us, we may retain
                    this information for the duration your account exists on our system. If your personal information is
                    no longer required for this purpose, we will delete it or make it anonymous by removing all details
                    that identify you.
                </p>

                <h5>7. CHILDREN'S PRIVACY</h5>

                <p>
                    We do not aim any of our Services directly at children under the age of 13, and we do not knowingly
                    collect personal information about children under 13. If we become aware that we have unknowingly
                    collected information about a child under 13 years of age, we will make commercially reasonable
                    efforts to delete such information from our database.
                </p>
                <p>
                    If you are the parent or guardian of a child under 13 years of age who has provided us with their
                    personal information, you may contact us using the below information to request that it be deleted.
                    If Company ever does in the future collect, use or disclose personal information from your child, we
                    will endeavor to obtain parent of legal guardian’s consent before doing so or otherwise comply with
                    the law. If Company does collection information, no more information than is reasonably required to
                    participate in the activity shall be required to be disclosed. We will not keep personal information
                    for longer than necessary. Parents or legal guardians may also contact us to ask if we have
                    collected their child’s personal information, to review that information, request that Company stop
                    collection this information and have it deleted, and refuse to allow further collection or use of
                    the child’s information. Such requests are subject to Company verifying to our satisfaction that the
                    requester is in fact the child’s parent or legal guardian. A parent or legal guardian may contact
                    Company at&nbsp;<a href="mailto:legal@modernizegames.com">legal@modernizegames.com</a> to
                    make requests and review such information.
                </p>

                <h5>8. YOUR RIGHTS AND CONTROLLING YOUR PERSONAL INFORMATION</h5>
                <p>If you have previously agreed to us using your personal information and data, at any time you may
                    request to have your data deleted by contacting us at
                    <a href="mailto:legal@modernizegames.com"> legal@modernizegames.com</a>.
                </p>

                <h5>9. USE OF COOKIES AND TRACKING TECHNOLOGIES</h5>
                <p>
                    Our website, like most websites, uses cookies, pixels, and other tracking technologies (“Tracking
                    Technologies”) to collect information about you and your activity across our site. A cookie, for
                    example, is a small piece of data that our website stores on your computer, and accesses each time
                    you visit, so we can understand how you use our site. A pixel is a small portion of code that we use
                    as part of our Services. We use pixels to learn whether you have clicked on certain web content.
                    This helps us measure and improve our services and personalize your experience. Tracking
                    Technologies help us to improve our Services including by conducting statistical analyses,
                    estimating audience size and usage patterns, and identifying a user’s digital asset wallet address
                    and wallet provider.
                </p>
                <p>
                    You may control the way in which your devices permit the use of cookies. If you so choose, you may
                    block or delete our cookies from your browser; however, blocking or deleting cookies may cause some
                    of our Services to work incorrectly or you to be unable to access certain parts of our website.
                    Most browsers accept cookies automatically. However, you may be able to configure your browser
                    settings to use our Services without some cookie functionality. You can delete cookies manually or
                    set your browser to automatically delete cookies on a predetermined schedule. For example, in the
                    Chrome menu bar, select: More → Settings → Privacy and security → Cookies and other site data → See
                    all cookies and site data, where you can choose to allow or block all or certain cookies, and choose
                    to clear cookies when you quit Chrome.
                </p>

                <p>For more information on how to manage your browser settings, please see below:</p>
                <ul>
                    <li><a href="https://support.microsoft.com" rel="noreferrer" target="_blank">Cookie settings in
                        Internet Explorer</a>
                    </li>
                    <li><a href="https://privacy.microsoft.com" rel="noreferrer" target="_blank">Cookie settings in
                        Internet Explorer</a>
                    </li>
                    <li><a href="https://support.mozilla.org" rel="noreferrer" target="_blank">Cookie settings in
                        Firefox</a></li>
                    <li><a href="https://support.google.com" rel="noreferrer" target="_blank">Cookie settings in
                        Chrome</a></li>
                    <li><a href="https://support.apple.com" rel="noreferrer" target="_blank">Cookie settings in
                        Safari</a></li>
                </ul>

                <h5>10. CHOICE</h5>

                <p>
                    Like many other companies, we do not honor DNT flags but instead offer other choices with respect to
                    third-parties. Many third-parties participate in self-regulation to offer you a choice regarding
                    receiving targeted ads. Please note that you’ll still see generic ads after opting out, but they
                    won’t be based on your activities online. On the web, you can opt out of participating companies by
                    visiting the following sites:
                </p>
                <ul>
                    <li><a href="http://optout.networkadvertising.org/" rel="noreferrer"
                           target="_blank">http://optout.networkadvertising.org/</a></li>
                    <li><a href="http://optout.aboutads.info/" rel="noreferrer"
                           target="_blank">http://optout.aboutads.info/</a></li>
                    <li><a href="http://youronlinechoices.eu/" rel="noreferrer"
                           target="_blank">http://youronlinechoices.eu/</a></li>
                    <li><a href="https://tools.google.com/dlpage/gaoptout" rel="noreferrer"
                           target="_blank">https://tools.google.com/dlpage/gaoptout</a></li>
                    <li><a href="https://support.apple.com/en-us/HT202074 " rel="noreferrer"
                           target="_blank">https://support.apple.com/en-us/HT202074 </a></li>
                </ul>
                <p>
                    If you wish to similarly opt out of cross-app advertising on mobile devices, you can enable the
                    Limit Ad Tracking flag on the device. Enabling Limit Ad Tracking sends a flag to third-parties that
                    you wish to opt out of targeted advertising on that device, and major mobile platforms require
                    companies to honor this flag. Screenshots on how to find these options on various devices are
                    available here:<a href=" http://www.networkadvertising.org/mobile-choices" rel="noreferrer"
                                      target="_blank"> http://www.networkadvertising.org/mobile-choices</a>. To learn
                    how to opt out on other devices, please visit the platform’s privacy policies for more information.
                </p>

                <h5>11. BUSINESS TRANSFERS</h5>
                <p>
                    If we or our assets are acquired, or in the unlikely event that we go out of business or enter
                    bankruptcy or an insolvency proceeding, we would include data, including your personal information,
                    among the assets transferred to any parties who acquire us. You acknowledge that such transfers may
                    occur, and that any parties who acquire us may, to the extent permitted by applicable law, continue
                    to use your personal information according to this Privacy Policy, which they will be required to
                    assume as it is the basis for any ownership or use rights we have over such information.
                </p>

                <h5>12. THIRD PARTY WEBSITES AND LINKS</h5>
                <p>
                    Our Services may link to or interact with third party sites or services, such as hosting platforms
                    of the Game, marketplaces for in-Game assets, your digital wallet provider, or chat platforms that
                    are not operated by us. Our inclusion of such links or use of such services or platforms does not,
                    by itself, imply any endorsement of the content on such platforms or of their owners or operators
                    except as disclosed on our Services. After following a link to any third-party content, you should
                    read their posted privacy policy information about how they collect and use personal information.
                    Any information submitted by you directly to these third-parties is subject to that third-party’s
                    privacy policy. Please be aware that we have no control over the content and policies of those sites
                    and cannot accept responsibility or liability for their respective privacy practices. This Privacy
                    Policy does not apply to any of your activities after you leave our Services.
                </p>

                <h5>13. USER-GENERATED CONTENT</h5>
                <p>
                    Please note that we do not control who will have access to the information that you choose to make
                    available to others and cannot ensure that parties who have access to such information will respect
                    your privacy or keep it secure. We are not responsible for the privacy or security of any
                    information that you make publicly available on the features permitting creation of user generated
                    content (“UGC”) or what others do with information you share with them on such platforms. We are not
                    responsible for the accuracy, use, or misuse of any UGC that you disclose or receive from
                    third-parties through social media or discussions on our Services.
                </p>

                <h5>14. LOCATION OF SERVICE</h5>
                <p>
                    Our Services are directed to United States citizens. If you are outside of the United States, your
                    information will be processed and stored in the United States and you are consenting to such
                    transfer, processing, and storage under the laws of the United States.
                </p>

                <h5>15. CHANGE TO THIS POLICY</h5>
                <p>
                    Our Privacy Policy may change from time to time. We will post any Policy changes on this page and
                    within the settings of any mobile apps. Please check back periodically to view changes to our
                    privacy policy. Your continued use of the Services following posting of such changes constitutes
                    your acceptance of the modified terms.
                </p>

                <h5>16. CONTACT US</h5>
                <p>
                    Should you have any questions about our privacy practices or this Privacy Policy, please email us
                    at&nbsp;<a href="mailto:legal@modernizegames.com">legal@modernizegames.com</a>.
                </p>
            </WebsiteRightsContent>
        </DefaultLayout>
    )
}

export default PrivacyPolicy