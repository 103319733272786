import React from 'react';

import {QuantityCalculator, Image} from "components/atoms";
import {svg} from "assets/svg";

const PriceInfo = ({quantity, setQuantity, data, isLoading}) => {
    const inventoryPrice = data?.inventory.eth_price

    return (
        <div className="shop-divine_content_price-info">
            <div className="shop-divine_content_price-info_price">
                <span className="shop-divine_content_price-info_price_title">Price for {quantity}</span>
                <div className="shop-divine_content_price-info_price_content">
                    <Image src={svg.EtheriumGold} alt="Ethereum"/>
                    <span className={isLoading ? "skeleton" : ""}>
                                {isLoading ? "" : data
                                    ? (inventoryPrice * quantity).toFixed(3)
                                    : "??????????????"}
                    </span>
                </div>
            </div>
            {!isLoading && data &&
                <div className="shop-divine_content_price-info_quantity">
                    <span className="shop-divine_content_price-info_quantity_title">Quantity</span>
                    <QuantityCalculator value={quantity} setValue={setQuantity} limit={5}/>
                </div>}
        </div>
    )
}

export default PriceInfo