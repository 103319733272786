import React, {useState, useEffect, memo} from "react"
import "./style.scss"

const Pagination = ({currentButton, setCurrentButton, page, paginationRef}) => {
    const [arrayOfCurrentButton, setArrayOfCurrentButton] = useState([])
    let numberOfPage = []
    for (let i = 1; i <= page; i++) {
        numberOfPage = [...numberOfPage, i]
    }
    useEffect(() => {
        let tempNumberOfPages = [...arrayOfCurrentButton]
        if (page < 8) {
            tempNumberOfPages = [...numberOfPage]
        } else {
            const dotsInitial = "..."
            const dotsLeft = "... "
            const dotsRight = " ..."

            if (currentButton >= 1 && currentButton <= 4) {
                tempNumberOfPages = [1, 2, 3, 4, 5, dotsInitial, numberOfPage.length]
            } else if (currentButton > 4 && currentButton < numberOfPage.length - 2) {
                const sliced1 = numberOfPage.slice(currentButton - 2, currentButton)
                const sliced2 = numberOfPage.slice(currentButton, currentButton + 1)
                tempNumberOfPages = [1, dotsLeft, ...sliced1, ...sliced2, dotsRight, numberOfPage.length]
            } else if (currentButton >= numberOfPage.length - 2) {
                const sliced = numberOfPage.slice(numberOfPage.length - 5, numberOfPage.length)
                tempNumberOfPages = [1, dotsLeft, ...sliced]
            }
        }
        setArrayOfCurrentButton(tempNumberOfPages)
        // eslint-disable-next-line
    }, [currentButton, page])

    const fixingBoundaries = (currentButton, type) => {
        switch (type) {
            case "prev":
                return currentButton === 1 ? currentButton : currentButton - 1
            case "next":
                return numberOfPage.length === currentButton ? currentButton : currentButton + 1
            default:
                return currentButton
        }

    }

    return (
        <ul className="kompete-pagination" ref={paginationRef}>
            <li draggable={false} className="prev"
                onClick={() => setCurrentButton(fixingBoundaries(currentButton, "prev"))}>
                <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.4" d="M9 17L1 9L9 1" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </li>
            {
                arrayOfCurrentButton.map((page, i) =>
                    <li
                        onClick={() => typeof page === "string" ? "" : setCurrentButton(page)}
                        draggable={false}
                        className={currentButton === page ? "active" : ""}
                        key={i}>
                        {page}
                    </li>
                )
            }
            <li draggable={false} className="next"
                onClick={() => setCurrentButton(fixingBoundaries(currentButton, "next"))}>
                <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.4" d="M1 17L9 9L1 1" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </li>
        </ul>
    )
}
export default memo(Pagination)