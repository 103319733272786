import {createApi} from "@reduxjs/toolkit/query/react";

import {createFetchBaseServerQuery} from "config/createFetchBaseServerQuery";
import {LIMITED_SHOP} from "../endpoint";
import {ENVIRONMENTS} from "utils";

export const limitedShopApi = createApi({
    name: 'limited/shop/api',
    baseQuery: createFetchBaseServerQuery({baseUrl: ENVIRONMENTS.MARKET_URL, isAuthorization: false}),
    endpoints: (builder) => ({
        getActiveLimitedShop: builder.query({
            query: () => ({
                url: LIMITED_SHOP.LIMITED_INVENTORIES,
                method: "GET",
            }),
            keepUnusedDataFor: 10,
            transformResponse: (response) => {
                const {data} = response

                if (data) {
                    const itemFormatter = (item) => {
                        const {groups, rarity} = item.inventory
                        const {name: sub_category, parent} = groups[0]
                        const {slot, inventory} = item

                        return {
                            slot,
                            item_id: item.id,
                            sku: inventory.sku,
                            rarity: rarity.name,
                            name: inventory.name,
                            inventory_id: inventory.id,
                            sub_category: sub_category,
                            token_id: inventory.token_id,
                            image_url: inventory.image_url,
                            description: inventory.description,
                            voolah_price: inventory.voolah_price,
                            category: parent ? parent.value : '-',
                            never_sold_again: inventory.never_sold_again,
                        }
                    }

                    const formattedQueueItems = data.queue_items.map((item) => itemFormatter(item))

                    return {
                        ...data,
                        queue_items: formattedQueueItems

                    }
                }

                return null
            },
            transformErrorResponse: () => null
        })
    })
})

export const {useGetActiveLimitedShopQuery} = limitedShopApi