import {useDispatch, useSelector} from "react-redux";
import React, {useState, memo} from "react";

import {userRecoverPassword} from "store/AuthV2/AuthV2Slice";
import {useLocalStorage} from "hooks";

import ProfileInfoHeader from "./ProfileInfoHeader";
import {ButtonV2} from "components/atoms";

const ProfilePasswordBlock = () => {
    const dispatch = useDispatch()
    const {getLoginType} = useLocalStorage()

    const [isDisabled, setIsDisabled] = useState(false)

    const {
        error: recoverPasswordError,
        loading: recoverPasswordLoading,
        success: recoverPasswordSuccess,
        errorMessage: recoverPasswordErrorMessage
    } = useSelector(state => state.authV2.recoverPassword)
    const {userData} = useSelector(state => state.authV2.signIn)

    const requestPassword = () => {
        setIsDisabled(true)
        dispatch(userRecoverPassword({username: userData.username}))
        setTimeout(() => setIsDisabled(false), 60000)
    }

    const status = recoverPasswordError ? "error" : "default"
    const processInfo = recoverPasswordSuccess ? "Password requested!" : recoverPasswordError ? recoverPasswordErrorMessage : ""
    const isLoginTypeSocial = getLoginType() === "social"

    return !isLoginTypeSocial && (
        <div className="profile_container_info_password">
            <ProfileInfoHeader
                status={status}
                title="Password"
                processInfo={processInfo}
            />
            <ButtonV2
                onClick={requestPassword}
                loading={recoverPasswordLoading}
                disabled={recoverPasswordLoading ? false : isDisabled}
            >
                Request Password
            </ButtonV2>
        </div>
    )
}

export default memo(ProfilePasswordBlock)