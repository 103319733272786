import React, {useEffect, useRef, useState} from 'react';
import VimeoPlayer from '@vimeo/player';
import classNames from "classnames";

import "./style.scss"

const VideoEmbed = ({padding, src, title, maxQuality, imgClass, isOverlay, heightCallBack}) => {
    const [loading, setLoading] = useState(true)
    const iframeRef = useRef(null);
    let timeout

    const videoBackgroundStyle = classNames(imgClass ? {[imgClass]: loading} : "")

    useEffect(() => {
        if (!iframeRef.current) return

        const player = new VimeoPlayer(iframeRef.current);

        player.on('loaded', () => {
            player.setQuality(maxQuality || 'auto')
            player.setAutopause(false)
            player.setMuted(true)
            player.setLoop(true)
        });

        player.on("play", () => {
            setLoading(false)
        })

        player.on("error", () => {
            setLoading(true)
        })

        player.on("timeupdate", () => {
            clearTimeout(timeout);

            timeout = setTimeout(() => {
                setLoading(true)
            }, 3000);
        });

        return () => {
            player.off("play")
            player.off("error")
            player.off("timeupdate")

            if (timeout) clearTimeout(timeout);
        };
    }, []);

    useEffect(() => checkAndSetVideoHeight(), [iframeRef.current?.getBoundingClientRect()])

    const checkAndSetVideoHeight = () => {
        if (iframeRef) heightCallBack(iframeRef.current?.getBoundingClientRect().height)
    }

    const containerStyle = {
        padding: padding || '65% 0 0 0',
        position: 'relative',
    };

    const iframeStyle = {
        visibility: loading ? 'hidden' : 'visible',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    };

    return (
        <div style={containerStyle} className={videoBackgroundStyle}>
            <iframe
                title={title}
                frameBorder="0"
                ref={iframeRef}
                style={iframeStyle}
                src={`${src}&autoplay=1`}
                allow="autoplay; fullscreen; picture-in-picture"
            ></iframe>
            {isOverlay && <div className="video-overlay"/>}
        </div>
    );
};

export default VideoEmbed;