import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import React, {useEffect} from "react";

import {
    wait,
    ERROR,
    STEPS_STATUS,
    errorHandler,
    GLOBAL_MODALS,
    formatNumberToEuropeanStyle,
    walletAndTransactionErrorHandle
} from "utils";
import {useGlobalModalsContext, useModalsContext} from "layouts";
import {useConnectedWallet, useConnectWallet} from "hooks";
import useDivineShop from "../useDivineShop";

import PaymentMethod from "components/molecules/PaymentMethodTemplate";
import {CosmeticModalTemplate} from "components/molecules";

import "../style.scss"


const DivineOrderModal = () => {
    const navigate = useNavigate()

    const {disconnectWallet, openWalletModal} = useConnectWallet()
    const {
        address,
        providers,
        isConnected,
        getEthBalance,
        isConnectedAddressCorrect,
        isConnectedChainCorrect
    } = useConnectedWallet()
    const {currentModal, setCurrentModal} = useModalsContext()
    const {setModal, setMessage, resetStatesAndClose} = useGlobalModalsContext()

    const {currentChain} = useSelector(state => state.web3)
    const {ethBalance, wallet} = useSelector(state => state.authV2.signIn.userData)

    const {inventory, quantity} = currentModal.data

    const currentInventoryEthPrice = quantity * inventory.eth_price
    const formattedEthBalance = ethBalance ? Number(formatNumberToEuropeanStyle(ethBalance)).toFixed(5) : 0
    const isEthBalanceCorrect = Number(ethBalance) >= currentInventoryEthPrice
    const {walletConnectSigner} = providers()

    useEffect(() => {
        if (!ethBalance) getEthBalance(address)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {createSellOrder, createBuyOrder, sendTransaction} = useDivineShop({
        chainId: currentChain.chainId,
        feePercent: 90,
        price: currentInventoryEthPrice.toString(),
        signer: walletConnectSigner,
        quantity,
        tokenId: inventory.token_id,
        account: wallet
    })

    const mintInventory = async () => {
        if (!isConnectedChainCorrect) {
            setModal(GLOBAL_MODALS.ERROR)
            setMessage(ERROR.INVALID_CHAIN)
            resetStatesAndClose()
            disconnectWallet()
            return
        }

        if (!isConnectedAddressCorrect) {
            setModal(GLOBAL_MODALS.ERROR)
            setMessage(ERROR.WALLET_CONNECTED_MATCH_INVALID)
            resetStatesAndClose()
            disconnectWallet()
            return
        }

        setModal(GLOBAL_MODALS.PROCESS)
        setCurrentModal({status: STEPS_STATUS.CLOSE})

        try {
            const {sellOrder, sellSignature} = await createSellOrder()

            const {buyOrder, buySignature} = await createBuyOrder()

            await wait(4000)

            try {
                const result = await sendTransaction(sellOrder, sellSignature, buyOrder, buySignature)
                if (!result.status)
                    errorHandler(ERROR.TRANSACTION_FAILED)

                setCurrentModal({status: STEPS_STATUS.SUCCESS, data: currentModal.data})
                setModal(GLOBAL_MODALS.CLOSE)
                getEthBalance(address)
            } catch (error) {
                const {message} = error
                let errorMessage = message.length > 100 ? walletAndTransactionErrorHandle(error, false) : message
                setModal(GLOBAL_MODALS.ERROR)
                setMessage(errorMessage)
                resetStatesAndClose()
            }
        } catch (error) {
            setMessage(error?.message ?? ERROR.WRONG)
            setModal(GLOBAL_MODALS.ERROR)
            resetStatesAndClose()
        }
    }

    const methodDetails = {
        eth: {
            text: isConnected ? isEthBalanceCorrect ? "Pay with eth" : "Buy eth" : "Connect wallet",
            onClick: () =>
                isConnected
                    ? isEthBalanceCorrect
                        ? mintInventory()
                        : navigate("/bazaar?tab=token", {state: {process: "order-more-eth"}})
                    : (setCurrentModal({status: STEPS_STATUS.CLOSE}), openWalletModal()),
            error: isConnected ? isEthBalanceCorrect ? "" : "Insufficient ETH balance.Please purchase more ETH!" : "",
            totalPrice: `${currentInventoryEthPrice.toFixed(3)} ETH`
        }
    }

    return (
        <CosmeticModalTemplate
            show={true}
            rarity="divine"
            quantity={quantity}
            title="Order Summery"
            name={inventory.name}
            image={inventory.image_url}
            buttonOne={{
                callBack: () => methodDetails.eth.onClick(),
                text: methodDetails.eth.text
            }}
            isShowClose={true}
            closeClick={() => setCurrentModal({status: ""})}
        >
            <div className="shop-divine_order-summery_content_payments-methods">
                <div className="shop-divine_order-summery_content_payments-methods_head">
                    <div
                        className="shop-divine_order-summery_content_payments-methods_head_quantity">
                        <span>Available payment methods</span> <span>(1)</span>
                    </div>
                    <span className="shop-divine_order-summery_content_payments-methods_head_error">
                        {methodDetails.eth.error}
                    </span>
                </div>
                <PaymentMethod
                    method="eth"
                    checked={true}
                    disable={true}
                    balance={formattedEthBalance}
                    insufficient={!!methodDetails.eth.error}
                />
            </div>
            <div className="shop-divine_order-summery_content_total">
                <div>Total</div>
                <div>{methodDetails.eth.totalPrice}</div>
            </div>
            {methodDetails.eth.error &&
                <div className="shop-divine_order-summery_content_payments-methods_bottom_error">
                    <p>{methodDetails.eth.error}</p>
                </div>
            }
        </CosmeticModalTemplate>
    )
}

export default DivineOrderModal