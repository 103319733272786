import {useNavigate} from "react-router-dom";
import React from "react";

import {CosmeticModalTemplate} from "components/molecules";
import {useModalsContext} from "layouts";

const LockerCurrentCosmeticTransferSuccessModal = () => {
    const {currentModal} = useModalsContext()
    const {name, rarity, image_url} = currentModal.data
    const {quantity} = currentModal

    const navigate = useNavigate()

    return (
        <CosmeticModalTemplate
            show={true}
            name={name}
            rarity={rarity}
            image={image_url}
            quantity={quantity}
            title="Transfer successful!"
            buttonOne={{callBack: () => navigate("/locker"), text: "Go to locker"}}
        />
    )
}

export default LockerCurrentCosmeticTransferSuccessModal