import React from 'react';
import classNames from 'classnames';

import {BUTTON_VARIANT, SIZE} from "utils";

import {LoadSpinner} from "components/atoms";

import './style.scss';

const Button = (
    {
        onClick,
        children,
        className,
        loading = false,
        size = SIZE.LARGE,
        disabled = false,
        variant = BUTTON_VARIANT.DEFAULT,
    }
) => {
    const currentVariant = loading ? BUTTON_VARIANT.LOADING : variant
    const isLoading = currentVariant === BUTTON_VARIANT.LOADING || loading

    const buttonClass = classNames(
        "btn",
        `btn-${currentVariant}`,
        `btn-${size}`,
        className,
        {'btn-disabled': disabled},
    );

    return (
        <button
            onClick={onClick}
            disabled={disabled}
            className={buttonClass}
        >
            {isLoading ? <div className="lds-block"><LoadSpinner/></div> : children}
        </button>
    );
};

export default Button;