import React from "react";

import {useGlobalModalsContext} from "layouts";
import {GLOBAL_MODALS} from "utils";

import {ButtonV2, Modal} from "components/atoms";

import "./style.scss"

const LoginAlertModal = () => {
    const {setModal} = useGlobalModalsContext()

    return (
        <Modal
            isOpen={true}
            onClose={() => setModal(GLOBAL_MODALS.CLOSE)}
            title="You need to login or create a kompete acount to purchase"
        >
            <div className="login-alert-modal">
                <ButtonV2 onClick={() => setModal(GLOBAL_MODALS.SIGN_IN)}>Login</ButtonV2>
                <ButtonV2 onClick={() => setModal(GLOBAL_MODALS.CREATE_ACCOUNT)}>Create an account</ButtonV2>
            </div>
        </Modal>
    )
}

export default LoginAlertModal;