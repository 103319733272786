import {useNavigate, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";

import {
    ERROR,
    STEPS_STATUS,
    errorHandler,
    GLOBAL_MODALS,
    checkinDecimalAndToFixed,
    formatNumberToEuropeanStyle,
    walletAndTransactionErrorHandle
} from "utils";
import {limitedReset, payForInventoryWithVoolah, saveVoolahSpentHistory} from "store/LimitedShop/limitedTimeShopSlice";
import {useConnectedWallet, useConnectWallet, useGMTEvents} from "hooks";
import {useGlobalModalsContext, useModalsContext} from "layouts";
import useLimitedTimeShop from "../useLimitedTimeShop";

import {CosmeticModalTemplate, PaymentMethod} from "components/molecules";

import "./style.scss"

const LimitedTimeCurrentCosmeticOrderModal = ({quantity}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {openWalletModal, disconnectWallet} = useConnectWallet()
    const {setModal, setMessage, resetStatesAndClose} = useGlobalModalsContext()
    const {setCurrentModal, currentModal} = useModalsContext()
    const {NFTPurchaseShop, web2ItemPurchase} = useGMTEvents()
    const {
        address,
        providers,
        getEthBalance,
        isConnected,
        isConnectedChainCorrect,
        isConnectedAddressCorrect,
    } = useConnectedWallet()

    const [currentPayment, setCurrentPayment] = useState("voolah")
    // eslint-disable-next-line
    const [_, setSearchParams] = useSearchParams()

    const {token_id, sku, name, rarity, image_url, voolah_price, ethPrice, item_id} = currentModal.data

    const {ethBalance, voolahBalance, wallet, id} = useSelector(state => state.authV2.signIn.userData)
    const {
        error: cosmeticPaymentError,
        success: cosmeticPaymentSuccess,
        loading: cosmeticPaymentLoading
    } = useSelector(state => state.limitedShop.cosmeticPayment)
    const {currentChain} = useSelector(state => state.web3)

    const currentInventoryVoolahPrice = voolah_price * quantity
    const currentInventoryEthPrice = checkinDecimalAndToFixed(ethPrice || 0, 4)

    const isEthBalanceCorrect = ethBalance >= currentInventoryEthPrice
    const isVoolahBalanceCorrect = voolahBalance >= currentInventoryVoolahPrice
    const formattedEthBalance = ethBalance ? Number(formatNumberToEuropeanStyle(ethBalance)).toFixed(5) : 0
    const {walletConnectSigner} = providers()

    const {createSellOrder, createBuyOrder, sendTransaction} = useLimitedTimeShop({
        quantity,
        feePercent: 90,
        account: wallet,
        tokenId: token_id,
        signer: walletConnectSigner,
        chainId: currentChain.chainId,
        price: currentInventoryEthPrice.toString()
    })

    const methodDetails = {
        voolah: {
            text: isVoolahBalanceCorrect ? "Pay with voolah" : "Buy more voolah",
            onClick: () =>
                isVoolahBalanceCorrect
                    ? dispatch(payForInventoryWithVoolah({quantity, sku, currency: "voolah"}))
                    : setSearchParams({tab: "voolah"}),
            error: isVoolahBalanceCorrect ? "" : "Insufficient VOOLAH balance. Please purchase more VOOLAH!",
            totalPrice: `${currentInventoryVoolahPrice} VOOLAH`
        },
        eth: {
            text: wallet ? isConnected ? isEthBalanceCorrect ? "Pay with eth" : "Buy more eth" : "Connect wallet" : "Add Wallet",
            onClick: () =>
                wallet
                    ? isConnected
                        ? isEthBalanceCorrect
                            ? mintInventory()
                            : navigate("/bazaar?tab=token", {state: {process: "order-more-eth"}})
                        : (openWalletModal() , setCurrentModal({...currentModal, status: ""}))
                    : navigate("/profile"),
            error: isConnected ? isEthBalanceCorrect ? "" : "Insufficient ETH balance. Please purchase more ETH!" : "",
            totalPrice: `${currentInventoryEthPrice} ETH`
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => followCosmeticPaymentProcess(), [cosmeticPaymentError, cosmeticPaymentSuccess])

    const followCosmeticPaymentProcess = () => {
        if (cosmeticPaymentError) {
            setCurrentModal({...currentModal, status: "reject-payment"})
            dispatch(limitedReset({section: "cosmeticPayment"}))
        }
        if (cosmeticPaymentSuccess) {
            setCurrentModal({...currentModal, status: "success"})

            if (currentPayment === "voolah") {
                dispatch(saveVoolahSpentHistory({sku, amount: currentInventoryVoolahPrice}))
                web2ItemPurchase(id, item_id, name, voolah_price, "VOOLAH")
            }
        }

        if (address) getEthBalance(address)
    }

    async function mintInventory() {
        if (!isConnectedChainCorrect) {
            setModal(GLOBAL_MODALS.ERROR)
            setMessage(ERROR.INVALID_CHAIN)
            resetStatesAndClose()
            disconnectWallet()
            return
        }

        if (!isConnectedAddressCorrect) {
            setModal(GLOBAL_MODALS.ERROR)
            setMessage(ERROR.WALLET_CONNECTED_MATCH_INVALID)
            resetStatesAndClose()
            disconnectWallet()
            return
        }

        setModal(GLOBAL_MODALS.PROCESS)
        setCurrentModal({...currentModal, status: STEPS_STATUS.CLOSE})

        try {
            const {sellOrder, sellSignature} = await createSellOrder()

            const {buyOrder, buySignature} = await createBuyOrder()

            setTimeout(async () => {
                try {
                    const result = await sendTransaction(sellOrder, sellSignature, buyOrder, buySignature, hashCallBack)
                    if (!result.status)
                        errorHandler(ERROR.TRANSACTION_FAILED)

                    setCurrentModal({
                        ...currentModal,
                        data: {...currentModal.data, quantity},
                        status: STEPS_STATUS.SUCCESS
                    })
                    setModal(GLOBAL_MODALS.CLOSE)
                    getEthBalance(address)
                } catch (error) {
                    const {message} = error
                    let errorMessage = message.length > 100 ? walletAndTransactionErrorHandle(error, false) : message

                    setModal(GLOBAL_MODALS.ERROR)
                    setMessage(errorMessage)
                    resetStatesAndClose()
                }
            }, 4000)
        } catch (error) {
            setModal(GLOBAL_MODALS.ERROR)
            setMessage(error?.message ?? ERROR.WRONG)
            resetStatesAndClose()
            setCurrentModal({status: STEPS_STATUS.CLOSE})
        }
    }

    function hashCallBack(transactionDetails) {
        const {hash} = transactionDetails

        NFTPurchaseShop(id, token_id, ethPrice, "ETH", hash)
    }

    return (
        <CosmeticModalTemplate
            show={true}
            name={name}
            rarity={rarity}
            image={image_url}
            quantity={quantity}
            title="Order Summery"
            buttonOne={{
                isLoading: cosmeticPaymentLoading,
                callBack: () => methodDetails[currentPayment].onClick(),
                text: methodDetails[currentPayment].text
            }}
            isShowClose={true}
            closeClick={() => setCurrentModal({status: ""})}
            contentClassName="limited-time-current-cosmetic_order-summery_content_payments-methods"
        >
            <div className="limited-time-current-cosmetic_order-summery_content_payments-methods">
                <div className="limited-time-current-cosmetic_order-summery_content_payments-methods_head">
                    <div
                        className="limited-time-current-cosmetic_order-summery_content_payments-methods_head_quantity">
                        <span>Available payment methods</span> <span>(2)</span>
                    </div>
                    <span className="limited-time-current-cosmetic_order-summery_content_payments-methods_head_error">
                        {methodDetails[currentPayment].error}
                    </span>
                </div>
                <PaymentMethod
                    method="voolah"
                    balance={voolahBalance}
                    checked={currentPayment === "voolah"}
                    insufficient={!!methodDetails.voolah.error}
                    onClick={() => setCurrentPayment("voolah")}
                />
                <PaymentMethod
                    method="eth"
                    balance={formattedEthBalance}
                    checked={currentPayment === "eth"}
                    insufficient={!!methodDetails.eth.error}
                    onClick={() => setCurrentPayment("eth")}
                />
            </div>
            <div className="limited-time-current-cosmetic_order-summery_content_total">
                <div>Total</div>
                <div>{methodDetails[currentPayment].totalPrice}</div>
            </div>
            {methodDetails[currentPayment].error &&
                <div className="limited-time-current-cosmetic_order-summery_content_payments-methods_bottom_error">
                    <p>{methodDetails[currentPayment].error}</p>
                </div>
            }
        </CosmeticModalTemplate>
    )
}

export default LimitedTimeCurrentCosmeticOrderModal