import React from 'react';

import DownloadDeviceCart from "./DownloadDeviceCart";
import DownloadGameVideo from "./DownloadGameVideo";
import {UnderLine} from "components/atoms";

import "./style.scss"

const DownloadGeneralContent = () => (
    <div className="download_main_content_general container">
        <div className="download_main_content_general_title">
            <h1>Download <span>for free</span></h1>
            <UnderLine/>
        </div>
        <div className="download_main_content_general_main">
            <div className="download_main_content_general_main_links">
                <p>Available Devices:</p>
                <div className="download_main_content_general_main_links_available">
                    <DownloadDeviceCart
                        device="windows"
                        icon="modernize"
                        text="modernize launcher"
                        link="https://www.modernizegames.com/ModernizeInstaller.exe"
                        size="md"
                    />
                    <DownloadDeviceCart
                        device="windows"
                        icon="epicGames"
                        text="epic games launcher"
                        link="https://store.epicgames.com/en-US/p/kompete-73c94a"
                        isTarget={true}
                        size="md"
                    />
                    <DownloadDeviceCart
                        device="android"
                        icon="googlePlay"
                        text="google play"
                        link="https://play.google.com/store/apps/details?id=com.Kompete"
                        isTarget={true}
                        size="md"
                    />
                    <DownloadDeviceCart
                        device="android"
                        icon="android"
                        text="apk"
                        link="https://kompetecdn.azureedge.net/kompete-game-download/KOMPETE_android.apk"
                        size="md"
                    />
                </div>
                <p>Coming soon:</p>
                <div className="download_main_content_general_main_links_no-available">
                    <DownloadDeviceCart
                        device="ios"
                        icon="appStore"
                        available={false}
                        text="app store"
                    />
                    <DownloadDeviceCart
                        device="console"
                        icon="playstation"
                        available={false}
                        text="playstation"
                    />
                    <DownloadDeviceCart
                        device="console"
                        icon="xbox"
                        available={false}
                        text="xbox"
                    />
                </div>
            </div>
            <div className="download_main_content_general_main_video">
                <DownloadGameVideo/>
            </div>
        </div>
    </div>
)

export default DownloadGeneralContent