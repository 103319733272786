import React, {useEffect, memo} from "react";
import {useNavigate} from "react-router-dom";

import {useConnectedWallet, useConnectWallet, useLocalStorage} from "hooks";
import {ERROR, GLOBAL_MODALS, STEPS_STATUS, SUCCESS} from "utils";
import {useGlobalModalsContext, useModalsContext} from "layouts";

import BazaarCurrentAlreadyListedWeb3CosmeticRemoveModal from "./BazaarCurrentAlreadyListedWeb3CosmeticRemoveModal";
import BazaarCurrentAlreadyListedWeb3CosmeticEditModal from "./BazaarCurrentAlreadyListedWeb3CosmeticEditModal"
import BazaarSuccessModal from "./BazaarCurrentAlreadyListedWeb3CosmeticSuccessModal";

const BazaarCurrentCosmeticSteps = () => {
    const {setModal, setMessage, resetStatesAndClose} = useGlobalModalsContext()
    const {currentModal, setCurrentModal} = useModalsContext()
    const {setWalletConnectionSuccess} = useLocalStorage()

    const {isConnected, isWalletConnection, isConnectedAddressCorrect} = useConnectedWallet()
    const {disconnectWallet} = useConnectWallet()

    const navigate = useNavigate()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => checkWalletConnectionAndAddressMatch(), [isConnected]);

    const checkWalletConnectionAndAddressMatch = async () => {
        if (isConnected && !isWalletConnection) {
            setModal(GLOBAL_MODALS.PROCESS)

            if (!isConnectedAddressCorrect) {
                setModal(GLOBAL_MODALS.ERROR)
                setMessage(ERROR.WALLET_CONNECTED_MATCH_INVALID)

                resetStatesAndClose()
                disconnectWallet()
            } else {
                setModal(GLOBAL_MODALS.SUCCESS)
                setMessage(SUCCESS.WALLET_CONNECTED)
                resetStatesAndClose()
                setWalletConnectionSuccess()
            }
        }
    }

    return (
        <React.Fragment>
            {
                {
                    [STEPS_STATUS.ORDER_EDIT]:
                        <BazaarCurrentAlreadyListedWeb3CosmeticEditModal/>,
                    [STEPS_STATUS.ORDER_REMOVE]:
                        <BazaarCurrentAlreadyListedWeb3CosmeticRemoveModal/>,
                    [STEPS_STATUS.ORDER_EDIT_SUCCESS]:
                        <BazaarSuccessModal
                            title="update successful!"
                            setCurrentModal={() => setCurrentModal({status: STEPS_STATUS.CLOSE})}
                        />,
                    [STEPS_STATUS.ORDER_CANCEL_SUCCESS]:
                        <BazaarSuccessModal
                            title="update successful!"
                            setCurrentModal={() => navigate("/locker")}
                        />,
                    [STEPS_STATUS.ORDER_PURCHASE_SUCCESS]:
                        <BazaarSuccessModal
                            title="purchase successful!"
                            setCurrentModal={() => navigate("/locker")}
                        />
                }[currentModal.status]
            }
        </React.Fragment>
    )
}

export default memo(BazaarCurrentCosmeticSteps)