import React, {memo} from "react"
import classNames from "classnames";

import {Icon, Rarity, Image, Chain, NeverSoldAgain} from "components/atoms";
import Share from "../Share";

import "./style.scss"

const CosmeticViewTemplate = (
    {
        img,
        title,
        chain,
        rarity,
        isIcon,
        loading,
        children,
        attribute,
        classname,
        isTransfer,
        isShare = true,
        transferCallBack,
        isNeverSoldAgain
    }
) => {
    const cosmeticImg = classNames("left", {skeleton: loading})
    const cosmeticName = classNames("title", {skeleton: loading})
    const cosmeticRarity = classNames("right_rarity", {skeleton: loading})

    return (
        <div className={`${classname} shop-template container`}>
            <div className={cosmeticImg}>
                {img && !loading ? <Image alt="cosmetic" src={img}/> : ""}
            </div>
            <div className="right">
                <div className="right_header">
                    <h2 className={cosmeticName}>{!loading ? title : ""}</h2>
                    {isTransfer &&
                        <div onClick={transferCallBack} className="right_header_transfer-block">
                            <span>Transfer</span>
                        </div>}
                    {isShare && <div className="right_header_share-block"><Share/></div>}
                </div>
                <div className={cosmeticRarity}>
                    {!loading &&
                        <React.Fragment>
                            {rarity && <Rarity
                                text={rarity}
                                rarity={rarity}
                            />}
                            {attribute &&
                                <React.Fragment>
                                    {React.Children.toArray(attribute.map((item) =>
                                        <Rarity text={item.name} rarity="default"/>))
                                    }
                                </React.Fragment>
                            }
                            {chain && <Chain chain={chain}/>}
                            {isNeverSoldAgain && <NeverSoldAgain/>}
                        </React.Fragment>}
                    {!loading && isIcon && <Icon name={isIcon}/>}
                </div>
                {children}
            </div>
        </div>
    )
}

export default memo(CosmeticViewTemplate)