import React, {memo} from 'react';

import {Icon} from "components/atoms";

const EligibilityItemBlock = ({data, isLoading}) => (
    <React.Fragment>
        {data?.eligibility && !isLoading &&
            <div className="shop-divine_content_eligibility-item-block">
                <h3 className="shop-divine_content_eligibility-item-block_title">
                    You must own one of the following:
                </h3>
                <React.Fragment>
                    {React.Children.toArray(
                        data.eligibility.map(({description, eligibility}) =>
                            <div className="shop-divine_content_eligibility-item-block_item">
                                <div>{description}</div>
                                <div><Icon name={eligibility ? "eligibility" : "noEligibility"}/></div>
                            </div>))}
                </React.Fragment>

            </div>}
    </React.Fragment>
)

export default memo(EligibilityItemBlock)