import {useLocation, useNavigate} from "react-router-dom";
import React, {useState} from 'react';

import {globalService} from "store/Global/globalService";
import {validateEmail} from "utils";

import ModernizeFooter from "components/atoms/ModernizeFooter";
import {Button, Input, Seo} from "components/atoms";

import "./style.scss"

const Option = () => {
    const {pathname} = useLocation()
    const navigate = useNavigate()

    const [email, setEmail] = useState("")
    const [error, setError] = useState("")

    const subscribe = async () => {
        if (validateEmail(email)) {
            try {
                const {success} = await globalService.createSubscriber({email})

                if (success) {
                    switch (pathname) {
                        case "/lp/mobile-02":
                            navigate("/lp/mobile-02-success", {replace: true})
                            break
                        default:
                            navigate("/lp/pc-02-success", {replace: true})
                    }
                }
            } catch (error) {
                setEmail("")
                setError(error.message)
            }
        }
    }

    return (
        <React.Fragment>
            <Seo title="Free Game in Early Access"/>
            <div className="landing-page_option">
                <div className="landing-page_option_form">
                    <h1>100% free game</h1>
                    <h2>Secret new & fun early access game</h2>
                    <h3>With free battlepass & free legendary skin</h3>
                    <Input
                        error={error}
                        value={email}
                        status={error ? "error" : "default"}
                        placeholder="Enter Your Email Here..."
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Button
                        path={true}
                        onClick={subscribe}
                    >
                        Play now for free
                    </Button>
                </div>
                <ModernizeFooter/>
            </div>
        </React.Fragment>
    )
}

export default Option
