import React from "react";
import classNames from "classnames";
import "./style.scss"

const Status = ({type, children}) => {
    const statusStyle = classNames("status", type ?? "success")

    return (
        <span className={statusStyle}>
            {children}
        </span>
    )
}

export default Status