import {createFetch} from "config/createFetch";
import {PROFILE} from "../endpoint";
import {SERVER} from "utils";

const options = {
    host: process.env.REACT_APP_HOST,
    xsolla_project_id: process.env.REACT_APP_XSOLLA_PROJECT_ID,
    kompete_project_id: process.env.REACT_APP_XSOLLA_KOMPETE_PROJECT_ID
}

const userSignUp = async (data, thunkAPI) => {
    const response = await fetch(`${options.host}/v1/user/register`, {
        method: "POST",
        body: JSON.stringify(data)
    })

    if (response.status === 204 || response.status === 200) {
        const result = await response.json()
        const href = new URL(result.payload).searchParams
        return href.get("token")
    } else {
        let result = await response.json()

        if (!Array.isArray(result)) {
            if (Array.isArray(result.error.details)) {
                return thunkAPI.rejectWithValue(result.error.details[0].message)
            } else {
                return thunkAPI.rejectWithValue(result.error.details.message)
            }
        } else {
            if (result[0]) {
                return thunkAPI.rejectWithValue(result[0].error.description)
            } else {
                return thunkAPI.rejectWithValue("Something went wrong")
            }
        }
    }
}

const userSignIn = (data, thunkAPI) => {
    return fetch(`https://login.xsolla.com/api/login?projectId=${options.xsolla_project_id}`, {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    })
        .then(async (result) => {
            const data = await result.json()
            const {status} = result

            if (status === 200) {
                const url = data.login_url
                return new URL(url).searchParams.get("token")
            }

            if (status === 401) throw new Error("Account details incorrect. Please enter again")
            throw new Error(data.error.description)
        })
        .then((result) => {
            return userGetData(result, thunkAPI)
                .then((user) => {
                    if (!user.nickname) {
                        user.accessToken = result
                        return user
                    } else {
                        localStorage.setItem('accessToken', result)
                        return user
                    }
                })
        })
        .catch((error) => {
            return thunkAPI.rejectWithValue(error)
        })
}

const userRecoverPassword = async (data, thunkAPI) => {
    const response = await fetch(
        `https://login.xsolla.com/api/password/reset/request?projectId=${options.xsolla_project_id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })

    if (response.status === 204) {
        return true;
    } else {
        return thunkAPI.rejectWithValue("Something went wrong!")
    }
}

const userGetData = async (jwt, thunkAPI) => {
    return Promise.all([getUserDataFromXsolla(jwt, thunkAPI), getUserDataFromBack(jwt), getUserVoolahBalance(jwt)])
        .then((result) => {
            result[0].wallet = result[1].wallet
            result[0].accessToken = jwt
            result[0].voolahBalance = result[2]
            return result[0]
        })
}

const getUserDataFromXsolla = (JWT, thunkAPI) => {
    return fetch("https://login.xsolla.com/api/users/me", {
        method: 'GET',
        headers: {
            Authorization: "Bearer " + JWT
        }
    })
        .then(async (result) => {
            if (result.status === 200) {
                return await result.json()
            } else {
                return thunkAPI.rejectWithValue(result.error.description)
            }
        })
}

const getUserDataFromBack = (jwt) => {
    return fetch(`${options.host}/v1/user/auth/basic`, {
        method: 'POST',
        body: JSON.stringify({jwt})
    })
        .then(async (result) => {
            if (result.status === 200) {
                let data = await result.json()
                const {wallet} = data.success.user

                return {wallet}
            } else {
                throw new Error("Something went wrong")
            }
        }).catch(() => {
            throw new Error("Something went wrong")
        })
}

const userVerificationSocial = (jwt, thunkAPI) => {
    localStorage.setItem("login", "social")
    return fetch(`${options.host}/v1/user/auth/social`, {
        method: "POST",
        body: JSON.stringify({jwt: jwt})
    })
        .then(async (result) => {

            if (result.status === 200) {
                const data = await result.json()
                const {wallet} = data.success.user
                const voolahBalance = await getUserVoolahBalance(jwt, thunkAPI)

                return getUserDataFromXsolla(jwt, thunkAPI)
                    .then((result) => {
                        result.wallet = wallet
                        result.accessToken = jwt
                        result.voolahBalance = voolahBalance

                        if (!result.nickname) {
                            return {type: "firstLogin", user: result}
                        } else {
                            localStorage.setItem("accessToken", jwt)
                            return {type: "firstLogin", user: result}
                        }
                    })
            }

            if (result.status === 422) {
                return {type: "registration", token: jwt}
            }
        })
}

const userSignUpSocial = (data, thunkAPI) => {
    const {fields, token} = data
    return fetch(`${options.host}/v1/user/register/social`, {
        method: "POST",
        body: JSON.stringify({...fields, jwt: token})
    })
        .then(async (result) => {
            if (result.status === 200) {
                return true
            } else {
                return thunkAPI.rejectWithValue("Something went wrong")
            }
        },)
}

const getUserVoolahBalance = (jwt) => {
    return createFetch("TWO", "v1/get_voolah_balance", "POST", {}, {jwt})
        .then((result) => result.data.voolah)
        .catch(() => "0")
}

const syncXsollaUserToDB = (thunkAPI, token) => {
    const jwt = token ? token : thunkAPI.getState().authV2.signIn.userData.accessToken
    return createFetch("TWO", "v1/user/syncWallet2Inventory", "POST", {}, {jwt})
}

const addWallet = async (wallet, jwt) => {
    return await createFetch(SERVER.MARKET, PROFILE.ADD_WALLET, "POST", {authorization: false}, {jwt, data: {wallet}})
}

const removeWallet = async () => {
    return await createFetch(SERVER.MARKET, PROFILE.REMOVE_WALLET, "POST", {authorization: true})
}

export const authV2Service = {
    addWallet,
    userSignUp,
    userSignIn,
    userGetData,
    removeWallet,
    userSignUpSocial,
    syncXsollaUserToDB,
    userRecoverPassword,
    getUserVoolahBalance,
    userVerificationSocial
}