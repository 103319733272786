import React from "react";
import Title from "components/atoms/TitleTemplate";
import Image from "components/atoms/Image";
import {png} from "assets/png";

const DeckBarriers = () => {
    return (
        <section className="deck_barriers">
            <Title underline={true}>The big <span>4 barriers</span></Title>
            <p className="deck_barriers_underTitle">
                There are 4 barriers that prevent people from hopping on a game. Some very highly trafficked game modes are locked up!
            </p>
            <div className="deck_barriers_cart container">
                <BarrierCart src={png.Rectangle} title="costs money each year for the same game"/>
                <BarrierCart src={png.Rectangle04} title="Game is pay to win"/>
                <BarrierCart src={png.Rectangle02} title="Game has no crossplatform play"/>
                <BarrierCart src={png.Rectangle03} title="Game doesn’t include mobile in main community"/>
            </div>
        </section>
    )
}

export default DeckBarriers

const BarrierCart = ({src, title}) => {
    return (
        <div className="deck_barriers_cart_item">
            <Image src={src} alt="barrier"/>
            <h4 className="deck_barriers_cart_item_title">{title}</h4>
        </div>
    )
}