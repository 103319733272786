import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {ethers} from "ethers";

import {ButtonV2, Modal} from "components/atoms";

import {errorHandler, GLOBAL_MODALS, PROCESS, ERROR, BUTTON_VARIANT} from "utils";
import {useGlobalModalsContext} from "layouts/context/GlobalModalsProvider";
import {useConnectedWallet, useConnectWallet, useLocalStorage} from "hooks";
import {authV2Service} from "store/AuthV2/AuthV2Service";

import "./style.scss"

const ConnectWalletModal = () => {
    const {isConnected, address, isWalletConnection, providers} = useConnectedWallet()
    const {setWalletConnectionSuccess, removeWalletConnectionSuccess} = useLocalStorage()
    const {disconnectWallet, openWalletModal,} = useConnectWallet()
    const {setModal, setMessage} = useGlobalModalsContext()
    const {walletConnectSigner} = providers()

    const {userData} = useSelector(state => state.authV2.signIn)

    const [isDisabled, setIsDisabled] = useState(false)
    const [addWalletLoading, setAddWalletLoading] = useState(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => followWalletConnectProcess(), [isConnected])

    const followWalletConnectProcess = async () => {
        if (isConnected && !isWalletConnection) {
            setAddWalletLoading(true)
            setWalletConnectionSuccess()
            setIsDisabled(true)

            await walletConnectSigner.signMessage(PROCESS.WALLET_CONFIRM)
                .then(async (signature) => {
                    const signerAddress = ethers.utils.verifyMessage(PROCESS.WALLET_CONFIRM, signature);

                    if (signerAddress === address) {
                        await authV2Service.addWallet(address, userData.accessToken)

                        setModal(GLOBAL_MODALS.CREATE_NICKNAME)
                        disconnectWallet(true)

                    } else {
                        errorHandler(ERROR.INVALID_WALLET)
                    }

                    setAddWalletLoading(false)
                })
                .catch((error) => {
                    disconnectWallet()
                    setIsDisabled(false)
                    removeWalletConnectionSuccess()
                    setAddWalletLoading(false)

                    if (error.message === ERROR.ALREADY_EXISTED_WALLET && error.message !== ERROR.INVALID_WALLET) {
                        setModal(GLOBAL_MODALS.ERROR)
                        setMessage(ERROR.ALREADY_EXISTED_WALLET)
                        setTimeout(() => setModal(GLOBAL_MODALS.CONNECT_WALLET), 3000)
                    }else{
                        setModal(GLOBAL_MODALS.ERROR)
                        setMessage(ERROR.WRONG)
                        setTimeout(() => setModal(GLOBAL_MODALS.CONNECT_WALLET), 3000)
                    }
                })
        }
    }

    return (
        <Modal
            isOpen={true}
            title="Connect wallet to your account?"
            onClose={() => setModal(GLOBAL_MODALS.CLOSE)}
        >
            <div className="connect-wallet-modal">
                <p>You don’t need this to play and you can connect it later in your profile.</p>
                <ButtonV2
                    onClick={() => openWalletModal()}
                    variant={addWalletLoading ? BUTTON_VARIANT.LOADING : BUTTON_VARIANT.DEFAULT}
                >
                    Yes
                </ButtonV2>
                <ButtonV2
                    disabled={isDisabled}
                    onClick={() => setModal(GLOBAL_MODALS.CREATE_NICKNAME)}
                >
                    No
                </ButtonV2>
            </div>
        </Modal>
    )
}

export default ConnectWalletModal;