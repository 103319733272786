import React from "react";
import {Helmet} from "react-helmet-async";

const Seo = (props) => {
    return (
            <Helmet>
                <title>{props.title}</title>
                <meta name="description" content={props.description}/>
                <meta property="og:title" content={props.title}/>
                <meta property="og:description" content={props.description}/>
                <meta property="og:url" content={window.origin}/>
                <meta name="twitter:title" content={props.title}/>
                <meta name="twitter:description" content={props.description}/>
                {props.children}
            </Helmet>
    );
}

export default Seo;