import React from "react";

import DefaultLayout from "layouts/DefaultLayout";
import PageMain from "components/atoms/PageMain";
import Seo from "components/atoms/Seo";

import {png} from "assets/png";
import "./style.scss"

export const Support = () => {

    return (
        <DefaultLayout>
            <Seo title="Support - KOMPETE"/>
            <PageMain
                title="Support"
                filter={true}
                originalImage={png.HeroPrivacy}
                resizeImage={png.HeroPrivacyResized}
            />
            <section className="support_content">
                <div className="support_content_main">
                    <h3>Get help with kompete</h3>
                    <p>If you have an issue and need to contact us please email us at &nbsp;<a
                        href="mailto:legal@modernizegames.com">help@kompete.game</a>
                        &nbsp;using your account email and a description of the issue you’re experiencing. Thanks!</p>
                </div>
            </section>
        </DefaultLayout>
    )
}

export default Support