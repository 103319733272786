import React, {memo} from "react";

import {PriceHistorySection} from "components/molecules";
import {Accordion} from "components/atoms";

const LockerCurrentCosmeticTransactionHistory = ({data}) => (
    <section className="container locker-price-history-section">
        <Accordion
            open={true}
            title="Price History"
            icon="price"
        >
            <PriceHistorySection tokenId={data?.token_id} chainId={data?.chain_id}/>
        </Accordion>
    </section>

)

export default memo(LockerCurrentCosmeticTransactionHistory)