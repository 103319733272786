import classNames from "classnames";
import React from "react"

import {STANDARD_LIMIT_OPTIONS} from "utils";

import {PaginationArrowWithTools, PaginationWithTools} from "components/molecules";
import TableBodySkeleton from "./TableBodySkeleton";
import TableNoBody from "./TableNoBody";
import TableHead from "./TableHead";
import TableBody from "./TableBody";

import "./style.scss"

const Table = (
    {
        callBackCurrentPage,
        isQueryPagination,
        callBackLimit,
        isDisableNext,
        callBackArrow,
        isPagination,
        currentPage,
        limitValue,
        tableHead,
        tableBody,
        isLoading,
        total,
        skip,
        limitData = STANDARD_LIMIT_OPTIONS
    }
) => {
    const isQueryPaginationToolsAvailable = !isPagination && isQueryPagination && !!tableBody.length
    const isDefaultPaginationToolsAvailable = !isQueryPagination && isPagination && !!tableBody?.length
    const isDataAvailable = !!tableBody.length

    const tableStyle = classNames("details", {"no-data": !isDataAvailable})

    return (
        <div className="table-container">
            <div className="table-container_main" style={{overflow: isDataAvailable ? "" : "hidden"}}>
                <table className={tableStyle}>
                    {isDataAvailable && <TableHead head={tableHead}/>}
                    {isLoading
                        ? <TableBodySkeleton
                            headOption={tableHead}
                            limitValue={limitValue.value}
                            dataCount={tableBody.length}
                        />
                        : !!tableBody.length
                            ? <TableBody body={tableBody} head={tableHead}/>
                            : <TableNoBody headLength={tableHead.length}/>
                    }
                </table>
            </div>
            {isDefaultPaginationToolsAvailable &&
                <PaginationWithTools
                    isChange={true}
                    totalNft={total}
                    limitData={limitData}
                    limitValue={limitValue}
                    currentPage={currentPage}
                    callBackLimit={callBackLimit}
                    callBackCurrentPage={callBackCurrentPage}
                />}
            {isQueryPaginationToolsAvailable &&
                <PaginationArrowWithTools
                    offset={skip}
                    isLoading={isLoading}
                    limitData={limitData}
                    limitValue={limitValue}
                    dataCount={tableBody.length}
                    callBackArrow={callBackArrow}
                    callBackLimit={callBackLimit}
                    isDisableNext={isDisableNext}
                />
            }
        </div>
    )
}

export default Table