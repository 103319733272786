import React from "react";
import classNames from "classnames";

import CheckBox2 from "components/atoms/CheckBox2";
import Rarity from "components/atoms/Rarity";
import Image from "components/atoms/Image";

import {svg} from "assets/svg";
import {png} from "assets/png";
import "./styles.scss"


const PaymentMethod = ({onClick, checked, method, balance, recommended, insufficient,disable}) => {
    const balanceStyle = classNames("payment-method-block_left_currency_balance", {insufficient: insufficient})
    const paymentBlockStyle = classNames("payment-method-block", {insufficient: insufficient, checked: checked})

    return (
        <div onClick={onClick} className={paymentBlockStyle}>
            <div className="payment-method-block_left">
                <div className="payment-method-block_left_img">
                    {
                        {
                            "eth":
                                <Image className="eth" src={svg.EtheriumGold} alt="eth"/>,
                            "voolah":
                                <Image className="voolah" src={png.VoolahCoin} alt="voolah"/>,
                            "xsolla":
                                <Image className="xsolla" src={png.Xsolla} alt="xsolla"/>,
                        }[method]
                    }
                </div>
                <div className="payment-method-block_left_currency">
                    <div className="payment-method-block_left_currency_name">
                        <span>
                            {
                                {
                                    "eth":
                                        "Ethereum",
                                    "voolah":
                                        "Voolah",
                                    "xsolla":
                                        "Xsolla",
                                }[method]
                            }
                        </span>
                        {
                            recommended &&
                            <Rarity rarity="common" text="recommended"/>
                        }
                    </div>
                    <div className={balanceStyle}>
                        {
                            method !== "xsolla" &&
                            <p>Current Balance: {balance} <span>{method}</span></p>
                        }
                    </div>
                    <div className="payment-method-block_left_currency_info">
                        <Image src={svg.Currency} alt="cur."/>
                        {
                            {
                                "eth":
                                    <span>Gas fees apply</span>,
                                "voolah":
                                    <span>In-game currency</span>,
                                "xsolla":
                                    <span>Pay with card or cryptocurrency</span>,
                            }[method]
                        }
                    </div>
                </div>
            </div>
            <div className="payment-method-block_right">
                <CheckBox2 view="green" type="circle" checked={checked} onChange={onClick} disable={disable}/>
            </div>
        </div>
    )
}

export default PaymentMethod