import React from 'react';
import {SkeletonText} from "components/atoms";

const Description = ({data, isLoading}) => (
    <div className="shop-divine_content_description">
        <span className="shop-divine_content_description_title">Description</span>
        {isLoading
            ? <SkeletonText textLineCount={5}/>
            : data
                ? <p>{data.inventory.description}</p>
                : <p>The next Divine item will be released soon! Check back later.</p>}
    </div>
)

export default Description
