import React, {memo, useState} from "react";
import classNames from "classnames";

import {makeFirstLetterUppercase} from "utils";

import {Icon, Button, CheckBox} from "components/atoms";
import DropdownItem from "./DropDownItem";

import "./style.scss"

const FiltersSideBar = ({details, applyCallBack, setSideBarDetailsCallBack}) => {
    const [isOpenSideBar, setIsOpenSideBar] = useState(true)

    const sideBarContainerStyle = classNames("sidebar-container", {"open": isOpenSideBar})
    const sideBarHeaderStyle = classNames("sidebar-container_header", {"sideBarOpen": isOpenSideBar})

    return (
        <div className={sideBarContainerStyle}>
            <div className={sideBarHeaderStyle}>
                <h6>Filters</h6>
                <div onClick={() => setIsOpenSideBar(!isOpenSideBar)}><Icon name="left"/></div>
            </div>
            <div className="sidebar-container_content">
                {React.Children.toArray(
                    details.map((section) =>
                        <DropdownItem open={section.open} title={makeFirstLetterUppercase(section.name)}>
                            {
                                section.attributes &&
                                section?.attributes.map(attribute =>
                                    <div key={attribute.id}>
                                        <div>{attribute.name}</div>
                                        <div>
                                            <CheckBox
                                                view="white"
                                                extent="small"
                                                name="attribute"
                                                checked={attribute.checked}
                                                onChange={() => setSideBarDetailsCallBack(
                                                    attribute,
                                                    section.name,
                                                )}
                                            />
                                        </div>
                                    </div>
                                )
                            }
                        </DropdownItem>
                    )
                )}
            </div>
            <div className="sidebar-container_apply-btn">
                <Button onClick={() => applyCallBack()}>Apply</Button>
            </div>
        </div>
    )
}
export default memo(FiltersSideBar)