import React from "react";
import classNames from "classnames";
import Image from "components/atoms/Image";
import "./style.scss"

const Row = ({src, title, imgRight, children}) => {
    const rowStyle = classNames("row", {right: imgRight})

    return (
        <div className={rowStyle}>
            <div className="row_img">
                <Image alt={title} src={src}/>
            </div>
            <div className="row_content">
                {title && <h4>{title}</h4>}
                <div className="row_content_text">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Row