import React, {useEffect} from 'react';

import {useGlobalModalsContext} from "layouts/context/GlobalModalsProvider";
import {useClaimInventoryMutation} from "store/FreeItem/freeItem.api";
import {ERROR, GLOBAL_MODALS, STEPS_STATUS} from "utils";
import {useModalsContext} from "layouts";
import {useAuthorization} from "hooks";

import Button from "components/atoms/Button";

const ClaimButton = ({inventory}) => {
    const {setModal,setMessage,resetStatesAndClose} = useGlobalModalsContext()
    const {setCurrentModal} = useModalsContext()
    const {isAuth} = useAuthorization()

    const [claim, {isError, isSuccess, isLoading}] = useClaimInventoryMutation()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => monitoringClaimProcess(), [isError, isSuccess])

    const monitoringClaimProcess = () => {
        if (isSuccess) setCurrentModal({status: STEPS_STATUS.CLAIM_SUCCESS, data: {inventory}})
        if (isError) {
            setModal(GLOBAL_MODALS.ERROR)
            setMessage(ERROR.WRONG)
            resetStatesAndClose()
        }
    }

    const buttonDetails = {
        onClick: () => isAuth ? claim() : setModal(GLOBAL_MODALS.SIGN_IN),
        text: () => isAuth ? "Claim item" : "Sign in"
    }

    return (
        <Button disabled={isLoading} onClick={buttonDetails.onClick}>
            {buttonDetails.text()}
        </Button>
    )
}

export default ClaimButton