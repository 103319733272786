import React from "react";
import TablePriceBlock from "./TablePriceBlock";

const TableBody = ({body, head}) => {
    return (
        <tbody>
        {
            body?.map((item, index) =>
                <tr key={index}>
                    {
                        head.map((elm, index) =>
                            <td className={elm.className} key={index}>
                                {
                                    elm.value === "name"
                                        ? <>
                                            <div className={`img-block ${item.rarity}-small`}>
                                                <img alt="cosmetic" src={item?.["image"]}/>
                                            </div>
                                            <span>{item["name"]}</span>
                                        </>
                                        : <div>
                                            {elm.value === "price"
                                                ? item.currency
                                                    ? <TablePriceBlock currency={item.currency} price={item[elm.value]}/>
                                                    : <span>{item[elm.value]}</span>
                                                : <span>{item[elm.value]}</span>}
                                        </div>
                                }
                            </td>
                        )
                    }
                </tr>
            )
        }
        </tbody>
    )
}
export default TableBody