import React from "react";
import Image from "components/atoms/Image";
import {png} from "assets/png";
import Icon from "components/atoms/Icon";

const DeckInfo = () => {
    return (
        <section className="deck_info">
            <div className="deck_info_contact container">
                <Image alt="modernize" src={png.LogoModernizeGame}/>
                <ul>
                    <li><Icon name="phoneBack"/> <span>+1 2345 567890</span></li>
                    <li><Icon name="kompete"/><span>j@modernizegames.com</span></li>
                    <li><Icon name="locationBack"/><span>7825 Fay Ave. Ste. 200. La Jolla, California 92037</span></li>
                </ul>
            </div>
            <div/>
        </section>
    )
}

export default DeckInfo