import React from "react";

import {useModalsContext} from "layouts";
import {STEPS_STATUS} from "utils";

import CosmeticSuccessModalTemplate from "components/molecules/CosmeticModalTemplate";

const DivinePurchaseSuccessfulModal = ({show}) => {
    const {currentModal, setCurrentModal} = useModalsContext()
    const {inventory, quantity} = currentModal.data

    return (
        <CosmeticSuccessModalTemplate
            show={show}
            isShowClose={true}
            quantity={quantity}
            name={inventory.name}
            rarity={inventory.rarity}
            image={inventory.image_url}
            title="Purchase successful!"
            closeClick={() => setCurrentModal({status: STEPS_STATUS.CLOSE})}
            buttonOne={{text: "Back to shop", callBack: () => setCurrentModal({status: STEPS_STATUS.CLOSE})}}
        />
    )
}

export default DivinePurchaseSuccessfulModal