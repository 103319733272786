export const createFetchBaseQuery = ({baseUrl, isAuthorization}) => async ({url, method, body}) => {
    try {
        const headers = {'Content-Type': 'application/json'};

        if (isAuthorization) {
            const bearerToken = localStorage.getItem('accessToken');
            headers['Authorization'] = `Bearer ${bearerToken}`;
        }

        const response = await fetch(`${baseUrl}/${url}`, {
            method,
            body: body ? JSON.stringify(body) : undefined,
            headers
        });

        if (response.ok) {
            let data = await response.json();
            return {data, error: null};
        } else {

            let error = {
                status: response.status,
                data: await response.json(),
            };
            return {data: null, error};
        }
    } catch (fetchError) {
        let error = {status: 'fetch error', data: fetchError.message};
        return {data: null, error};
    }
}

export default createFetchBaseQuery;

