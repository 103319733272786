import {useModalsContext} from "layouts";
import {useConnectedWallet} from "hooks";

import {web3Utils} from "web3/utils";
import {ERROR} from "utils";

export const useLocker = () => {
    const {currentModal, setCurrentModal} = useModalsContext()
    const {address, providers} = useConnectedWallet()

    const safeTransfer = async (addressTo, tokenId, amount,chainId) => {
        const {walletConnectSigner} = providers()
        setCurrentModal({...currentModal, status: "process"})

        try {
            const {collection} = await web3Utils.getConfiguration(0,chainId)
            const tx = await collection
                .connect(walletConnectSigner)
                .safeTransferFrom(address, addressTo, tokenId, amount, "0x")

            return tx.wait().then((result) => result)
        } catch {
            throw new Error(ERROR.TRANSFER_FAILED)
        }
    }

    return {
        safeTransfer
    }
}