import {useLocation} from "react-router-dom";
import React from 'react';

import ModernizeFooter from "components/atoms/ModernizeFooter";
import {ButtonLink, ReactPlayer, Seo} from "components/atoms";

const Success = () => {
    const {pathname} = useLocation()

    const deviceDetails = () => {
        switch (pathname) {
            case "/lp/pc-02-success":
                return {link: "https://www.modernizegames.com/ModernizeInstaller.exe", text: "Pc Download"}
            case"/lp/mobile-02-success":
                return {
                    link: "https://kompetecdn.azureedge.net/kompete-game-download/KOMPETE_android.apk",
                    text: "APK Download"
                }
            default:
                return {link: "https://www.modernizegames.com/ModernizeInstaller.exe", text: "Pc Download"}
        }
    }

    return (
        <React.Fragment>
            <Seo title="Free Download"/>
            <div className="landing-page_success">
                <div className="landing-page_success_content container">
                    <h1>SECRET NEW, FUN, & FREE EARLY ACCESS GAME</h1>
                    <h2>With Free Battlepass & Free Legendary Skin</h2>
                    <div className="landing-page_success_content_video-block">
                        <ReactPlayer
                            src="https://player.vimeo.com/video/1004228293?h=c98eeb715e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&muted=1&autoplay=1&loop=1&controls=0"
                            title="VSL_02_4K.mp4"
                        />
                    </div>
                    <div className="landing-page_success_content_links">
                        <ButtonLink href={deviceDetails().link}>{deviceDetails().text}</ButtonLink>
                        <ButtonLink href="https://discord.gg/kompete-921684972786573333" isTarget={true}>
                            Free skin in discord
                        </ButtonLink>
                    </div>
                </div>
                <ModernizeFooter/>
            </div>
        </React.Fragment>
    )
}

export default Success