import React, {useRef, useState} from "react";
import classNames from "classnames";

import {useOutside} from "hooks";
import {Icon} from "components/atoms";

import "./style.scss"

const SelectDropDownDisplay = (
    {
        optionsList,
        name,
        onChange,
        value,
        btnText,
        label,
        error,
        status,
        size,
        labelCallBack,
        labelClickText,
        optionListPosition
    }) => {
    const [isOptionsOpen, setIsOptionsOpen] = useState(false);
    const selectStyle = classNames("select-container_body", status ?? "default", size ?? "lg", {["expanded-cont"]: isOptionsOpen})
    const selectRef = useRef(null)
    const optionStyle = classNames("select-container_body_options", optionListPosition ?? "bottom", {["show"]: isOptionsOpen})
    const buttonStyle = classNames({expanded: isOptionsOpen}, {["set-data"]: value !== null})

    useOutside(selectRef, setIsOptionsOpen)

    const setSelectedThenCloseDropdown = (index) => {
        onChange({target: {name: name, value: {name: optionsList[index].name, value: optionsList[index]}}})
        setIsOptionsOpen(false);
    };

    return (
        <div ref={selectRef} className="select-container">
            <div className="select-container_label-block">
                {label && <label className="select-container_label-block_name">{label}</label>}
                {error && <span className={`select-container_label-block_error ${status}`}>{error}</span>}
                {labelCallBack &&
                    <button
                        className="select-container_label-block_btn"
                        onClick={labelCallBack}>
                        {labelClickText}
                    </button>}
            </div>
            <div className={selectStyle}>
                <button
                    type="button"
                    aria-expanded={isOptionsOpen}
                    className={buttonStyle}
                    onClick={() => setIsOptionsOpen(!isOptionsOpen)}
                >
                    <span>{value == null ? btnText : value.name}</span>
                    <span><Icon name="select"/></span>
                </button>
                <ul
                    className={optionStyle}
                    aria-activedescendant={optionsList[value]}
                >
                    {optionsList?.map((option, index) => (
                        <li
                            key={option.value}
                            id={option.value}
                            aria-selected={option.value === value?.value}
                            onClick={() => setSelectedThenCloseDropdown(index)}
                        >
                            {option.name}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default SelectDropDownDisplay