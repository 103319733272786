import React, {useEffect} from "react"

import classNames from "classnames";

import {useWindowSize, useFixScrollBar} from "hooks";

import NetworkButtonsBlock from "../UserDetails/NetworkButtonsBlock";
import MenuUser from "./MenuUser";
import Menu from "./Menu";

import "./../style.scss"

const MenuMobile = ({mobileMenu, signOut, setMobileMenu}) => {
    const {width} = useWindowSize()

    const navigationMobileStyle = classNames("navigation-mobile", {"mobile-menu": mobileMenu.open && mobileMenu.section === "menu"})
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const contentStyle = classNames("navigation-mobile_content", {safari: isSafari})

    useEffect(() => {
        if (width > 991) {
            setMobileMenu({open: false, section: ""})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width])

    useFixScrollBar(true)

    return (
        <div className={navigationMobileStyle}>
            <div className={contentStyle}>
                {
                    {
                        "menu":
                            <Menu setMobileMenu={setMobileMenu}/>,
                        "userMenu":
                            <MenuUser signOut={signOut}/>
                    }[mobileMenu.section]
                }
                <NetworkButtonsBlock/>
            </div>
        </div>
    )
}

export default MenuMobile
