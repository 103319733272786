import React from "react"
import "./style.scss"

const LoadSpinner = () => {
    return (
            <div className="lds-default">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
    )
}
export default LoadSpinner