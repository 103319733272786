import React from "react";

import {useWindowSize} from "hooks";

import DropdownItem from "components/atoms/DropdownItem";
import Title from "components/atoms/TitleTemplate";

const HomeSpacs = () => {
    const {width} = useWindowSize()

    return (
        <section className="home_tech-specs">
            <Title underline={true}>tech <span>specs</span></Title>
            <div className="home_tech-specs_content container">
                <DropdownItem
                    title="PC Minimum Specifications"
                    open={width > 991}
                >
                    <div className="home_tech-specs_content_dropdown">
                        <ul>
                            <SpecItem label="Operating system" text="Windows 10 64-bit"/>
                            <SpecItem label="CPU" text="Intel Core i3-4340 or AMD FX-6300"/>
                            <SpecItem label="RAM" text="8 GB RAM"/>
                            <SpecItem label="Storage Required" text="10 GB Space"/>
                        </ul>
                        <ul>
                            <SpecItem label="Video" text="NVIDIA GeForce 1060 / Intel HD 4000"/>
                            <SpecItem label="Direct X" text="Requires DirectX 11 Compatible System"/>
                            <SpecItem label="Network" text="Broadband Internet connection"/>
                            <SpecItem label="sound card" text="DirectX Compatible"/>
                        </ul>
                    </div>
                </DropdownItem>
                <DropdownItem
                    title="iOS Minimum Specifications"
                    open={width < 991}
                >
                    <div className="home_tech-specs_content_dropdown">
                        <ul>
                            <SpecItem label="Operating system" text="iOS 15"/>
                            <SpecItem label="Device" text="iPhone 13 Pro, iPad Pro (4th generation)"/>
                            <SpecItem label="RAM" text="6 GB"/>
                            <SpecItem label="Storage Required" text="3 GB"/>
                        </ul>
                        <ul>
                            <SpecItem label="Network" text="Broadband Internet connection"/>
                        </ul>
                    </div>
                </DropdownItem>
                <DropdownItem
                    title="Android Minimum Specifications"
                    open={width < 991}
                >
                    <div className="home_tech-specs_content_dropdown">
                        <ul>
                            <SpecItem label="Operating system" text="Android 11"/>
                            <SpecItem label="RAM" text="6 GB"/>
                            <SpecItem label="CPU" text="Adreno 650"/>
                            <SpecItem label="Storage Required" text="3 GB"/>
                        </ul>
                        <ul>
                            <SpecItem label="Network" text="Broadband Internet connection"/>
                        </ul>
                    </div>
                </DropdownItem>
            </div>
        </section>
    )
}
export default HomeSpacs

const SpecItem = ({label, text}) => (
    <li>
        <label>{label}</label>
        <p>{text}</p>
    </li>
)