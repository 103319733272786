import {Navigate, useLocation, useParams} from "react-router-dom";
import React, {useEffect} from "react";

import {useGetCurrentInventoryQuery} from "store/Locker/locker.api";
import {ModalsProvider, DefaultLayout} from "layouts";
import {TRANSACTION_HISTORY_SECTION} from "utils";
import {useGMTEvents} from "hooks";

import {
    LockerCurrentCosmeticSteps,
    LockerCurrentCosmeticPriceHistory,
    LockerCurrentCosmeticTransactionHistory,
    LockerCurrentNotListedWeb3CosmeticSection
} from "components/organisms/Locker";
import {RecentlyViewedItemsSection} from "components/molecules";
import ProtectedPage from "components/molecules/ProtectedPage";

const LockerCurrentNotListedWeb3Cosmetic = () => {
    const viewStart = new Date().getTime()

    const {sku, token_id} = useParams()
    const {viewSpentTimeProcess} = useGMTEvents()
    const {state} = useLocation()

    const {data, isLoading} = useGetCurrentInventoryQuery(sku)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => () => viewSpentTimeProcess(viewStart, token_id), []);

    if (!state?.request) {
        return <Navigate to={"/locker"}/>
    }

    return (
        <DefaultLayout>
            <ProtectedPage>
                <ModalsProvider>
                    <LockerCurrentNotListedWeb3CosmeticSection
                        sku={sku}
                        data={data}
                        tokenId={token_id}
                        isLoading={isLoading}
                    />
                    <LockerCurrentCosmeticPriceHistory data={data}/>
                    <LockerCurrentCosmeticTransactionHistory
                        data={data}
                        transactionHistorySection={TRANSACTION_HISTORY_SECTION.INVENTORY_ACTIVITY}
                    />
                    <RecentlyViewedItemsSection/>
                    <LockerCurrentCosmeticSteps/>
                </ModalsProvider>
            </ProtectedPage>
        </DefaultLayout>
    )
}

export default LockerCurrentNotListedWeb3Cosmetic