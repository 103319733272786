import React from 'react';

import ProgressiveImage from "components/atoms/ProgressiveImage";
import {Button} from "components/atoms";

import {png} from "assets/png";
import "./style.scss"

const DownloadDiscord = () => (
    <section className="download_discord">
        <div className="download_discord_content container">
            <div className="download_discord_content_info">
                <h2>Get a free legendary reward for joining discord!</h2>
                <ProgressiveImage
                    alt="weapon"
                    imgSrc={png.SecretAssaultRifleWeaponPng}
                    previewSrc={png.SecretAssaultRifleWeaponWebp}
                    className="download_discord_content_info_weapon"
                />
                <Button
                    onClick={() => window.open("https://discord.gg/kompete-921684972786573333", "_blank")}
                    icon="discordBlack"
                    path={true}
                >
                    Join now!
                </Button>
            </div>
            <div className="download_discord_content_reward">
                <ProgressiveImage
                    alt="weapon"
                    imgSrc={png.SecretAssaultRifleWeaponPng}
                    previewSrc={png.SecretAssaultRifleWeaponWebp}
                    className="download_discord_content_reward_weapon"
                />
            </div>
        </div>
    </section>
)

export default DownloadDiscord