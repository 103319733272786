import React from "react";
import {Image} from "components/atoms";
import {png} from "assets/png";
import "./style.scss"

const NoCosmetic = ({text}) => {
    return (
        <div className="no-cosmetics">
            <div className="no-cosmetics_content">
                <Image alt="reject" src={png.SadSmile}/>
                <p>{text}</p>
            </div>
        </div>
    )
}
export default NoCosmetic