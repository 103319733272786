import React from "react";
import Title from "components/atoms/TitleTemplate";
import Image from "components/atoms/Image";
import {png} from "assets/png";

const DeckMonetizedBetter = () => {
    return (
        <section className="deck_monetized-better">
            <Title underline={true}>we’re <span>monetized better</span></Title>
            <p className="deck_monetized-better_underTitle">Cosmetic items don’t provide any advantages. Players receive all game
                features for free without paying or grinding. We
                sell and giveaway limited-time/quantity cosmetic items. Everything in KOMPETE is available for purchase on the game
                client and our website. This setup is unique and helps reduce marketplace fees.</p>
            <div className="deck_monetized-better_content container">
                <div className="deck_monetized-better_content_item">
                    <Image alt="hero" src={png.HeroIndividual7}/>
                    <h4>Cosmetic based monetization</h4>
                </div>
                <div className="deck_monetized-better_content_item">
                    <Image  alt="hero" src={png.HeroIndividual9}/>
                    <h4>No pay to win</h4>
                </div>
                <div className="deck_monetized-better_content_item">
                    <Image  alt="hero" src={png.HeroIndividual8}/>
                    <h4>Limited time & quantity cosmetics</h4>
                </div>
            </div>
        </section>
    )
}

export default DeckMonetizedBetter