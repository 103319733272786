import classNames from "classnames";
import React from "react"

import {CHART_PERIODS} from "utils";

import "./style.scss"

const AreaPeriodButtons = ({isDisabled, currentPeriodCallBack, checkedPeriod}) => {
    const periodButtonStyle = (period) => classNames({"checked": checkedPeriod === period})

    return (
        <div className="area_period-buttons">
            {React.Children.toArray(
                CHART_PERIODS.map(((period) => (
                        <button
                            disabled={isDisabled}
                            className={periodButtonStyle(period)}
                            onClick={() => currentPeriodCallBack(period)}
                        >
                            {period}
                        </button>)
                ))
            )}
        </div>
    )
}

export default AreaPeriodButtons