import {createApi} from '@reduxjs/toolkit/query/react'

import createFetchBaseServerQuery from "config/createFetchBaseServerQuery";
import {BAZAAR, LOCKER} from "../endpoint";
import {ENVIRONMENTS} from "utils";

export const bazaarApi = createApi({
    reducerPath: 'bazaar/api',
    tagTypes: ['OrderList', 'OrderCancel', 'OrderUpdate', 'OrdersCheck', 'OrderTrade'],
    baseQuery: createFetchBaseServerQuery({baseUrl: ENVIRONMENTS.MARKET_URL, isAuthorization: true}),
    endpoints: (builder) => ({
        getListedInventories: builder.query({
            query: (data) => ({
                url: BAZAAR.LISTED_ORDERS,
                body: data,
                method: "POST",
            }),
            keepUnusedDataFor: 5,
            transformResponse: (response) => response.data,
            providesTags: () => ['OrderList', 'OrderCancel', 'OrderUpdate', 'OrdersCheck', 'OrderTrade'],
        }),
        listCurrentInventory: builder.mutation({
            query: (data) => ({
                url: LOCKER.CREATE_ORDER,
                method: "POST",
                body: {data},
            }),
            invalidatesTags: () => ['OrderList'],
        }),
        cancelListedOrder: builder.mutation(({
            query: (data) => ({
                url: BAZAAR.CANCEL_LISTED_ORDER,
                method: "POST",
                body: {data}
            }),
            invalidatesTags: () => ['OrderCancel']
        })),
        updateListedOrder: builder.mutation(({
            query: (data) => ({
                url: BAZAAR.UPDATE_LISTED_ORDER,
                method: "POST",
                body: {data},
            }),
            transformResponse: (response) => response.data,
            invalidatesTags: () => ['OrderUpdate']
        })),
        checkListedOrdersExpiration: builder.mutation(({
            query: (data) => ({
                url: BAZAAR.CHECK_ORDERS,
                method: "POST",
                body: data,
            }),
            invalidatesTags: () => ['OrdersCheck']
        })),
        sendTransactionHash: builder.mutation(({
            query: (data) => ({
                url: BAZAAR.SEND_TRANSACTION_HASH,
                method: "POST",
                body: {data},
            }),
            invalidatesTags: () => ['OrderTrade']
        }))
    }),
})

export const {
    useGetListedInventoriesQuery,
    useCancelListedOrderMutation,
    useUpdateListedOrderMutation,
    useSendTransactionHashMutation,
    useListCurrentInventoryMutation,
    useCheckListedOrdersExpirationMutation
} = bazaarApi