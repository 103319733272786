import React from "react";

import ProgressiveImage from "components/atoms/ProgressiveImage";
import Title from "components/atoms/TitleTemplate";
import ItemBlock from "./ItemBlock";

import {png} from "assets/png";
import "./style.scss"

const EcoModular = () => {

    return (
        <section className="economy_modular container">
            <Title underline={true}>Modular <span>character <br/> customization</span></Title>
            <div className="economy_modular_content">
                <ItemBlock
                    reverse={true}
                    background={false}
                    className="item-one"
                    orgImage={png.HeroIndividual5Png}
                    noOrgImage={png.HeroIndividual5Webp}
                    title="Look Good. Feel good. Play Good."
                >
                    Customize your character with modular options! Edit your avatar’s hair, skin tone, clothing, shoes,
                    equipment, and more!
                </ItemBlock>
                <div className="row-left-img item-two">
                    <div>
                        <ProgressiveImage imgSrc={png.BazaarCosmeticsPng} previewSrc={png.BazaarCosmeticsWebp}/>
                    </div>
                    <div>
                        <h2>Tons of unique cosmetics to use</h2>
                        <p>We have a boatload of cosmetics for you to collect, equip, and express yourself with. Step
                            into your next game oozing cool or max clowning, the choice is yours! </p>
                    </div>
                </div>
                <div className="row-right-img item-three">
                    <div>
                        <h2>Six different cosmetic rarities</h2>
                        <p>Not all cosmetics are cut from the same cloth! KOMPETE has six different cosmetic rarities:
                            Ordinary, Common, Rare, Elite, Legendary, and Divine.</p>
                    </div>
                    <div>
                        <ProgressiveImage imgSrc={png.RarityComponentsPng} previewSrc={png.RarityComponentsWebp}/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EcoModular

