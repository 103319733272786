import React from "react"

import Image from "components/atoms/Image";
import FooterModernize from "components/atoms/ModernizeFooter";

import {png} from "../../assets/png";

import "./style.scss"

const Links = () => {
    return (
        <div className="links">
            <div className="links_block">
                <div className="links_block_logo">
                    <Image src={png.LogoKompete} alt="logo"/>
                </div>
                <LinkCart
                    text="Official website"
                    href="https://kompete.game"
                />
                <LinkCart
                    text="Download early access"
                    href="https://kompete.game/download"
                />
                <LinkCart
                    text="discord"
                    href="https://discord.gg/kompete-921684972786573333"
                />
                <LinkCart
                    text="telegram"
                    href="https://t.me/KompeteGameEntryPortal"
                />
                <LinkCart
                    text="Buy kompete token (4% slippage)"
                    href="https://app.uniswap.org/#/swap?outputCurrency=0x1e0b2992079b620aa13a7c2e7c88d2e1e18e46e9"
                />
            </div>
            <FooterModernize/>
        </div>
    )
}

const LinkCart = ({text, href, description}) => {
    return (
        <button>
            <a aria-label={description} href={href} target="_blank" rel="noreferrer">{text}</a>
        </button>
    )
}

export default Links
