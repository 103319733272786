import React, {createContext, useContext, useState} from "react"


const ModalContext = createContext()
export const ModalsProvider = ({children, changeStep}) => {
    const [currentModal, setCurrentModal] = useState({status: "", data: "", message: ""})

    return (
        <ModalContext.Provider value={{currentModal, setCurrentModal,changeStep}}>
            {children}
        </ModalContext.Provider>
    )
}

export const useModalsContext = () => useContext(ModalContext)