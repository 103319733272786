import {formatUnits, parseUnits} from "ethers/lib/utils";
import {Wallet} from "ethers";

import {
    OrderSide,
    signOrder,
    getERC20Contract,
    getFeeFromPercent,
    createOrderWithFees,
    getGameAssetContract,
    getMarketplaceContract,
    getGameAssetFactoryContract
} from "@modernize-games/nft-sdk";
import {ERC20, KompeteERC721} from "web3/ABI"

import {UniswapV2Router02} from "./ABI/UniswapV2Router02";
import configs from "./config"

const ethers = require('ethers')

const getConfiguration = async (feePercent, chainId) => {
    const chain = configs.chains[configs.networkEnvironment].find(chain => chain.chainId === chainId)
    const {rpcUrl} = chain

    const {
        marketplace: marketplaceAddress,
        factory: factoryAddress,
        collection: collectionAddress,
        payment: paymentAddress
    } = chain.contractAddress

    const providerRPC = new ethers.providers.JsonRpcProvider(rpcUrl)

    const marketplace = getMarketplaceContract(marketplaceAddress, providerRPC)
    const factory = getGameAssetFactoryContract(factoryAddress, providerRPC)
    const collection = getGameAssetContract(collectionAddress, providerRPC)

    const [tokenAddress, treasury] = await Promise.all([
        marketplace.exchangeToken(),
        marketplace.protocolFeeRecipient(),
    ])

    const percent = feePercent ?? 90
    const fees = getFeeFromPercent(percent / 100)
    const paymentToken = getERC20Contract(tokenAddress, providerRPC)

    return {
        providerRPC,
        marketplace,
        factory,
        fees,
        collection,
        tokenAddress,
        treasury,
        paymentToken,
        paymentAddress
    }
}

const loadTokenInfos = async (token: ERC20) => {
    const [symbol, name, decimals] = await Promise.all([
        token.name(),
        token.decimals(),
    ])
    return {
        symbol,
        name,
        decimals,
    }
}

export const createOrderWithSignature = async (
    {
        chainId,
        signer,
        token,
        account,
        price,
        amount,
        expiration,
        tokenId,
        orderSide,
        admin,
        feePercent,
        mint
    }
) => {
    const {
        marketplace,
        collection,
        paymentToken,
        treasury,
        providerRPC,
        factory,
        fees
    } = await getConfiguration(feePercent, chainId)
    const decimals = await paymentToken.decimals()

    const parsePrice = parseUnits(price, token === "eth" ? 18 : decimals)

    const amounts = Array.isArray(amount) ? [...amount] : [amount]
    const tokenIds = Array.isArray(tokenId) ? [...tokenId] : [tokenId]
    const expirationTime = expiration ? expiration : 0
    const paymentTokenForOrder = token === "eth" ? ethers.constants.AddressZero : paymentToken.address

    const {address, chainId: networkChainId, owner} = await orderOwnerDetails(admin)

    try {
        const order = await createOrderWithFees(
            {
                from: address,
                side: OrderSide[orderSide],
                tokenIds,
                amounts,
                factory: mint ? factory.address : undefined,
                collection: collection.address,
                paymentToken: paymentTokenForOrder,
                price: parsePrice,
                mint: mint,
                expirationTime,
            },
            treasury,
            fees,
        )

        return signOrder(
            marketplace.address,
            networkChainId,
            order,
            owner,
            await marketplace.nonces(address),
        ).then(signature => {
            return {
                signature,
                order
            }
        })

    } catch (error) {
        return false
    }

    async function orderOwnerDetails(isAdmin) {

        if (isAdmin) {
            const adminWallet = new Wallet(process.env.REACT_APP_SELLER_WALLET_KEY, providerRPC)
            const chainId = (await providerRPC.getNetwork()).chainId

            return {
                address: adminWallet.address,
                chainId,
                owner: adminWallet,
                provider: providerRPC
            }
        } else {
            let provider = signer.provider
            const chainId = (await provider.getNetwork()).chainId
            const owner = signer

            return {
                address: account,
                provider,
                chainId,
                owner,
            }
        }
    }
}

const getKompeteBalance = async (address) => {
    try {
        const paymentAddress = configs.chains.mainNet[0].contractAddress.payment
        const rpcUrl = configs.chains.mainNet[0].rpcUrl

        const provider = new ethers.providers.JsonRpcProvider(rpcUrl)
        const paymentContract = new ethers.Contract(paymentAddress, ERC20, provider)
        const kompeteBalance = await paymentContract.balanceOf(address)

        return Number(formatUnits(kompeteBalance, 10))
    } catch {
        return 0
    }
}

const getOneKompetePrice = async () => {
    try {
        const paymentAddress = configs.chains.mainNet[0].contractAddress.payment
        const rpcUrl = configs.chains.mainNet[0].rpcUrl

        const provider = new ethers.providers.JsonRpcProvider(rpcUrl)
        const paymentContract = new ethers.Contract(paymentAddress, KompeteERC721, provider)
        const swapRouterAddress = await paymentContract.swapRouter()

        const swapRouterContract = new ethers.Contract(swapRouterAddress, UniswapV2Router02, provider)

        const inputAmount = parseUnits('1', 10)
        const inputTokenAddress = "0x1e0b2992079b620aa13a7c2e7c88d2e1e18e46e9"
        const outputTokenAddress = "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"
        const path = [inputTokenAddress, outputTokenAddress]

        const currentPriceBigInt = await swapRouterContract.getAmountsOut(inputAmount, path)

        const currentKompetePrice = formatUnits(currentPriceBigInt[1], 18)

        const currentETHPrice = await getEthPrice()

        return currentKompetePrice * currentETHPrice
    } catch {
        return 0
    }
}

export const getEthPrice = async () => {
    return fetch("https://api.coinbase.com/v2/prices/eth-usd/spot")
        .then(result => result.json())
        .then(result => result.data.amount)
        .catch(() => {
            return 0
        })
}

export const web3Utils = {
    getConfiguration,
    loadTokenInfos,
    createOrderWithSignature,
    getKompeteBalance,
    getOneKompetePrice,
    getEthPrice
}