import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react"
import {useNavigate} from "react-router-dom";
import jwt_decode from "jwt-decode";
import classNames from "classnames";

import {reset, setData, userGetData, userVerificationSocial} from "store/AuthV2/AuthV2Slice"
import {useAuthorization, useConnectWallet, useGMTEvents, useLocalStorage} from "hooks";
import {useGlobalModalsContext} from "layouts/context/GlobalModalsProvider";
import {globalService} from "store/Global/globalService";
import navPaths from "./navPathData.json"
import {GLOBAL_MODALS} from "utils";

import {LoadSpinnerAllPage, Image} from "components/atoms";
import {HeaderTopBanner} from "components/organisms";
import NavItem from "./ResuableComponents/NavItem";
import UserDetails from "./UserDetails";
import MenuMobile from "./menuMobile";

import {png} from "assets/png";
import "./style.scss"

const Header = ({onlyLogo = false, banner}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {
        removeToken,
        removeSyncDate,
        removeLoginType,
        removeSignInNetwork,
        removeSignUpNetwork,
        isSignUpMethodAvailable,
        isSignInMethodAvailable
    } = useLocalStorage()
    const {setDataLayerDefaultData, userSignIn, userSignUp} = useGMTEvents()
    const {isAuth, accessToken} = useAuthorization()
    const {disconnectWallet} = useConnectWallet()
    const {setModal} = useGlobalModalsContext()

    const [mobileMenu, setMobileMenu] = useState({open: false, section: ""})

    const {
        error: userVerificationError,
        loading: userVerificationLoading
    } = useSelector(state => state.authV2.userVerification)
    const {error: userDataXsollaError} = useSelector(state => state.authV2.userDataXsolla)
    const {userData, success: signInSuccess} = useSelector(state => state.authV2.signIn)

    const menuHeaderClass = classNames(
        "header",
        {"mobile-menu": mobileMenu.open && mobileMenu.section === "menu"},
        {"logoCenter": onlyLogo},
        {"banner-place": banner}
    )

    const navigationMobileClass = classNames("navigation_auth_btn-mobile", {"btn-close": mobileMenu.open})

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => checkAndGetUserDataAfterRefresh(), [userDataXsollaError, userVerificationError])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => checkIfUserRegisterNickname(), [signInSuccess])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => followUrlAfterSocialAuthRedirect(), []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => followAuthForGlobalProcess(), [isAuth])

    const signOut = () => {
        removeToken()
        removeSyncDate()
        removeLoginType()
        disconnectWallet()

        dispatch(setData({path1: "signIn", path2: "userData", data: {}}))
        setMobileMenu({open: false, section: ""})
        dispatch(reset("signIn"))
        navigate("/")
    }

    const checkAndGetUserDataAfterRefresh = () => {
        if (accessToken && !userData.accessToken) dispatch(userGetData(accessToken))

        if (userDataXsollaError && userVerificationError) signOut()
    }

    const checkIfUserRegisterNickname = () => {
        if (signInSuccess && userData && !userData?.nickname) setModal(GLOBAL_MODALS.CREATE_NICKNAME)
    }

    const followUrlAfterSocialAuthRedirect = () => {
        const href = new URL(window.location.href).searchParams
        const token = href.get("token")

        if (token) {
            try {
                let decode = jwt_decode(token)

                if (decode) {
                    dispatch(userVerificationSocial(token))
                    navigate("/")
                }
            } catch (error) {
                navigate("/")
            }
        }
    }

    const followAuthForGlobalProcess = () => {
        if (isAuth) {
            globalService.checkSyncDateAndSendInventories()
            setDataLayerDefaultData()

            if (isSignInMethodAvailable()) {
                userSignIn(userData.id, isSignInMethodAvailable())
                removeSignInNetwork()
            }

            if (isSignUpMethodAvailable()) {
                userSignUp(userData.id, isSignUpMethodAvailable())
                removeSignUpNetwork()
            }
        }
    }

    return (
        <React.Fragment>
            {banner && <HeaderTopBanner banner={banner}/>}
            <header className={menuHeaderClass}>
                <div className="navigation">
                    <div className="navigation_logo">
                        <div className="navigation_logo_block">
                            <Image onClick={() => navigate("/")} src={png.LogoKompete} alt="logo"/>
                        </div>
                    </div>
                    {
                        !onlyLogo &&
                        <React.Fragment>
                            <div className="navigation_pages">
                                <ul>
                                    {React.Children.toArray(
                                        navPaths.map(({notLink, name, path, route}) =>
                                            <NavItem
                                                name={name}
                                                path={path}
                                                route={route}
                                                notLink={notLink}
                                                setMobileMenu={setMobileMenu}
                                            />
                                        )
                                    )}
                                </ul>
                            </div>
                            <div className="navigation_auth">
                                {!mobileMenu.open && <UserDetails signOut={signOut} SetMobileMenu={setMobileMenu}/>}
                                <div className={navigationMobileClass}
                                     onClick={() => setMobileMenu({
                                         open: !mobileMenu.open,
                                         section: !mobileMenu.open ? "menu" : ""
                                     })}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                </div>
            </header>
            {mobileMenu.open &&
                <MenuMobile
                    signOut={signOut}
                    mobileMenu={mobileMenu}
                    setMobileMenu={setMobileMenu}
                />}
            <LoadSpinnerAllPage show={userVerificationLoading}/>
        </React.Fragment>
    )
}

export default Header