const settings = {
    merchant_id: process.env.REACT_APP_XSOLLA_MERCHANT_ID,
    project_id: process.env.REACT_APP_XSOLLA_KOMPETE_PROJECT_ID,
    api_key: process.env.REACT_APP_XSOLLA_API_KEY
}

const buyVoolah = (data, thunkAPI) => {
    const {itemSku, accessToken, ui, searchParams} = data
    const options = {
        locale: "en",
        settings: {
            return_url: `${window.location.origin}/shop?${searchParams}`,
            redirect_policy: {
                redirect_condition: "none",
                status_for_manual_redirection: "none"
            },
            disable_saved_methods: false,
            ui: {
                theme: "dark",
                size: ui.size,
                version: ui.version,
                mobile: {
                    header: {
                        close_button: true
                    }
                }
            }
        },
    }

    return fetch(`https://store.xsolla.com/api/v2/project/${settings.project_id}/payment/item/${itemSku}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify(options)
    })
        .then(async (result) => {
            const data = await result.json()

            if (result.status === 200) {
                const {size} = ui
                let options = {
                    access_token: data.token,
                    lightbox: {
                        width: size === "large" ? 744 : 630,
                        height: size === "large" ? 680 : 610,
                        spinner: "round",
                        zIndex: 100000
                    }
                };

                let optionsMobile = {
                    access_token: data.token,
                    lightbox: {
                        spinner: "round",
                        zIndex: 100000
                    },
                    childWindow: {
                        target: "_self"
                    }
                }

                window.XPayStationWidget.init(ui.version === "desktop" ? options : optionsMobile)
                return data
            }

            return thunkAPI.rejectWithValue(data.message)
        })
}


const shopService = {
    buyVoolah,
}

export default shopService