import React, {useState, memo} from "react";

import {makeFirstLetterUppercase} from "utils";
import {useFixScrollBar} from "hooks";

import {CheckBox, Button, Icon} from "components/atoms";
import DropdownItem from "./DropDownItem";

import "./style.scss"

const FiltersMobile = ({details, applyCallBack, setSideBarDetailsCallBack}) => {
    const [isOpenSideBar, setIsOpenSideBar] = useState(false)

    useFixScrollBar(isOpenSideBar)

    const apply = () => {
        applyCallBack()
        setIsOpenSideBar(false)
    }

    return (
        <div className="filter-mobile-wrapper">
            <button className="filter-mobile-wrapper_btn" onClick={() => setIsOpenSideBar(true)}>
                <Icon name="filter"/>
                <span>Filter</span>
            </button>
            {
                isOpenSideBar &&
                <div className="filter-mobile-wrapper_open">
                    <div className="filter-mobile-wrapper_open_head">
                        <h4>Filters</h4>
                        <button
                            className="filter-mobile-wrapper_open_head_close"
                            onClick={() => setIsOpenSideBar(false)}
                        >
                            <Icon name="close"/>
                        </button>
                    </div>
                    <div className="filter-mobile-wrapper_open_content">
                        {React.Children.toArray(
                            details.map((section) =>
                                <DropdownItem open={section.open} title={makeFirstLetterUppercase(section.name)}>
                                    {
                                        section.attributes &&
                                        section?.attributes.map(attribute =>
                                            <div key={attribute.id}>
                                                <div>{attribute.name}</div>
                                                <div>
                                                    <CheckBox
                                                        view="white"
                                                        extent="small"
                                                        name="attribute"
                                                        checked={attribute.checked}
                                                        onChange={() => setSideBarDetailsCallBack(
                                                            attribute,
                                                            section.name,
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    }
                                </DropdownItem>
                            )
                        )}
                    </div>
                    <div className="filter-mobile-wrapper_open_btn-block">
                        <Button onClick={() => apply()}>Apply</Button>
                    </div>
                </div>
            }
        </div>
    )
}

export default memo(FiltersMobile)