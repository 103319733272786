import {createSlice, createAsyncThunk} from "@reduxjs/toolkit"
import {divineShopService} from "./divineShopService";

const initialState = {
    currentCosmetic: {
        loading: false,
        success: false,
        error: false,
        errorMessage: null,
        data: null
    },

    transactionHash: {
        success: false,
        error: false,
        errorMessage: null,
    }
}

export const getCurrentDivineNFT = createAsyncThunk("get/divine/NFT", async (_, thunkAPI) => {
    try {
        return await divineShopService.getCurrentDivineNFT(thunkAPI)
    } catch (error) {
        return thunkAPI.rejectWithValue("Something went wrong")
    }
})

export const createNFTBuyOrder = createAsyncThunk("create/NFT/buy/order", async (data, thunkAPI) => {
    try {
        return await divineShopService.createNFTBuyOrder(thunkAPI, data)
    } catch (error) {
        return thunkAPI.rejectWithValue("Something went wrong")
    }
})

const divineShopSlice = createSlice({
    name: "divineShop",
    initialState,
    reducers: {},
    extraReducers: (builder => {
        builder
            .addCase(getCurrentDivineNFT.pending, (state) => {
                state.currentCosmetic.loading = true
            })
            .addCase(getCurrentDivineNFT.fulfilled, (state, action) => {
                state.currentCosmetic.loading = false
                state.currentCosmetic.success = true
                state.currentCosmetic.error = false
                state.currentCosmetic.data = action.payload
            })
            .addCase(getCurrentDivineNFT.rejected, (state, action) => {
                state.currentCosmetic.loading = false
                state.currentCosmetic.success = false
                state.currentCosmetic.error = true
                state.currentCosmetic.errorMessage = action.payload
            })

            .addCase(createNFTBuyOrder.fulfilled, (state) => {
                state.transactionHash.success = true
                state.transactionHash.error = false
            })
            .addCase(createNFTBuyOrder.rejected, (state, action) => {
                state.transactionHash.success = false
                state.transactionHash.error = true
                state.transactionHash.errorMessage = action.payload
            })
    })
})

export default divineShopSlice.reducer