import React from 'react';

import EconomyCollecting from "../../components/organisms/GameEconomy/EconomyCollecting";
import EconomyBuySell from "../../components/organisms/GameEconomy/EconomyBuySell";
import EconomyYouPay from "../../components/organisms/GameEconomy/EconomyYouPay";
import EconomyMain from "../../components/organisms/GameEconomy/EconomyMain";
import EcoModular from "../../components/organisms/GameEconomy/EcoModular";
import {FreeItemSection} from "components/organisms";
import DefaultLayout from "layouts/DefaultLayout";
import Seo from "components/atoms/Seo";

const FreeItem = () => (
    <DefaultLayout>
        <Seo
            title="Free Item - KOMPETE"
            description=""
        />
        <FreeItemSection/>
        <EconomyMain/>
        <EcoModular/>
        <EconomyCollecting/>
        <EconomyYouPay/>
        <EconomyBuySell/>
    </DefaultLayout>
)

export default FreeItem;