import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import React, {useState} from "react";

import {useGlobalModalsContext} from "layouts/context/GlobalModalsProvider";
import {BUTTON_VARIANT, ERROR, GLOBAL_MODALS, SUCCESS} from "utils";
import {globalService} from "store/Global/globalService";
import {userGetData} from "store/AuthV2/AuthV2Slice";
import {useDebounce, useLocalStorage} from "hooks";

import {ButtonV2, InputV2, Modal} from "components/atoms";

import "./style.scss"

const CreateNicknameModal = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const debounce = useDebounce()

    const {setModal, setMessage, setData, resetStates} = useGlobalModalsContext()
    const {removeToken, setToken} = useLocalStorage()

    const {userData} = useSelector(state => state.authV2.signIn)

    const [isChecking, setIsChecking] = useState(false)
    const [isDisable, setIsDisable] = useState(true)
    const [nickname, setNickname] = useState("")
    const [error, setError] = useState("")

    const checkNickname = async (nickname) => {
        setIsDisable(true)

        if (!nickname) return

        try {
            setIsChecking(true)
            await globalService.checkNickname(nickname)

            setError(ERROR.CLEAN)
            setIsDisable(false)
            setIsChecking(false)
        } catch (error) {
            setIsDisable(true)
            setIsChecking(false)
            setError(error?.message ?? ERROR.WRONG)
        }
    }

    const handleNicknameChange = (value) => {
        setNickname(value)
        debounce(() => checkNickname(value), 500)
    }

    const updateNickname = async () => {
        try {
            setIsChecking(true)

            await globalService.updateNickname(nickname, userData.accessToken)
            dispatch(userGetData(userData.accessToken))
            setToken(userData.accessToken)

            window._paq.push(['trackEvent', 'Nickname', 'Success'])
            window._paq.push(['trackEvent', 'Nickname-more', `${nickname}`])

            setModal(GLOBAL_MODALS.SUCCESS)
            setMessage(SUCCESS.ACCOUNT_CREATED)
            setData(
                {
                    onClick: () => {
                        navigate("/")
                        resetStates()
                        setModal(GLOBAL_MODALS.CLOSE)
                    },
                    text: "Download kompete"
                }
            )
        } catch (error) {
            setIsChecking(false)
            setError(error?.message ?? ERROR.WRONG)
        }
    }

    const signOut = () => {
        removeToken()
        window.location.reload()
        setModal(GLOBAL_MODALS.CLOSE)
    }

    return (
        <Modal
            isOpen={true}
            title="Create Your KOMPETE Nickname"
            onClose={() => signOut()}
        >
            <div className="create-nickname-modal">
                <InputV2
                    name="nickname"
                    label="Nickname"
                    value={nickname}
                    errorMessage={error}
                    onChange={(e) => handleNicknameChange(e.target.value)}
                />
                <div className="create-nickname-modal_rules">
                    <ul>
                        <li>Must be between 3 and 16 characters</li>
                        <li>May contain letters, numbers, non-consecutive dashes, periods, underscores, and spaces.</li>
                    </ul>
                </div>
                <ButtonV2
                    onClick={updateNickname}
                    disabled={!nickname || !isChecking && isDisable}
                    variant={isChecking ? BUTTON_VARIANT.LOADING : BUTTON_VARIANT.DEFAULT}
                >
                    Register Nickname
                </ButtonV2>
            </div>
        </Modal>)
}

export default CreateNicknameModal