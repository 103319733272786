import React from "react"
import {useCountdownTimer} from "hooks";
import "./style.scss"

const CountDown = ({date, day = true, isReloadPage = false, callBackReload}) => {
    const {countdown} = useCountdownTimer(date, day)

    const isCountdownComplete = (countdown, day) =>
        (day ? countdown?.days === "00" : true) &&
        countdown?.hours === "00" &&
        countdown?.minutes === "00" &&
        countdown?.seconds === "00";

    if (isReloadPage && isCountdownComplete(countdown, day)) {
        callBackReload()
    }

    const renderTimeItem = (countdownValue, label) => {
        return <TimeItem countdown={countdownValue} time={`${label}${countdownValue > 1 ? "s" : ""}`}/>
    }

    return (
        <div className="countdown">
            <div className="numbers">
                {day && renderTimeItem(countdown.days, "day")}
                {renderTimeItem(countdown.hours, "hour")}
                {renderTimeItem(countdown.minutes, "minute")}
                {renderTimeItem(countdown.seconds, "second")}
            </div>
        </div>
    )
}

export default CountDown

const TimeItem = ({countdown, time}) => {

    if (time === "seconds" || time === "second" && countdown) {
        return <div className="num-card">
            <span className="num">{countdown}</span>
            <span className="num-label">{time}</span>
        </div>
    }

    return (
        <React.Fragment>
            {countdown !== "00" && countdown && (
                <React.Fragment>
                    <div className="num-card">
                        <span className="num">{countdown}</span>
                        <span className="num-label">{time}</span>
                    </div>
                    <span className="divider">:</span>
                </React.Fragment>)}
        </React.Fragment>)
};