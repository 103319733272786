import {GraphQLClient} from "graphql-request";

export const createGraphFetchBaseQuery = ({baseUrl}) => async ({url, query, body}) => {
    try {
        const graphQLClient = new GraphQLClient(`${baseUrl}${url}`)

        const response = await graphQLClient.request(query, body)

        return {data: response, error: null};
    } catch (fetchError) {
        let error = {status: 'fetch error', data: fetchError.message};
        return {data: null, error};
    }
}

export default createGraphFetchBaseQuery;

