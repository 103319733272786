import React from "react";
import classNames from "classnames";

import {CARD_CHAIN_ICON} from "utils";

import Image from "components/atoms/Image";
import {Icon} from "components/atoms";

import "./style.scss"

const OwnedCard = (
    {
        img,
        name,
        count,
        price,
        trade,
        rarity,
        onClick,
        chainId
    }) => {

    const nftInfoStyle = classNames("nft-card_info", {"big-size": price})
    const nftCartStyle = classNames("nft-card", rarity?.toLowerCase(), {"clickable": !!onClick})

    const isCountVisible = count !== undefined

    const iconTradeType = CARD_CHAIN_ICON[chainId] ?? "unTradeable"

    return (
        <div className="nft-card-wrapper">
            <div className={nftCartStyle} onClick={onClick}>
                {isCountVisible && <div className="nft-card_img_count"><span>x{count}</span></div>}
                {trade && <div className="nft-card_img_trade">
                    <Icon name={iconTradeType}/>
                </div>}
                <div className="nft-card_img">
                    <Image alt="cosmetic" src={img}/>
                </div>
                <div className={nftInfoStyle}>
                    <h6>{name}</h6>
                </div>
                {price &&
                    <div className="nft-card_info_price">
                        <Icon name="kompete"/>
                        <span>{price}</span>
                    </div>}
            </div>
        </div>
    )
}

export default OwnedCard