import React from "react";
import Title from "components/atoms/TitleTemplate";
import Image from "components/atoms/Image";
import {png} from "assets/png";

const DeckZeroBarriers = () => {
    return (
        <section className="deck_zero-barriers">
            <Title underline={true}>Kompete has <span>Zero barriers  to entry</span></Title>
            <p className="deck_zero-barriers_underTitle">Everyone has access to KOMPETE on all major platforms for free & can play
                together with no one having advantages. </p>
            <div className="deck_zero-barriers_carts container">
                <BarrierItem src={png.CubeKompete} title="Free to PLay"/>
                <BarrierItem src={png.TriangleKompete} title="Cross Platform play"/>
                <BarrierItem src={png.PolygonKompete} title="No pay to win"/>
            </div>
        </section>
    )
}

export default DeckZeroBarriers

const BarrierItem = ({src, title}) => {
    return (
        <div className="deck_zero-barriers_carts_item">
            <Image src={src} alt="barriers"/>
            <h6>{title}</h6>
        </div>
    )
}