import React from "react";

import ProgressiveImage from "components/atoms/ProgressiveImage";
import Title from "components/atoms/TitleTemplate";
import {Button} from "components/atoms";

import {png} from "assets/png";
import "./style.scss"

const TokenWhitePaper = () => (
    <section className="token-white-paper">
        <div className="container">
            <Title underline={true}>Be sure to <span>read <br/> our whitepaper!</span></Title>
            <div className="token-white-paper_content">
                <div>
                    <div>
                        <p>Read our whitepaper for a breakdown of the game economy, technical details, and our plans
                            for the future!</p>
                        <Button
                            onClick={() => window.open("https://modernize-games.gitbook.io/kompete-token-whitepaper/", "_blank")}
                        >
                            Read now
                        </Button>
                    </div>
                </div>
                <div>
                    <ProgressiveImage
                        alt="token"
                        imgSrc={png.KompeteTokenBookPng}
                        previewSrc={png.KompeteTokeBookWebp}
                    />
                </div>
            </div>
        </div>
    </section>
)

export default TokenWhitePaper