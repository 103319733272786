import classNames from "classnames";
import ProgressiveImage from "components/atoms/ProgressiveImage";
import React from "react";

const ItemBlock = ({title, className, reverse, background, children, orgImage, noOrgImage}) => {
    const howItemStyle = classNames("item-block", "container", className, {reverse: reverse})
    const homeItemParentStyle = classNames({"red-back": background})

    return (
        <div className={homeItemParentStyle}>
            <div className={howItemStyle}>
                <div>
                    <ProgressiveImage alt="hero" imgSrc={orgImage} previewSrc={noOrgImage}/>
                </div>
                <div>
                    <h3>{title}</h3>
                    <p>{children}</p>
                </div>
            </div>
        </div>
    )
}

export default ItemBlock