import {ERROR} from "../messages/errors";

export const walletAndTransactionErrorHandle = (error, isReturnError = true) => {
    const {message} = error

    if(error.message.length > 100) {
        const codeRegex = /code=([A-Z_]+)/;

        const codeMatch = message.match(codeRegex);

        if (isReturnError) {
            switch (codeMatch[1]) {
                case "ACTION_REJECTED":
                    throw new Error(ERROR.USER_DENIED_SIGNATURE)
                case "CALL_EXCEPTION":
                    throw new Error(ERROR.TRANSACTION_FAILED)
                default:
                    throw new Error(error.message)
            }


        } else {
            switch (codeMatch[1]) {
                case "ACTION_REJECTED":
                    return ERROR.USER_DENIED_SIGNATURE
                case "CALL_EXCEPTION":
                    return ERROR.TRANSACTION_FAILED
                default:
                    throw new Error(error.message)
            }
        }
    }else{
        if (isReturnError) {
            throw new Error(error.message)
        } else {
            Error(error.message)
        }
    }
}