import {useWeb3Modal, useDisconnect} from "@web3modal/ethers5/react"
import {useSelector} from "react-redux";

import {useConnectedWallet} from "./useConnectedWallet";

export const useConnectWallet = () => {
    const {currentChain} = useSelector(state => state.web3)

    const {open} = useWeb3Modal()
    const {disconnect} = useDisconnect()
    const {isConnected} = useConnectedWallet()

    const disconnectWallet = (isFastDisconnect) => {
        const time = isFastDisconnect ? 0 : 1000
        setTimeout(async () => {
            try {
                if (isConnected) await disconnect()
                localStorage.removeItem("wallet-connection")
            } catch (error) {
                console.log(error)
            }
        }, time)
    }

    const openWalletModal = () => {
        open()
        localStorage.removeItem("wallet-connection")
    }

    return {
        openWalletModal,
        disconnectWallet,
        currentChain,
    }
}