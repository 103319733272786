import React from "react";
import classNames from "classnames";
import {Icon} from "components/atoms";
import "./style.scss";

const Button = ({view, size, icon, iconDirection, path, disabled, onClick, downloadUrl, children}) => {
    const buttonStyle = classNames("buttonV3", view ?? "secondary", size ?? "lg", {path: path});
    const iconStyle = classNames("buttonV3_icon", iconDirection ?? "");

    const handleClick = (e) => {
        if (downloadUrl) {
            e.preventDefault();
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = downloadUrl.split('/').pop()
            link.click();
        } else if (onClick) {
            onClick(e);
        }
    };

    return (
        <button onClick={handleClick} disabled={disabled} className={buttonStyle}>
            {icon && <div className={iconStyle}><Icon name={icon}/></div>}
            <div>{children}</div>
        </button>
    );
};

export default Button;
