import React, {useState} from "react";
import classNames from "classnames";

import {INPUT_TYPE, INPUT_VARIANT, POSITION, SIZE} from "utils";

import Icon from "../Icon";

import "./style.scss"

const InputV2 = (
    {
        name,
        value,
        label,
        isError,
        onChange,
        labelClick,
        placeholder,
        errorMessage,
        labelClickText,
        size = SIZE.LARGE,
        disabled = false,
        type = INPUT_TYPE.TEXT,
        variant = INPUT_VARIANT.DEFAULT
    }) => {
    const [passwordFiled, setPasswordFiled] = useState({type: INPUT_TYPE.PASSWORD, check: false})

    const inpClass = classNames(`inp-${variant}`, `inp-${size}`, {error: errorMessage || isError})
    const inpLabelErrorClass = classNames("inp-block_label-block_error", variant, {[POSITION.RIGHT]: !label})

    const changeType = () => {
        setPasswordFiled(prev => {
            const currentType = prev.type === INPUT_TYPE.PASSWORD ? INPUT_TYPE.TEXT : INPUT_TYPE.PASSWORD

            return {...prev, type: currentType, check: !prev.check}
        })
    }

    return (
        <div className="inp-block">
            <div className={`inp-block_label-block ${variant}`}>
                {label && <label className="inp-block_label-block_name">{label}</label>}
                {errorMessage && <span className={inpLabelErrorClass}>{errorMessage}</span>}
                {labelClick &&
                    <button
                        className="inp-block_label-block_btn"
                        disabled={disabled}
                        onClick={labelClick}
                    >
                        {labelClickText}
                    </button>}
            </div>
            <input
                name={name}
                value={value}
                autoComplete="off"
                disabled={disabled}
                onChange={onChange}
                className={inpClass}
                placeholder={placeholder}
                type={type === INPUT_TYPE.PASSWORD ? passwordFiled.type : type}
            />
            {type === INPUT_TYPE.PASSWORD &&
                <div onClick={changeType} className="inp-block_password-icon">
                    {<Icon name={passwordFiled.type === INPUT_TYPE.PASSWORD ? "passwordHide" : "passwordOpen"}/>}
                </div>}
        </div>
    )
}
export default InputV2