import React, {memo} from "react";
import networkData from "components/atoms/NetworkButton/networkData.json";
import {NetworkButton} from "components/atoms";

export const NetworkButtons = () => {
    return (
        <div className="navigation-mobile_content_social">
            {networkData.map((elm, i) =>
                <NetworkButton
                    key={i}
                    icon={elm.network}
                    link={elm.link}
                    size="sm"
                />
            )}
        </div>
    )
}

export default memo(NetworkButtons)