import React from "react"
import "./style.scss"

const TabBlockDisplay = ({children}) =>{

    return(
        <div className={`tab-block-display`}>
            {children}
        </div>
    )
}

export default TabBlockDisplay