import React from "react";

import {useGlobalModalsContext} from "layouts/context/GlobalModalsProvider";

import {ButtonV2, Icon, Modal} from "components/atoms";

import "./style.scss"

const ErrorModal = ({mainMessage, infoMessage, icon, onClose}) => {
    const {message, data} = useGlobalModalsContext()

    return (
        <Modal
            isOpen={true}
            onClose={onClose}
        >
            <div className="error-modal">
                <div className="error-modal_image-block">
                    <Icon name={icon ?? "bot"}/>
                </div>
                <h3>{mainMessage ?? "Ooops!"}</h3>
                <p>{infoMessage ?? message}</p>
                {data && data?.onClick && <ButtonV2 onClick={data.onClick}>{data.text}</ButtonV2>}
            </div>
        </Modal>)
}

export default ErrorModal