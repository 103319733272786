import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import classNames from "classnames";

import {ERROR, errorHandler, GLOBAL_MODALS, LISTING_DAY_OPTIONS, STEPS_STATUS, WALLET_ERROR_CODE} from "utils";
import {useConnectedWallet, useConnectWallet, useUtils, useWindowSize} from "hooks";
import ProcessModal from "components/organisms/GlobalModals/processModal"
import {useUpdateListedOrderMutation} from "store/Bazaar/bazaar.api";
import {useGlobalModalsContext, useModalsContext} from "layouts";
import {setData} from "store/Locker/lockerSlice";

import {useLockerCurrentCosmetic} from "../../Locker/LockerCurrentCosmetic/useLockerCurrentCosmetic";
import {Input, Rarity, Image, SelectDropDownDisplay, Modal, ButtonV2} from "components/atoms";

const BazaarCurrentAlreadyListedWeb3CosmeticEditModal = () => {
    const {setModal, setMessage, resetStatesAndClose} = useGlobalModalsContext()
    const {isConnectedAddressCorrect} = useConnectedWallet()
    const {getChainDataById, addChain, switchChain} = useUtils()
    const {currentModal, setCurrentModal} = useModalsContext()
    const {disconnectWallet} = useConnectWallet()
    const {order_id} = useParams()
    const {width} = useWindowSize()

    const dispatch = useDispatch()

    const {token_id, name, image_url, rarity, groups, chain_id} = currentModal.data
    const [updateListedOrder, {isSuccess, isError, data}] = useUpdateListedOrderMutation()

    const [expires, setExpires] = useState({name: "7 Days", value: 7})
    const [isStartCreatingOrder, setIsStartCreatingOrder] = useState(false)
    const [isProcess, setIsProcess] = useState(false)
    const [fieldsError, setFieldsError] = useState("")
    const [listPrice, setListPrice] = useState("")

    const {createOrder} = useLockerCurrentCosmetic(expires, listPrice, token_id)

    const contentStyle = classNames("bazaar-current-cosmetic-section_listed_modal_content")
    const cosmeticCartStyle = classNames("bazaar-current-cosmetic-section_listed_modal_content_img", `${rarity}-small`)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => startCreatingOrder(), [isStartCreatingOrder])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => getUpdatedListedOrderAfterUpdate(), [isSuccess, isError])

    const getUpdatedListedOrderAfterUpdate = () => {
        if (isSuccess) {
            setCurrentModal({...currentModal, status: STEPS_STATUS.ORDER_EDIT_SUCCESS})
            dispatch(setData({path1: "currentListedCosmetic", path2: "data", data}))
        }

        if (isError) {
            setModal(GLOBAL_MODALS.ERROR)
            setMessage(ERROR.LISTING_FAIL_UPDATE)
            resetStatesAndClose()
        }
    }

    const updateNow = async () => {
        if (!listPrice || !expires) {
            setFieldsError(ERROR.ALL_FIELDS_REQUIRED)
        } else {
            setFieldsError(ERROR.CLEAN)
            setIsProcess(true)

            if (!isConnectedAddressCorrect) {
                setModal(GLOBAL_MODALS.ERROR)
                setMessage(ERROR.WALLET_CONNECTED_MATCH_INVALID)
                resetStatesAndClose()
                disconnectWallet()

                return
            }

            try {
                const {chainIdHex, chainId} = getChainDataById(chain_id)
                const {error, code} = await switchChain(chainIdHex)

                if (error && code !== WALLET_ERROR_CODE.UNRECOGNIZED_CHAIN_ID) errorHandler(ERROR.FAIL_REQUEST)

                if (error && code === WALLET_ERROR_CODE.UNRECOGNIZED_CHAIN_ID) {
                    const {error} = await addChain(chainId)

                    if (error) errorHandler(ERROR.CLEAN)
                }

                setIsStartCreatingOrder(true)
            } catch (error) {
                setModal(GLOBAL_MODALS.ERROR)
                setMessage(ERROR.WALLET_FAILED)
                setIsProcess(false)
                resetStatesAndClose()
            }
        }
    }

    const startCreatingOrder = async () => {
        if (isStartCreatingOrder) {
            try {
                const {sellOrder, sellSignature} = await createOrder(chain_id)

                updateListedOrder({
                    order_id,
                    order: sellOrder,
                    signature: sellSignature
                }).unwrap()

            } catch (error) {
                setModal(GLOBAL_MODALS.ERROR)
                setMessage(error?.message ?? ERROR.WRONG)
                setIsProcess(false)
                setIsStartCreatingOrder(false)
                resetStatesAndClose()
            }
        }
    }

    const removeListing = () => {
        setCurrentModal({...currentModal, status: STEPS_STATUS.ORDER_REMOVE})
    }

    return (
        <React.Fragment>
            {isProcess ?
                <ProcessModal/>
                : <Modal
                    isOpen={true}
                    title="Listing update"
                    onClose={() => setCurrentModal({status: STEPS_STATUS.CLOSE})}
                    className="bazaar-current-cosmetic-section_listed_modal"
                >
                    <div className={contentStyle}>
                        <div className={cosmeticCartStyle}>
                            <Image alt="cosmetic" src={image_url}/>
                        </div>
                        <div className="bazaar-current-cosmetic-section_listed_modal_content_inputs">
                            <div>
                                <h5 className="bazaar-current-cosmetic-section_listed_modal_content_inputs_title">{name}</h5>
                                <div className="bazaar-current-cosmetic-section_listed_modal_content_inputs_rarity">
                                    <React.Fragment>
                                        {React.Children.toArray(
                                            groups.map((item) =>
                                                <Rarity text={item.name} rarity="default"/>
                                            )
                                        )}
                                    </React.Fragment>
                                    <Rarity
                                        text={rarity}
                                        rarity={`${rarity} ${width > 991 ? "small" : "medium"}`}
                                    />
                                </div>
                                <div className="bazaar-current-cosmetic-section_listed_modal_content_inputs_field">
                                    <div
                                        className="bazaar-current-cosmetic-section_listed_modal_content_inputs_price-inp">
                                        <Input
                                            size="md"
                                            name="price"
                                            type="number"
                                            value={listPrice}
                                            placeholder="List Price (KT)"
                                            status={fieldsError ? "error" : "default"}
                                            onChange={(e) => setListPrice(e.target.value)}
                                        />
                                    </div>
                                    <div
                                        className="bazaar-current-cosmetic-section_listed_modal_content_inputs_day-select">
                                        <SelectDropDownDisplay
                                            size="md"
                                            value={expires}
                                            btnText="Expires"
                                            optionsList={LISTING_DAY_OPTIONS}
                                            status={fieldsError ? "error" : "default"}
                                            onChange={(e) => setExpires(e.target.value.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bazaar-current-cosmetic-section_listed_modal_content_btn">
                        <ButtonV2 onClick={() => updateNow()}>Update Now</ButtonV2>
                        <ButtonV2 onClick={() => removeListing()}>Remove listing</ButtonV2>
                    </div>
                </Modal>
            }
        </React.Fragment>

    )
}

export default BazaarCurrentAlreadyListedWeb3CosmeticEditModal