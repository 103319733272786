import {useSelector} from "react-redux";
import React, {memo} from 'react';

import ProfileInfoHeader from "./ProfileInfoHeader";
import ProfileInfoBlock from "./ProfileInfoBlock";

import "./style.scss"

const ProfileEmailBlock = () => {
    const {userData} = useSelector(state => state.authV2.signIn)

    return (
        <div className="profile_container_info_email">
            <ProfileInfoHeader title="Email"/>
            <ProfileInfoBlock text={userData.email}/>
        </div>
    )
}

export default memo(ProfileEmailBlock)