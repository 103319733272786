import React from "react";

import {TRANSACTION_HISTORY_SECTION} from "utils";

import {TransactionHistorySection} from "components/molecules";

const BazaarActivity = () => {
    return (
        <section className="bazaar_cosmetics_content_general_activity container">
            <TransactionHistorySection transactionHistorySection={TRANSACTION_HISTORY_SECTION.BAZAAR_ACTIVITY}/>
        </section>
    )
}

export default BazaarActivity