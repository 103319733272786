import React from "react";

import {useModalsContext} from "layouts";
import {STEPS_STATUS} from "utils";

import DivinePurchaseSuccessfulModal from "./DivinePurchaseSuccessfulModal";
import DivineWeb2BrowserAlertModal from "./DivineWeb2BrowserAlertModal";
import DivineOrderModal from "./DivineOrderModal";

const DivineSteps = () => {
    const {currentModal, setCurrentModal} = useModalsContext()

    return (
        <React.Fragment>
            {
                {
                    [STEPS_STATUS.ORDER]:
                        <DivineOrderModal
                            show={true}
                            setWhichModalOpen={() => setCurrentModal({status: STEPS_STATUS.CLOSE})}
                        />,
                    [STEPS_STATUS.SUCCESS]:
                        <DivinePurchaseSuccessfulModal
                            show={true}
                            setWhichModalOpen={() => setCurrentModal({status: STEPS_STATUS.CLOSE})}
                        />,
                    [STEPS_STATUS.WEB2_BROWSER]:
                        <DivineWeb2BrowserAlertModal
                            show={true}
                            changeStep={() => setCurrentModal({status: STEPS_STATUS.CLOSE})}
                        />
                }[currentModal.status]
            }
        </React.Fragment>
    )
}
export default DivineSteps