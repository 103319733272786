import React from "react"

import {ButtonV2, Modal} from "components/atoms";

import "../style.scss"

const DivineWeb2BrowserAlertModal = ({show, changeStep}) => (
    <Modal
        show={show}
        onClose={changeStep}
        title={<span>Can't connect on <br/> web2 browsers</span>}
    >
        <div className="shop-divine_web2-browser">
            <p>Please use Metamask or Trust Wallet app browsers.</p>
            <ButtonV2 onClick={changeStep}>Back to divine shop</ButtonV2>
        </div>
    </Modal>
)

export default DivineWeb2BrowserAlertModal

