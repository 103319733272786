import React, {memo} from 'react';
import Icon from "../Icon";
import {CARD_CHAIN_ICON} from "utils";

import "./style.scss"

const Chain = ({chain}) => (
    <div className="chain-block"><Icon name={CARD_CHAIN_ICON[chain]}/></div>
)

export default memo(Chain)