import React from "react";

import {SelectDropDownDisplay, Icon} from "components/atoms";

import "./style.scss"

const PaginationArrowWithTools = (
    {
        offset,
        limitData,
        isLoading,
        dataCount,
        limitValue,
        callBackArrow,
        callBackLimit,
        isDisableNext
    }
) => {
    const disabledNext = dataCount < limitValue.value
    const disabledPrev = offset - limitValue.value < 0

    return (
        <div className="pagination-query-manage-block">
            <div className="pagination-query-manage-block_limit-select">
                <SelectDropDownDisplay
                    size="sm"
                    btnText="12"
                    name="limit"
                    value={limitValue}
                    optionsList={limitData}
                    optionListPosition="top"
                    onChange={(e) => callBackLimit(e, "limit")}
                />
            </div>
            <div className="pagination-query-manage-block_arrow">
                <div className="pagination-query-manage-block_arrow_controller">
                    <button
                        draggable={false}
                        disabled={disabledPrev || isLoading}
                        onClick={() => callBackArrow("prev")}
                        className="pagination-query-manage-block_arrow_controller_prev"
                    >
                        <Icon name="prevArrow"/>
                    </button>
                    <button
                        draggable={false}
                        onClick={() => callBackArrow("next")}
                        disabled={disabledNext || isLoading || isDisableNext}
                        className="pagination-query-manage-block_arrow_controller_next"
                    >
                        <Icon name="nextArrow"/>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PaginationArrowWithTools