import React, {useState, memo} from "react"
import classNames from "classnames";
import Icon from "../Icon";
import "./style.scss"

const Accordion = ({icon, title, children, open = false, bodyBorder = true}) => {
    const [isOpen, setIsOpen] = useState(open)

    const bodyStyle = classNames("custom-accordion-body", {"according-open": isOpen}, {"body-border": bodyBorder})

    return (
        <div className="custom-accordion">
            <div className="custom-accordion-item">
                <h2 className="custom-accordion-header">
                    <button className="custom-accordion-button" onClick={() => setIsOpen(!isOpen)}>
                        <div>
                            <Icon name={icon}/>
                            <span>{title}</span>
                        </div>
                        <div className={isOpen ? "openIcon" : ""}>
                            <Icon name="selectArrow"/>
                        </div>
                    </button>
                </h2>
                <div className={bodyStyle}>
                    <div className="custom-according-content">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(Accordion)