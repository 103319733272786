import React, {memo} from 'react';
import {Button, Icon, SkeletonText} from "components/atoms";

import "./style.scss"

const BazaarCurrentCosmeticInfo = ({description, price, isLoading, isSuccess, buttonDetails}) => (
    <React.Fragment>
        <div className="bazaar-current-cosmetic-section_price">
            <span className="bazaar-current-cosmetic-section_price_title">Price</span>
            <div className="bazaar-current-cosmetic-section_price_content">
                <Icon name="kompete"/>
                <span className={isLoading ? "skeleton" : ""}>{isSuccess ? price : ""}</span>
            </div>
        </div>
        {buttonDetails &&
            <Button
                disabled={buttonDetails.isDisabled}
                onClick={() => buttonDetails.onClick()}
            >
                {buttonDetails.text}
            </Button>}
        <div className="bazaar-current-cosmetic-section_description">
            <span className="bazaar-current-cosmetic-section_description_title">Description</span>
            {isSuccess && <p>{description}</p>}
            {isLoading && <SkeletonText textLineCount={2}/>}
        </div>
    </React.Fragment>
)

export default memo(BazaarCurrentCosmeticInfo)