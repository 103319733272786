import React from "react";
import Icon from "../Icon";

const NeverSoldAgain = () => (
    <div className="never-sold-again-card">
        <Icon name="fire"/>
        <span>Never sold again</span>
    </div>
)

export default NeverSoldAgain;