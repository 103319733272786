import { useCallback } from 'react';

export const useDebounce = () => {
    let timeout

    const debounce = useCallback((callback, delay) => {
        clearTimeout(timeout);
        // eslint-disable-next-line
        timeout = setTimeout(() => {
            callback()
        }, delay);
    }, [])

    return debounce;
};