import {png} from "assets/png";

import {
    SORT_OPTIONS,
    CHAINS_FILTRATION_DATA,
    GROUPS_FILTRATION_DATA,
    STANDARD_LIMIT_OPTIONS,
    RARITIES_FILTRATION_DATA
} from "./global";

export const TRANSFER_INPUTS_DEFAULT_DATA = {
    quantity: {
        value: "",
        validation: false
    },
    address: {
        value: "",
        validation: false,
    }
}

export const LOCKER_TABS = {
    OWNED: "owned",
    LISTED: "listed",
    ACTIVITY: "activity",
    KONVERT: "konvert"
}

export const tabOptions = [
    {id: LOCKER_TABS.OWNED, name: "Owned Items", icon: png.OwnedItemsTab},
    {id: LOCKER_TABS.LISTED, name: "Listed Items ", icon: png.LimitedTimeTab},
    {id: LOCKER_TABS.ACTIVITY, name: "Activity", icon: png.ActivityTab},
    {id: LOCKER_TABS.KONVERT, name: "Konvert", icon: png.KonvertTab}
]

export const eligibility = [
    {
        name: "ticket",
        text: "Own The Current Seasons’s Ticket",
        eligibility: false
    },
    {
        name: "cosmetic",
        text: "Own the Founding Father’s Outfit",
        eligibility: true
    },
    {
        name: "token",
        text: "Own $500 worth of KOMPETE Token",
        eligibility: false
    },
]

export const INVENTORY_FILTRATION_DATA_LOCKER = {
    page: {value: 1},
    orderBy: {
        data: SORT_OPTIONS,
        value: null
    },
    limit: {
        data: STANDARD_LIMIT_OPTIONS,
        value: STANDARD_LIMIT_OPTIONS[0],
    },
    filter: [
        CHAINS_FILTRATION_DATA,
        RARITIES_FILTRATION_DATA,
        GROUPS_FILTRATION_DATA
    ]
}

export const OWNED_INVENTORIES_REQUEST_DEFAULT_DATA = {limit: 36, offset: 0, platform: "xsolla"}

export const ORDERS_REQUEST_DEFAULT_DATA_LOCKER = {
    limit: 12,
    offset: 0,
    value: "",
    orderBy: "",
    groups: [],
    rarities: [],
    chains: []
}

export const LISTING_DAY_OPTIONS = [
    {name: "1 Day", value: 1},
    {name: "3 Days", value: 3},
    {name: "7 Days", value: 7},
    {name: "14 Days", value: 14},
    {name: "30 Days", value: 30}
]