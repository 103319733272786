import React from 'react';

import {Icon} from "components/atoms";

import "./style.scss"

const DiscordBanner = () => (
    <div className="banner_discord">
        <div className="banner_discord_desktop">
            <p>Get a free legendary reward for joining discord!</p>
            <Icon name="weapon"/>
            <a href="https://discord.gg/kompete-921684972786573333" target="_blank" rel="noreferrer">
                Click here to join
            </a>
        </div>
        <div className="banner_discord_mobile">
            <p>Joining Discord for a free reward!</p>
            <a href="https://discord.gg/kompete-921684972786573333" target="_blank" rel="noreferrer">
                Tap here to join
            </a>
        </div>
    </div>
)

export default DiscordBanner