import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {ethers} from "ethers";

import {BUTTON_VARIANT, ERROR, errorHandler, GLOBAL_MODALS, STEPS_STATUS} from "utils";
import {useCancelListedOrderMutation} from "store/Bazaar/bazaar.api";
import {useGlobalModalsContext, useModalsContext} from "layouts";
import {useConnectedWallet, useConnectWallet} from "hooks";

import CosmeticModalTemplate from "components/molecules/CosmeticModalTemplate";
import ProcessModal from "components/organisms/GlobalModals/processModal"
import {ButtonV2} from "components/atoms";

const BazaarCurrentAlreadyListedWeb3CosmeticRemoveModal = () => {
    const {isConnectedAddressCorrect, address} = useConnectedWallet()
    const {setModal, setMessage, resetStatesAndClose} = useGlobalModalsContext()
    const {disconnectWallet} = useConnectWallet()
    const {providers} = useConnectedWallet()
    const {walletConnectSigner: signer} = providers()
    const {setCurrentModal} = useModalsContext()
    const {currentModal} = useModalsContext()
    const {order_id} = useParams()

    const [cancelListedOrder, {isError, isSuccess}] = useCancelListedOrderMutation()

    const {name, image_url, rarity} = currentModal.data

    const [isProcess, setIsProcess] = useState(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => monitoringOrderCancelProcess(), [isSuccess, isError])

    const monitoringOrderCancelProcess = () => {
        if (isSuccess) {
            setCurrentModal({...currentModal, status: STEPS_STATUS.ORDER_CANCEL_SUCCESS})
        }

        if (isError) {
            setModal(GLOBAL_MODALS.ERROR)
            setMessage(ERROR.LISTING_FAIL_UPDATE)
            resetStatesAndClose()
        }
    }

    const removeListing = async () => {
        if (!isConnectedAddressCorrect) {
            setCurrentModal({status: STEPS_STATUS.REJECT, message: ERROR.WALLET_CONNECTED_MATCH_INVALID})
            disconnectWallet()

            return
        }

        setIsProcess(true)

        const message = "Please sign to confirm the removal of your KOMPETE cosmetic listing"
        await signer.signMessage(message)
            .then((signature) => {
                const signerAddress = ethers.utils.verifyMessage(message, signature);

                if (signerAddress === address) {
                    cancelListedOrder({order_id})
                } else {
                    errorHandler(ERROR.CLEAN)
                }
            })
            .catch(() => {
                setModal(GLOBAL_MODALS.ERROR)
                setMessage(ERROR.LISTING_FAIL_UPDATE)
                resetStatesAndClose()
                setCurrentModal({status: STEPS_STATUS.CLOSE, message: ""})
            })
    }

    return (
        <React.Fragment>
            {isProcess ?
                <ProcessModal/> :
                <CosmeticModalTemplate
                    show={true}
                    name={name}
                    quantity="1"
                    rarity={rarity}
                    image={image_url}
                    title="Remove listing"
                    contentClassName="bazaar-current-cosmetic-section_remove-modal"
                    closeClick={() => setCurrentModal({status: STEPS_STATUS.CLOSE})}
                >
                    <ButtonV2 variant={BUTTON_VARIANT.RED} onClick={removeListing}>Remove listing</ButtonV2>
                </CosmeticModalTemplate>
            }
        </React.Fragment>
    )
}

export default BazaarCurrentAlreadyListedWeb3CosmeticRemoveModal