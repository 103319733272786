import React from "react";
import Title from "../TitleTemplate";

import networkData from "../NetworkButton/networkData.json";
import NetworkButton from "../NetworkButton";

import "./style.scss"

const FollowUsFooter = () => {
    return (
        <div className="footer_follow-us">
            <div className="footer_follow-us_block">
                <Title underline={true}><span>Follow </span>us</Title>
                <div className="footer_follow-us_block_socials">
                    {React.Children.toArray(
                        networkData.map((elm) =>
                            <NetworkButton
                                size="lg"
                                link={elm.link}
                                icon={elm.network}
                                description={elm.description}
                            />
                        ))}
                </div>
            </div>
        </div>
    )
}

export default FollowUsFooter