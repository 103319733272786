import {useLocation} from "react-router-dom";
import React from "react";

import {DownloadMain, DownloadDiscord} from "components/organisms";
import DefaultLayout from "layouts/DefaultLayout";
import Seo from "components/atoms/Seo";

const Download = ({banner}) => {
    const {pathname} = useLocation()

    return (
        <DefaultLayout
            banner={banner}
            isFollowUsBack={true}
            onlyLogo={pathname !== "/download"}
            isFollowUsShow={pathname === "/download"}
        >
            <Seo
                title="Download - KOMPETE"
                description="Download KOMPETE on Windows. iOS, Android, Xbox, and Playstation are coming soon!"
            />
            <DownloadMain isBanner={banner}/>
            <React.Fragment>{banner && <DownloadDiscord/>}</React.Fragment>
        </DefaultLayout>
    )
}
export default Download

