import React from "react"
import classNames from "classnames";
import {useWindowResize} from "hooks"

import "./style.scss"

const SkeletonNftCart = ({cartLineCount, type}) => {
    const ref = React.useRef(null)
    const {width} = useWindowResize(ref)

    const nftCartStyle = classNames("skeleton-nft-cart_img-block", type ?? "default")

    const generateLine = () => {
        return new Array(cartLineCount ?? 5).fill("").map((item, i) =>
            <div key={i} className="skeleton-nft-cart skeleton">
                <div className={nftCartStyle} ref={ref} style={{height: `${width + 15}px`}}/>
                <div className="skeleton-nft-cart_content">
                    <div className="skeleton-nft-cart_content_limited"/>
                </div>
            </div>
        )
    }

    return (
        <React.Fragment>
            {generateLine()}
        </React.Fragment>
    )
}
export default SkeletonNftCart