import {useEffect, useState} from "react";

export const useCountdownTimer = (targetDateTime, isDay) => {
    const [countdown, setCountdown] = useState('');

    useEffect(() => {
        const countdownInterval = setInterval(() => {
            const targetDate = new Date(targetDateTime).getTime();
            const timeDifference = targetDate - new Date();

            if (timeDifference <= 0) {
                clearInterval(countdownInterval);
                setCountdown({days: '00', hours: '00', minutes: '00', seconds: '00'});
                return;
            }

            const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

            const countdownStringWithOutDay = {
                hours: ((days * 24) + Number(hours)).toString().padStart(2, '0'),
                minutes: minutes.toString().padStart(2, '0'),
                seconds: seconds.toString().padStart(2, '0')
            }

            const countdownStringWithDay = {
                days: days.toString().padStart(2, '0'),
                hours: hours.toString().padStart(2, '0'),
                minutes: minutes.toString().padStart(2, '0'),
                seconds: seconds.toString().padStart(2, '0')
            }

            setCountdown(isDay ? countdownStringWithDay : countdownStringWithOutDay);
        }, 1000);

        return () => {
            clearInterval(countdownInterval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [targetDateTime]);

    return {countdown};
};