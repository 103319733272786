import {useLocation, useNavigate} from "react-router-dom";
import React from "react";

import {useDetectMobile} from "hooks";

const NavItem = ({path, name, route, notLink, setMobileMenu}) => {
    const location = useLocation()
    const navigate = useNavigate()
    const {isMobile} = useDetectMobile()

    const navigatePage = () => {
        if (isMobile) setMobileMenu({open: false, section: ""})
        navigate(path)
    }

    return (
        <li
            onClick={() =>
                notLink
                    ? window.open(path, "_blank")
                    : navigatePage(path)
            }
            className={location.pathname === route ? "active" : ""}
        >
            <span>{name}</span>
        </li>
    )
}

export default NavItem