export const ENVIRONMENTS = {
    //XSOLLA
    XSOLLA_PROJECT_ID: process.env.REACT_APP_XSOLLA_KOMPETE_PROJECT_ID,
    XSOLLA_STORE_TARGET_SERVER: process.env.REACT_APP_XSOLLA_STORE_TARGET_SERVER,

    //ALCHEMY
    ALCHEMY_GRAPHQL_SERVER: process.env.REACT_APP_ALCHEMY_GRAPHQL_SERVER,

    //HOST
    WS_MARKET_URL: process.env.REACT_APP_WS_MARKET_URL,
    MARKET_URL: process.env.REACT_APP_MARKET_SERVER_HOST
}