import React, {useEffect, memo} from "react"
import {useLocation} from "react-router-dom";

import Wrapper from "../PageWrapper";
import Header from "./Header";
import Footer from "./Footer";

const DefaultLayout = (
    {
        banner,
        children,
        menu = true,
        isFollowUsBack,
        isFooter = true,
        onlyLogo = false,
        isFollowUsShow = true
    }) => {

    const location = useLocation()

    useEffect(() => {
        window.scroll(0, 0)
        window.history.scrollRestoration = 'manual'

        if (sessionStorage.getItem("_paq") !== location.pathname) {
            sessionStorage.setItem("_paq", "")
            setTimeout(() => {
                const {_paq} = window
                _paq.push(['setCustomUrl', window.location.href]);
                _paq.push(['setDocumentTitle', document.title]);
                _paq.push(['trackPageView']);
            }, 0)
        }
    }, [location.pathname])

    return (
        <Wrapper>
            {menu && <Header banner={banner} onlyLogo={onlyLogo}/>}
            <main>{children}</main>
            {isFooter && <Footer isFollowUsShow={isFollowUsShow} isFollowUsBack={isFollowUsBack}/>}
        </Wrapper>
    )
}

export default memo(DefaultLayout)