import {useSelector} from "react-redux";
import classNames from "classnames";
import React, {memo} from "react";

import {formatNumberToEuropeanStyle} from "utils";
import {useConnectedWallet} from "hooks";

import {Image} from "components/atoms";

import {png} from "assets/png";

const InfoBlock = () => {
    const {userData,loading} = useSelector(state => state.authV2.signIn)
    const {isConnected} = useConnectedWallet()

    const noConnectedWallet = classNames("navigation_auth_user-details_panel_info_balance_voolah")
    const nicknameStyle = classNames("navigation_auth_user-details_panel_info_user-nickname",{"skeleton": loading})
    const noRegisteredWallet = classNames("navigation_auth_user-details_panel_info_balance_kompete", {"no-wallet": !isConnected})
    const formattedVoolahBalance = userData?.voolahBalance ? formatNumberToEuropeanStyle(userData?.voolahBalance) : 0
    const formattedKompeteBalance = isConnected && userData?.kompeteBalance ? formatNumberToEuropeanStyle(Number(userData?.kompeteBalance).toFixed(2)) : 0

    return (
        <div className="navigation_auth_user-details_panel_info">
            <span className={nicknameStyle}>{userData.nickname}</span>
            <div className="navigation_auth_user-details_panel_info_balance">
                <div className={noConnectedWallet}>
                    <Image alt="voolah" src={png.VoolahCoin}/>
                    <span>{formattedVoolahBalance}</span>
                </div>
                <div className={noRegisteredWallet}>
                    <Image alt="kompete-token" src={png.T1Png}/>
                    <span>{formattedKompeteBalance}</span>
                </div>
            </div>
        </div>
    )
}

export default memo(InfoBlock)