import React, {useEffect, useRef, useState} from "react";
import {FacebookShareButton, TelegramShareButton, TwitterShareButton} from "react-share";
import {useOutside} from "hooks"

import {copyToClipboard} from "utils";

import Toast from "components/atoms/Toast";

import {svg} from "assets/svg";
import {png} from "assets/png";
import "./style.scss"

const Share = () => {
    const [isOpenShareBlock, setIsOpenShareBlock] = useState(false)
    const [errorOptions, setErrorOptions] = useState({text: "", variant: ""})
    const [isTop, setIsTop] = useState(false)
    const wrapperRef = useRef(null)
    useOutside(wrapperRef, setIsOpenShareBlock)

    useEffect(() => {
        let isMount = true

        if (isMount) checkPosition()

        return () => {
            isMount = false
        }
    }, [])

    const netWoksBlockToggle = () => {
        checkPosition()
        setIsOpenShareBlock(!isOpenShareBlock)
    }

    const checkPosition = () => {
        if (window.screen.height - wrapperRef.current?.getBoundingClientRect().y > 215) {
            setIsTop(false)
        } else {
            setIsTop(true)
        }
    }

    const copy = () => {
        copyToClipboard(window.location.href)
            .then((result) => {
                if (result) {
                    setErrorOptions({text: "Copy", variant: "success"})
                } else {
                    setErrorOptions({text: "Something went wrong", variant: "error"})
                }
            })
    }

    return (
        <React.Fragment>
            <div ref={wrapperRef} className="share">
                <span className="share_text" onClick={() => netWoksBlockToggle()}>Share</span>
                {
                    isOpenShareBlock &&
                    <div
                        onClick={() => netWoksBlockToggle()}
                        className={`share_networks ${isTop ? "top" : "bottom"}`}
                    >
                        <div
                            onClick={() => copy()}>
                            <img alt="copy" src={png.CopyClipboard}/>
                            Copy to Clipboard
                        </div>
                        <div>
                            <FacebookShareButton url={window.location.href}>
                                <img alt="facebook" src={svg.Facebook}/>
                                Share on FaceBook
                            </FacebookShareButton>
                        </div>
                        <div>
                            <TwitterShareButton url={window.location.href}>
                                <img alt="twiter" src={svg.Twitter}/>
                                Share on Twitter
                            </TwitterShareButton>
                        </div>
                        <div>
                            <TelegramShareButton url={window.location.href}>
                                <img alt="telegram" src={svg.Telegram}/>
                                Share on Telegram
                            </TelegramShareButton>
                        </div>
                    </div>
                }
            </div>
            <Toast text={errorOptions.text} clearState={setErrorOptions} variant={errorOptions.variant}/>
        </React.Fragment>
    )
}
export default Share