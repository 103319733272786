import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";

import {BUTTON_VARIANT, ERROR, GLOBAL_MODALS, REG_EXP, SUCCESS} from "utils";
import {useGlobalModalsContext} from "layouts/context/GlobalModalsProvider";
import {reset, userRecoverPassword} from "store/AuthV2/AuthV2Slice";

import {ButtonV2, InputV2, Modal} from "components/atoms";

import "./style.scss"

const ForgotPasswordModal = () => {
    const dispatch = useDispatch();

    const {setModal, setData, setMessage, resetStates} = useGlobalModalsContext()

    const {
        success: recoverPasswordSuccess,
        loading: recoverPasswordLoading
    } = useSelector(state => state.authV2.recoverPassword)

    const [email, setEmail] = useState("")
    const [error, setError] = useState("")

    useEffect(() => followRecoverPasswordProcess(), [recoverPasswordSuccess]);

    const checkMailAndNavigate = () => {
        const isMailMatched = email.match(REG_EXP.EMAILS)

        if (!isMailMatched) window.open("https://" + email.split("@")[1])

        resetStates()
        setModal(GLOBAL_MODALS.CLOSE)
    }

    const followRecoverPasswordProcess = () => {
        if (recoverPasswordSuccess) {
            setModal(GLOBAL_MODALS.SUCCESS)
            setMessage(SUCCESS.RECOVER_PASSWORD)
            setData({onClick: () => checkMailAndNavigate(), text: "Ok, got it!"})

            dispatch(reset("recoverPassword"))
        }
    }

    const recoverPassword = () => {
        const isValidEmail = email.match(REG_EXP.EMAIL)

        setError(ERROR.CLEAN)

        if (!isValidEmail) {
            setError(ERROR.INVALID_EMAIL)
            return
        }

        dispatch(userRecoverPassword({username: email}))
    }

    return (
        <Modal
            isOpen={true}
            title="Recover your kompete account password"
            onClose={() => setModal(GLOBAL_MODALS.CLOSE)}
        >
            <div className="recover-password-modal">
                <InputV2
                    name="email"
                    value={email}
                    errorMessage={error}
                    label="Email address"
                    disabled={recoverPasswordLoading}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <ButtonV2
                    disabled={!email}
                    onClick={recoverPassword}
                    variant={recoverPasswordLoading ? BUTTON_VARIANT.LOADING : BUTTON_VARIANT.DEFAULT}
                >
                    Recover Password
                </ButtonV2>
            </div>
        </Modal>
    )
}

export default ForgotPasswordModal