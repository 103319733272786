import React from "react";

import {BANNER} from "utils";

import {DiscordBanner} from "components/atoms";

const HeaderTopBanner = ({banner}) => (
    <React.Fragment>
        {
            {
                [BANNER.DISCORD]: <DiscordBanner/>
            }[banner]
        }
    </React.Fragment>
)

export default HeaderTopBanner