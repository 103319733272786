export const checkingTypeTime = (pastTimes) => {
    let currentTime

    for (let time in pastTimes) {
        let isSet = false
        if (pastTimes[time].value && !isSet) {
            currentTime = pastTimes[time]
            isSet = true
        }
    }

    return currentTime
}