import React, {memo} from 'react';
import {SkeletonText} from "components/atoms";

const Description = ({isLoading, isRewardClaimed, rewardSuccess}) => {
    return (
        <div className="free-item-section_content_description">
            {!isRewardClaimed && <span className="free-item-section_content_description_title">Description</span>}
            {isLoading
                ? <SkeletonText textLineCount={3}/>
                : rewardSuccess
                    ? isRewardClaimed ? "" :
                        <p>Congrats! 🎉 You’ve earned a free game item in KOMPETE! To claim your reward, just sign in or
                            create an account. Remember, it's one item per account, so don’t miss out!</p>
                    : <p>The next free item will be released soon! Check back later.</p>}
        </div>
    )
}

export default memo(Description)