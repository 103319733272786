import {ZERO_BYTES32} from "@modernize-games/nft-sdk";
import {parseUnits} from "ethers/lib/utils";
import {ethers} from "ethers";

import {createOrderWithSignature, web3Utils} from "web3/utils";
import {walletAndTransactionErrorHandle, ERROR, errorHandler} from "utils";

const useDivineShop = ({feePercent, price, signer, quantity, tokenId, account, chainId}) => {

    const createSellOrder = async () => {
        const {marketplace} = await web3Utils.getConfiguration(feePercent, chainId)

        const {order: sellOrder, signature: sellSignature} = await createOrderWithSignature({
            chainId,
            token: "eth",
            price,
            tokenId,
            amount: quantity,
            orderSide: "Sell",
            admin: true,
            mint: true,
            feePercent
        })

        if (!(await marketplace.validateOrderParameters_(sellOrder)))
            errorHandler(ERROR.INVALID_SELL_ORDER_PARAMS)
        if (!(await marketplace.validateOrder_(sellOrder, sellSignature)))
            errorHandler(ERROR.INVALID_SELL_ORDER)

        return {sellOrder, sellSignature}
    }

    const createBuyOrder = async () => {
        const {marketplace} = await web3Utils.getConfiguration(feePercent, chainId)

        try {
            const {order: buyOrder, signature: buySignature} = await createOrderWithSignature({
                chainId,
                signer,
                token: "eth",
                price,
                tokenId,
                amount: quantity,
                account,
                orderSide: "Buy",
                mint: true,
                feePercent
            })

            if (!(await marketplace.validateOrderParameters_(buyOrder)))
                errorHandler(ERROR.INVALID_BUY_ORDER_PARAMS)
            if (!(await marketplace.validateOrder_(buyOrder, buySignature)))
                errorHandler(ERROR.INVALID_SELL_ORDER)

            return {buyOrder, buySignature}
        } catch (error) {
            walletAndTransactionErrorHandle(error)
        }
    }

    const sendTransaction = async (sellOrder, sellSignature, buyOrder, buySignature) => {
        const {marketplace} = await web3Utils.getConfiguration(feePercent, chainId)
        const parsePrice = parseUnits(price.toString(), 18)

        try {
            if (!(await marketplace.ordersCanMatch_(buyOrder, sellOrder))) {
                if (await marketplace.ordersCanMatch_(buyOrder, sellOrder)) {
                    errorHandler(ERROR.INVALID_MATCH)
                }
            }

            let gasLimit

            try {
                gasLimit = (
                    await marketplace.estimateGas.atomicMatch_(
                        buyOrder,
                        buySignature,
                        sellOrder,
                        sellSignature,
                        ZERO_BYTES32,
                    )
                )
                    .mul(110)
                    .div(100)

            } catch (error) {
                gasLimit = ethers.BigNumber.from("245000")
            }

            try {
                const tx = await marketplace
                    .connect(signer)
                    .atomicMatch_(
                        buyOrder,
                        buySignature,
                        sellOrder,
                        sellSignature,
                        ZERO_BYTES32,
                        {
                            gasLimit,
                            value: parsePrice,
                        },
                    )

                return tx.wait().then((result) => result)
            } catch (error) {
                walletAndTransactionErrorHandle(error)
            }

        } catch (error) {
            walletAndTransactionErrorHandle(error)
        }
    }

    return {
        createSellOrder,
        createBuyOrder,
        sendTransaction
    }
}

export default useDivineShop




