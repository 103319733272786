import React from "react";
import Row from "./components/Row";
import Title from "components/atoms/TitleTemplate";
import "./style.scss"
import {png} from "assets/png";

const DeckAcquireBetter = () =>{
    return(
        <section className="deck_acquire-better">
            <Title underline={true}>We acquire <span>better</span></Title>
            <div className="deck_acquire-better_content container">
                <Row src={png.HeroIndividual11} imgRight={true} title="Marketing is Easier for Us.">
                    <ul>
                        <li>We are a former marketing company.</li>
                        <li>Easier to run ads to acquire players for a game with multiple game modes that has access to big audiences.</li>
                        <li>Game availability on multiple devices (PC, mobile, console).</li>
                        <li>Our game is free where as most competitors are paid.</li>
                        <li>Facebook, Instagram, & TikTok Ads to bring in new players (Facebook is up and working).</li>
                    </ul>
                </Row>
            </div>
        </section>
    )
}
export default DeckAcquireBetter