import {parseUnits} from "ethers/lib/utils";

import {ERROR, errorHandler, walletAndTransactionErrorHandle} from "utils";
import {useConnectedWallet} from "hooks";
import {useModalsContext} from "layouts";
import {useSelector} from "react-redux";
import {web3Utils} from "web3/utils";

export const useLockerCurrentCosmetic = (expires, price, tokenId) => {
    const {address, providers} = useConnectedWallet()
    const {currentModal, setCurrentModal} = useModalsContext()
    const {walletConnectSigner: signer} = providers()
    const {contractAddress} = useSelector(state => state.web3.currentChain)

    const createOrder = async (chainId) => {
        const feePercent = 2
        const {marketplace, paymentToken, collection} = await web3Utils.getConfiguration(feePercent, chainId)

        const expiresWithMilliseconds = expires?.value * 8.64e+7
        const currentTime = new Date().getTime()
        const expiration = Math.floor((currentTime + expiresWithMilliseconds) / 1000)

        const decimals = await paymentToken.decimals()

        try {
            const {order: sellOrder, signature: sellSignature} = await web3Utils.createOrderWithSignature({
                chainId,
                signer,
                token: "kompete",
                account: address,
                price,
                amount: 1,
                expiration,
                tokenId,
                orderSide: "Sell",
                admin: false,
                feePercent
            })

            if (!(await marketplace.validateOrderParameters_(sellOrder)))
                errorHandler(ERROR.INVALID_SELL_ORDER_PARAMS)
            if (!(await marketplace.validateOrder_(sellOrder, sellSignature)))
                errorHandler(ERROR.INVALID_SELL_ORDER)

            const approveAmount = parseUnits('1000000000', decimals)
            const hexDefaultValue = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

            const allowance = await paymentToken.connect(signer).allowance(address, contractAddress.marketplace)

            // @TODO will be removed in the future the "0x00"
            if (allowance._hex === hexDefaultValue || allowance._hex === "0x00") {
                const approve = await paymentToken.connect(signer).approve(contractAddress.marketplace, approveAmount)
                await approve.wait()
            }

            const isApprovalAll = await collection.connect(signer).isApprovedForAll(address, contractAddress.marketplace)

            if (!isApprovalAll) {
                const approvalForAll = await collection.connect(signer).setApprovalForAll(contractAddress.marketplace, true)
                await approvalForAll.wait()
            }

            return {sellOrder, sellSignature}
        } catch (error) {
            walletAndTransactionErrorHandle(error)
        }
    }

    const safeTransfer = async (addressTo, tokenId, amount) => {
        setCurrentModal({...currentModal, status: "process"})

        try {
            const {collection} = await web3Utils.getConfiguration()
            const tx = await collection.connect(signer).safeTransferFrom(address, addressTo, tokenId, amount, "0x")

            return tx.wait().then((result) => result)
        } catch {
            throw new Error(ERROR.TRANSFER_FAILED)
        }
    }

    return {
        createOrder,
        safeTransfer
    }
}