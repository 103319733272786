import React from "react";

const TabNavItem = ({id, children, activeTab, setActiveTab, isSetUrlParams, setParams}) => {
    const handleClick = () => {
        setActiveTab(id);

        if (isSetUrlParams) setParams(id)
    };

    return (
        <div onClick={() => handleClick()} className={activeTab === id ? "active" : ""}>
            {children}
        </div>
    );
}
export default TabNavItem