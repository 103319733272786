import React from "react";
import Title from "components/atoms/TitleTemplate";

const DeckSimilarGames = () => {
    return (
        <section className="deck_similar-games container">
            <div className="deck_similar-games_content">
                <Title underline={true}><span>Similar Games our audience plays </span>
                    Getting Millions of Downloads</Title>
                <div className="deck_similar-games_content_item-one">
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                </div>
            </div>
            <div className="deck_similar-games_content">
                <Title underline={true}><span>Similar Games you’ve never heard of</span> with Millions of Downloads</Title>
                <div className="deck_similar-games_content_item-two">
                    <div/>
                    <div/>
                    <div/>
                </div>
            </div>
        </section>
    )
}
export default DeckSimilarGames