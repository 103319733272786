import React, {useEffect, memo} from "react";

import {useConnectedWallet, useConnectWallet, useLocalStorage} from "hooks";
import {ERROR, GLOBAL_MODALS, STEPS_STATUS, SUCCESS} from "utils";
import {useGlobalModalsContext, useModalsContext} from "layouts";

import LockerCurrentCosmeticTransferSuccessModal from "./LockerCurrentCosmeticTransferSuccessModal";
import LockerCurrentCosmeticListingSuccessModal from "./LockerCurrentCosmeticListingSuccessModal";
import LockerCurrentCosmeticTransferModal from "./LockerCurrentCosmeticTransferModal";

const LockerCurrentCosmeticSteps = () => {
    const {currentModal, setCurrentModal} = useModalsContext()

    const {isConnected, isWalletConnection, isConnectedAddressCorrect} = useConnectedWallet()
    const {setModal, setMessage, resetStatesAndClose} = useGlobalModalsContext()
    const {setWalletConnectionSuccess} = useLocalStorage()
    const {disconnectWallet} = useConnectWallet()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => checkWalletConnectionAndAddressMatch(), [isConnected]);

    const checkWalletConnectionAndAddressMatch = async () => {
        if (isConnected && !isWalletConnection) {
            setModal(GLOBAL_MODALS.PROCESS)

            if (!isConnectedAddressCorrect) {
                setModal(GLOBAL_MODALS.ERROR)
                setMessage(ERROR.WALLET_CONNECTED_MATCH_INVALID)

                resetStatesAndClose()
                disconnectWallet()
            } else {
                setModal(GLOBAL_MODALS.SUCCESS)
                setMessage(SUCCESS.WALLET_CONNECTED)
                resetStatesAndClose()
                setWalletConnectionSuccess()
            }
        }
    }

    return (
        <React.Fragment>
            {
                {
                    [STEPS_STATUS.TRANSFER]:
                        <LockerCurrentCosmeticTransferModal/>,
                    [STEPS_STATUS.TRANSFER_SUCCESS]:
                        <LockerCurrentCosmeticTransferSuccessModal/>,
                    [STEPS_STATUS.LISTING_SUCCESS]:
                        <LockerCurrentCosmeticListingSuccessModal
                            show={true}
                            setCurrentModal={setCurrentModal}
                        />,
                }[currentModal.status]
            }
        </React.Fragment>
    )
}

export default memo(LockerCurrentCosmeticSteps)