import React, {memo} from "react"

import {useModalsContext} from "layouts";
import {ERROR, STEPS_STATUS} from "utils";

import LockerCurrentCosmeticTransferSuccessModal from "./LockerCurrentCosmetic/LockerCurrentCosmeticTransferSuccessModal";
import LockerCurrentCosmeticTransferModal from "./LockerCurrentCosmetic/LockerCurrentCosmeticTransferModal";
import LockerConvertSuccessModal from "./Konvert/LockerConvertSuccessModal";
import ErrorModal from "components/organisms/GlobalModals/errorModal";

const LockerSteps = ({changeTab}) => {
    const {currentModal, setCurrentModal} = useModalsContext()

    return (
        <React.Fragment>
            {
                {
                    [STEPS_STATUS.TRANSFER]:
                        <LockerCurrentCosmeticTransferModal/>,
                    [STEPS_STATUS.TRANSFER_SUCCESS]:
                        <LockerCurrentCosmeticTransferSuccessModal/>,
                    [STEPS_STATUS.KONVERT_SUCCESS]:
                        <LockerConvertSuccessModal changeTab={changeTab}/>,
                    [STEPS_STATUS.KONVERT_COOLDOWN]:
                        <ErrorModal
                            icon="cooldown"
                            mainMessage="Cooldown!"
                            infoMessage={ERROR.KONVERT_COOLDOWN}
                            onClose={() => setCurrentModal(STEPS_STATUS.CLOSE)}
                        />
                }[currentModal.status]
            }
        </React.Fragment>
    )
}

export default memo(LockerSteps)