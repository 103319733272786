import React from "react";

import {useLocalStorage} from "hooks";
import {AUTH_NETWORK} from "utils";

import {Icon} from "components/atoms";

const SocialAuth = () => {
    const {setSignInNetwork} = useLocalStorage()

    const signInSocial = (network) => {
        setSignInNetwork(network)

        const query = new URLSearchParams({
            projectId: process.env.REACT_APP_XSOLLA_PROJECT_ID,
            login_url: window.location.origin
        })

        window.location.replace(`https://login.xsolla.com/api/social/${network}/login_redirect?${query}`)
    }

    return (
        <div className="sign-in-modal_social-auth">
            <SocialButton onClick={signInSocial} network={AUTH_NETWORK.TWITTER}/>
            <SocialButton onClick={signInSocial} network={AUTH_NETWORK.GOOGLE}/>
            <SocialButton onClick={signInSocial} network={AUTH_NETWORK.FACEBOOK}/>
            <SocialButton onClick={signInSocial} network={AUTH_NETWORK.APPLE}/>
        </div>
    )
}

export default SocialAuth

const SocialButton = ({network, onClick}) => (
    <button className={network} onClick={() => onClick(network)}>
        <Icon name={network}/>
    </button>
)