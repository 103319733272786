import React from "react";
import {useNavigate} from "react-router-dom"

import ProgressiveImage from "components/atoms/ProgressiveImage";
import Title from "components/atoms/TitleTemplate";
import Button from "components/atoms/Button";

import {png} from "assets/png";
import "./style.scss"

const EconomyYouPay = () => {
    const navigate = useNavigate()

    return (
        <section className="economy_you-pay">
            <Title underline={true}>You pay your way at the <span>shop & ticket</span></Title>
            <div className="economy_you-pay_options container">
                <OptionsItem
                    title="voolah"
                    orgImage={png.VoolahEcoPng}
                    noOrgImage={png.VoolahEcoWebp}
                    onClick={() => navigate("/buy-kompete-token")}
                >
                    Voolah is KOMPETE's in-game currency for players who prefer using fiat to purchase cosmetics in the
                    shop. It is available on all marketplaces and the KOMPETE website.
                </OptionsItem>
                <OptionsItem
                    title="ethereum"
                    orgImage={png.EthereumEcoPng}
                    noOrgImage={png.EthereumEcoWebp}
                    onClick={() => navigate("/shop")}
                >
                    The KOMPETE shops are compatible with Ethereum (Mainnet or Base) for players who prefer full
                    ownership of their cosmetic items. Exclusively available on the KOMPETE website.
                </OptionsItem>
            </div>
        </section>
    )
}
export default EconomyYouPay

const OptionsItem = ({orgImage, noOrgImage, title, onClick, children}) => {
    return (
        <div className="economy_you-pay_options_item">
            <div className="economy_you-pay_options_item_img-block">
                <ProgressiveImage alt="currency" imgSrc={orgImage} previewSrc={noOrgImage}/>
            </div>
            <div className="economy_you-pay_options_item_content">
                <h2>{title}</h2>
                <p>{children}</p>
            </div>
            <Button view="secondary" onClick={onClick}>
                get {title}
            </Button>
        </div>
    )
}