import React from "react";
import Title from "components/atoms/TitleTemplate";
import Row from "./components/Row";
import {png} from "assets/png";
import "./style.scss"

const DeckMonetization = () => {
    return (
        <section className="deck_monetization">
            <Title underline={true}>Means of <span>Monetization</span></Title>
            <div className="deck_monetization_content container">
                <div className="deck_monetization_item-one">
                    <div className="deck_monetization_item-one_red-back"/>
                    <div className="deck_monetization_item-one_coin"/>
                    <Row src={png.Coins} title="VOOLAH">
                        <p className="deck_monetization_item-one_text">
                            Players buy Voolah and then use Voolah to purchase cosmetic items & XP reward systems. </p>
                        <div className="deck_monetization_item-one_estimate">
                            <span>Estimated Price</span>
                            <p>$3 to $97 per package</p>
                        </div>
                    </Row>
                </div>
                <div className="deck_monetization_item-two">
                    <div className="deck_monetization_item-two_coin"/>
                    <Row src={png.HeroIndividual4Png} imgRight={true} title="cosmetic item shop">
                        <p className="deck_monetization_item-two_text">
                            Players will buy cosmetic items using Voolah, including apparel, accessories, vehicles, equipment, &
                            more!</p>
                        <div className="deck_monetization_item-two_estimate">
                            <span>Estimated Price</span>
                            <p>$4 to $397 per cosmetic item depending on rarity.</p>
                        </div>
                    </Row>
                </div>
                <div className="deck_monetization_item-four">
                    <div className="deck_monetization_item-four_red-back"/>
                    <div className="deck_monetization_item-four_red-back-2"/>
                    <div className="deck_monetization_item-four_coin"/>
                    <Row src={png.HeroIndividual2Png} title="The Ticket (Battle Pass)">
                        <p className="deck_monetization_item-four_text">
                            Paid XP reward system that gives cosmetic rewards. It has 15 levels & releases every month.</p>
                        <div className="deck_monetization_item-four_estimate">
                            <span>Estimated Price</span>
                            <p>$7-11 per Ticket</p>
                        </div>
                    </Row>
                </div>
                <div className="deck_monetization_item-three">
                    <div className="deck_monetization_item-three_coin"/>
                    <Row src={png.HeroIndividual1Png} imgRight={true} title="Fame">
                        <p className="deck_monetization_item-three_text">
                            Free XP reward system that gives cosmetic rewards. It has 15 levels. Rewards are limited in quantity, and
                            most
                            are insanely hard to obtain. Releases every 3 months.
                        </p>
                        <div className="deck_monetization_item-three_estimate">
                            <span>Estimated Price</span>
                            <p>Free</p>
                        </div>
                    </Row>
                </div>
                <div className="deck_monetization_item-five">
                    <div className="deck_monetization_item-five_red-back"/>
                    <div className="deck_monetization_item-five_coin"/>
                    <div className="deck_monetization_item-five_coin-2"/>
                    <Row src={png.Marketplace} title="The Bazaar">
                        <p className="deck_monetization_item-five_text">
                            A marketplace where players can buy and sell cosmetic items with one another.</p>
                        <div className="deck_monetization_item-five_estimate">
                            <span>Estimated Price</span>
                            <p>Take a 5-10% fee on a successful marketplace transaction.</p>
                        </div>
                    </Row>
                </div>
                <div className="deck_monetization_item-six">
                    <div className="deck_monetization_item-six_coin"/>
                    <div className="deck_monetization_item-six_coin-2"/>
                    <Row imgRight={true} src={png.T4} title="KOMPETE TOKEN">
                        <p className="deck_monetization_item-six_text">
                            A virtual currency that allows players to transact on The Bazaar, access closed testing with the devs, and
                            obtain access to exclusive cosmetics!</p>
                        <div className="deck_monetization_item-six_estimate">
                            <span>Estimated Price</span>
                            <p>Take a 2-10% fee on a successful buy or sell of the token.</p>
                        </div>
                    </Row>
                </div>
            </div>
        </section>
    )
}

export default DeckMonetization