import React from "react"
import Title from "components/atoms/TitleTemplate";
import "./style.scss"

const data = [
    {
        name: "Multiple fleshed out game modes",
        backgroundImage: "one"
    },
    {
        name: "free-to-play",
        backgroundImage: "two"
    },
    {
        name: "cross-platform play",
        backgroundImage: "three"
    },
    {
        name: "Cross-progression",
        backgroundImage: "four"
    },
    {
        name: "photorealistic graphics",
        backgroundImage: "five"
    },
    {
        name: "modular character customizer",
        backgroundImage: "six"
    },
    {
        name: "attributes & traits system",
        backgroundImage: "seven"
    },
    {
        name: "xp progression systems",
        backgroundImage: "eight"
    },
    {
        name: "consistent content updates",
        backgroundImage: "nine"
    },
    {
        name: "new game modes to be added",
        backgroundImage: "ten"
    },
    {
        name: "social party system",
        backgroundImage: "eleven"
    },
    {
        name: "matchmaking enabled",
        backgroundImage: "twelve"
    },
]

const HomeWhy = () => {

    return (
        <section className="home_why-kompete">
            <Title underline={true}>why <span>kompete</span>?</Title>
            <div className="home_why-kompete_content container">
                <div className="home_why-kompete_content_why-list">
                    {React.Children.toArray(
                        data.map((elm) => <WhyItem background={elm.backgroundImage}>{elm.name}</WhyItem>)
                    )}
                </div>
            </div>
        </section>
    )
}

const WhyItem = ({children, background}) => {
    return (
        <div className={`home_why-kompete_content_why-list_item ${background}`}>
            <div className="home_why-kompete_content_why-list_item_block">
                <h5>{children}</h5>
            </div>
        </div>
    )
}

export default HomeWhy