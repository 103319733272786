import React, {useEffect, useState} from 'react';
import "./style.scss"

export default function ProgressiveImage({imgSrc, previewSrc, className, filter, alt = "game"}) {

    const [usedSrc, setUsedSrc] = useState(previewSrc);
    const [usedEffectStyle, setUsedEffectStyle] = useState({filter: 'blur(5px)', clipPath: 'inset(0)'});

    useEffect(() => {
        const img = new Image();
        img.src = imgSrc;
        img.onload = () => {
            setUsedSrc(img.src);
            setUsedEffectStyle({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            {filter && <div className="image-filter"></div>}
            <img
                alt={alt}
                loading="lazy"
                className={className}
                src={usedSrc}
                style={{transition: 'filter 0.1s ease-out', ...usedEffectStyle}}
            />
        </React.Fragment>
    )
}