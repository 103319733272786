import React, {memo} from 'react';
import classNames from "classnames";

import "./style.scss"

const ProfileInfoHeader = ({title, processInfo, status}) => {
    const processInfoStyle = classNames("profile_info-header_process-info", status ?? "default")

    return (
        <div className="profile_info-header">
            <h6>{title}</h6>
            {processInfo && <span className={processInfoStyle}>{processInfo}</span>}
        </div>
    )
}

export default memo(ProfileInfoHeader)