import React from "react";
import classNames from "classnames";

import {Icon} from "components/atoms";

import "./style.scss"

const DownloadDeviceCart = ({device, icon, available = true, text, link, isTarget = false, size}) => {
    const cartStyle = classNames("download_device-cart", icon, size ?? "sm")

    return (
        <div className={cartStyle}>
            <a rel="noreferrer" href={link} target={isTarget ? "_blank" : ""}>
                {!available && <span className="download_device-cart_no-available"><Icon name="unTradeable"/></span>}
                <span className="download_device-cart_device">{device}</span>
                <div className="download_device-cart_icon-block">
                    <Icon name={icon}/>
                </div>
                <Icon name="cartLine"/>
                <div className="download_device-cart_text-block">
                    <p>{text}</p>
                </div>

            </a>
        </div>
    )
}

export default DownloadDeviceCart