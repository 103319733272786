import React from "react";
import {useNavigate} from "react-router-dom"

import Title from "components/atoms/TitleTemplate";
import Button from "components/atoms/Button";

const DeckNow = () => {
    const navigate = useNavigate()

    return (
        <section className="deck_now">
            <div className="deck_now_content">
                <Title underline={true}>Play the early access build <span>now!</span></Title>
                <p>Our Early Access build is online! You can create an account and download KOMPETE right now. Jump into practice mode
                    or attend one of our playtesting sessions!</p>
                <Button view="secondary" onClick={() => navigate("/download")}>Create Account & Download</Button>
            </div>
        </section>
    )
}

export default DeckNow