import React from 'react';
import {SkeletonText} from "components/atoms";

const LockerCurrentCosmeticDescription = ({isLoading, description}) => (
    <div className="locker-current-cosmetic_section_description">
        <span className="locker-current-cosmetic_section_description_title">Description</span>
        {isLoading
            ? <SkeletonText textLineCount={2}/>
            : <p>{description}</p>
        }
    </div>
)

export default LockerCurrentCosmeticDescription