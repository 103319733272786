import {createSlice} from "@reduxjs/toolkit"

import config from "web3/config"

const defaultChain = config.chains[config.networkEnvironment]?.[1] ?? config.chains.mainNet[1]

const initialState = {
    currentEnvironment: config.networkEnvironment,
    currentChain: defaultChain,
}

const web3Slice = createSlice({
    name: "web3",
    initialState,
    reducers: {
        switchCurrentChain: (state, action) => {
            const {chainId} = action.payload
            state.currentChain = config.chains[config.networkEnvironment].find(chain => chain.chainId === chainId);
        }
    }
})

export const {switchCurrentChain} = web3Slice.actions
export default web3Slice.reducer