import React, {createContext, useContext, useState} from "react"
import {GLOBAL_MODALS} from "utils";

const ModalContext = createContext()

export const GlobalModalsProvider = ({children}) => {
    const [data, setData] = useState(null)
    const [message, setMessage] = useState(null)
    const [modal, setModal] = useState(GLOBAL_MODALS.CLOSE)

    const resetStates = () => {
        setMessage(null)
        setData(null)
    }

    const resetStatesAndClose = () => {
        setTimeout(() => {
            resetStates()
            setModal(GLOBAL_MODALS.CLOSE)
        }, 3000)
    }

    return (
        <ModalContext.Provider
            value={{modal, setModal, data, setData, message, setMessage, resetStates, resetStatesAndClose}}>
            {children}
        </ModalContext.Provider>
    )
}

export const useGlobalModalsContext = () => useContext(ModalContext)