import {useSearchParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useState} from "react";

import {buyVoolah} from "store/VoolahShop/voolahShopSlice";
import {voolahPackageDetails} from "utils";
import {useModalsContext} from "layouts";
import {useWindowSize} from "hooks";

const useVoolahShop = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const {setCurrentModal} = useModalsContext()

    const [XPayEventProcess, setXPayEventProcess] = useState("")
    const [XPayWidgetClose, setXPayWidgetClose] = useState(false)

    const dispatch = useDispatch()
    const currentPackageIndex = searchParams.get("voolah") || 0

    const {width} = useWindowSize()

    const searchParamsChecking = () => {
        const voolah = searchParams.get("voolah")
        const userId = searchParams.get("user_id")
        const status = searchParams.get("status")

        if (!voolah) {
            searchParams.set("voolah", 0)
            setSearchParams(searchParams)
        }

        if (userId && status !== "done") {
            setSearchParams({tab: "voolah", voolah: voolah})
        }

        if (userId && status === "done") {
            setSearchParams({tab: "voolah", voolah: voolah})
            setCurrentModal({status:"success"})
        }
    }

    const setXPayStationWidgetSettings = () => {
        const {XPayStationWidget} = window
        XPayStationWidget.off()
        XPayStationWidget.on(XPayStationWidget.eventTypes.CLOSE, function () {
            setXPayWidgetClose(true)
        });
        XPayStationWidget.on([
            XPayStationWidget.eventTypes.STATUS_INVOICE,
            XPayStationWidget.eventTypes.STATUS_DELIVERING,
            XPayStationWidget.eventTypes.STATUS_DONE,
            XPayStationWidget.eventTypes.STATUS_TROUBLED
        ].join(' '), function () {
            setXPayEventProcess(arguments[1].paymentInfo)
        });
    }

    const openVoolahOrderModal = () => {
        const itemSku = voolahPackageDetails[currentPackageIndex].value + "voolah"
        const size = width > 991 ? "large" : "small"
        const version = width > 600 ? "desktop" : "mobile"
        setXPayWidgetClose(false)
        dispatch(buyVoolah({itemSku, ui: {size, version}, searchParams: `tab=voolah&voolah=${currentPackageIndex}`}))
    }

    return {
        searchParamsChecking,
        setXPayStationWidgetSettings,
        openVoolahOrderModal,
        XPayEventProcess,
        XPayWidgetClose,
        setXPayEventProcess,
        setXPayWidgetClose,
        searchParams,
        setSearchParams,
        currentPackageIndex
    }
}

export default useVoolahShop