import React from "react";
import DefaultLayout from "layouts/DefaultLayout";
import HomeLetsKompete from "components/organisms/Home/HomeLetsKompete";
import HomeCurrentGameMode from "components/organisms/Home/HomeCurrentGameMode";
import HomeComingSoon from "components/organisms/Home/HomeComingSoon";
import MainDeck from "components/organisms/Deck/DeckMain";
import DeckIsland from "components/organisms/Deck/DeckIsland";
import DeckDownload from "components/organisms/Deck/DeckDownload";
import DeckOwnerMessage from "components/organisms/Deck/DeckOwnerMessage";
import DeckAudience from "components/organisms/Deck/DeckAudience";
import DeckBarriers from "components/organisms/Deck/DeckBarriers";
import DeckZeroBarriers from "components/organisms/Deck/DeckZeroBarriers";
import DeckGoal from "components/organisms/Deck/DeckGoal";
import DeckBigPicture from "components/organisms/Deck/DeckBigPicture";
import DeckAcquireBetter from "components/organisms/Deck/DeckAcquireBetter";
import DeckRetainBetter from "components/organisms/Deck/DeckRetainBetter";
import DeckMonetizedBetter from "components/organisms/Deck/DeckMonetizedBetter";
import DeckMonetization from "components/organisms/Deck/DeckMonetization";
import DeckWin from "components/organisms/Deck/DeckWin";
import DeckSimilarGames from "components/organisms/Deck/DeckSimilarGames";
import DeckModernizeGame from "components/organisms/Deck/DeckModernizeGames";
import DeckNow from "components/organisms/Deck/DeckNow";
import DeckInfo from "components/organisms/Deck/DeckInfo";
import Seo from "components/atoms/Seo";

const Deck = () => {
    return (
        <DefaultLayout menu={false}>
            <Seo
                title="Pitch Deck - KOMPETE"
            >
                <meta name="robots" content="noindex"/>
            </Seo>
            <MainDeck/>
            <HomeLetsKompete/>
            <HomeCurrentGameMode/>
            <HomeComingSoon/>
            <DeckIsland/>
            <DeckDownload/>
            <DeckOwnerMessage/>
            <DeckBarriers/>
            <DeckZeroBarriers/>
            <DeckGoal/>
            <DeckAudience/>
            <DeckBigPicture/>
            <DeckAcquireBetter/>
            <DeckRetainBetter/>
            <DeckMonetizedBetter/>
            <DeckMonetization/>
            <DeckWin/>
            <DeckSimilarGames/>
            <DeckModernizeGame/>
            <DeckNow/>
            <DeckInfo/>
        </DefaultLayout>
)
}

export default Deck