import React from "react";
import Title from "components/atoms/TitleTemplate";
import Row from "./components/Row";
import {png} from "assets/png";

const DeckBigPicture = () => {
    return (
        <section className="deck_big-picture ">
            <div className="deck_big-picture_red-back"/>
            <Title underline={true}>The big <span>Picture</span></Title>
            <div className="deck_big-picture_content container">
                <Row src={png.HeroIndividual12}>
                    <ul>
                        <li>Make KOMPETE extremely accessible.</li>
                        <li>Target game modes with high internet traffic, barriers to entry, & don’t include full cross platform
                            play.
                        </li>
                        <li>Create each game mode at a substitutable quality to our competitor’s games.</li>
                        <li>Retain players because of accessibility, connectivity with friends, multiple high-quality game modes that
                            the audience is interested in, and fair monetization.
                        </li>
                        <li>Sell cosmetic items that have no in game advantages.</li>
                    </ul>
                </Row>
            </div>
        </section>
    )
}

export default DeckBigPicture