import React from "react"
import "./style.scss"
import classNames from "classnames";

const Switch = ({onChange, checked, disabled}) => {
    const switchStyle = classNames("switch", {disabled: disabled})

    return (
        <label className={switchStyle}>
            <input
                type="checkbox"
                checked={checked}
                disabled={disabled}
                onChange={onChange}
                className="switch_input"
            />
            <span className="switch_slider round"></span>
        </label>
    )
}

export default Switch