import React from "react";
import classNames from "classnames";

import {BUTTON_VARIANT} from "utils";

import {ButtonV2, Image, Modal, Rarity} from "components/atoms";

import "./style.scss"

const CosmeticModalTemplate = (
    {
        show,
        title,
        image,
        name,
        rarity,
        quantity,
        buttonTwo,
        buttonOne,
        children,
        closeClick,
        showInfo = true,
        contentClassName = ""
    }
) => {
    const contentStyle = classNames("cosmetic-modal-template_content_main", contentClassName)

    return (
        <Modal
            isOpen={show}
            title={title}
            onClose={closeClick}
        >
            <div className="cosmetic-modal-template_content">
                {showInfo && <div className="cosmetic-modal-template_content_info">
                    <div className={`cosmetic-modal-template_content_info_img ${rarity}-small`}>
                        <Image alt="cosmetic" src={image}/>
                    </div>
                    <div className="cosmetic-modal-template_content_info_name">
                        <div>
                            <h6>{name}</h6>
                            <div className="cosmetic-modal-template_content_info_name_rarity">
                                <Rarity
                                    text={rarity}
                                    rarity={rarity}
                                    size="lg"
                                />
                            </div>
                        </div>
                        <div>
                            {quantity && <p>X {quantity}</p>}
                        </div>
                    </div>
                </div>}
                {children && <div className={contentStyle}>{children}</div>}
                <div className="cosmetic-modal-template_content_btns">
                    {buttonOne &&
                        <ButtonV2
                            onClick={() => buttonOne.callBack()}
                            variant={buttonOne.isLoading ? BUTTON_VARIANT.LOADING : BUTTON_VARIANT.DEFAULT}
                        >
                            {buttonOne.text}
                        </ButtonV2>}
                    {buttonTwo &&
                        <ButtonV2
                            onClick={() => buttonTwo.callBack()}
                            variant={buttonOne.isLoading ? BUTTON_VARIANT.LOADING : BUTTON_VARIANT.DEFAULT}
                        >
                            {buttonTwo.text}
                        </ButtonV2>}
                </div>
            </div>
        </Modal>
    )
}

export default CosmeticModalTemplate