export const useLocalStorage = () => {
    const removeToken = () => localStorage.removeItem("accessToken")
    const setToken = (token) => localStorage.setItem("accessToken", token)
    const removeSyncDate = () => localStorage.removeItem("sync_date")
    const removeLoginType = () => localStorage.removeItem("login")
    const getLoginType = () => localStorage.getItem("login")
    const setSignInNetwork = (network) => localStorage.setItem("sign_in_method", network)
    const setSignUpNetwork = (network) => localStorage.setItem("sign_up_method", network)
    const removeSignInNetwork = () => localStorage.removeItem("sign_in_method")
    const removeSignUpNetwork = () => localStorage.removeItem("sign_up_method")
    const isSignInMethodAvailable = () => localStorage.getItem("sign_in_method")
    const isSignUpMethodAvailable = () => localStorage.getItem("sign_up_method")
    const setWalletConnectionSuccess = () => localStorage.setItem("wallet-connection", "CONNECT_SUCCESS")
    const removeWalletConnectionSuccess = () => localStorage.removeItem("wallet-connection", "CONNECT_SUCCESS")

    return {
        setToken,
        removeToken,
        getLoginType,
        removeSyncDate,
        removeLoginType,
        setSignInNetwork,
        setSignUpNetwork,
        removeSignInNetwork,
        removeSignUpNetwork,
        isSignInMethodAvailable,
        isSignUpMethodAvailable,
        setWalletConnectionSuccess,
        removeWalletConnectionSuccess
    }
}