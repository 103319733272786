import React from "react"

import Post from "components/organisms/News/Posts/Post";
import DefaultLayout from "layouts/DefaultLayout";
import PostNews from "components/organisms/News/Posts/PostsNews";
import Seo from "components/atoms/Seo";

const NewsPost = () => {
    return (
        <DefaultLayout>
            <Seo
                title="News - KOMPETE"
                description="Catch up on the latest updates for KOMPETE."
            />
            <Post/>
            <PostNews/>
        </DefaultLayout>
    )

}
export default NewsPost