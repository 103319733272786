import React from "react";

import Title from "components/atoms/TitleTemplate";
import Image from "components/atoms/Image";

import {png} from "assets/png";
import "./style.scss"

const TokenThree = () => {
    return (
        <section className="token-three">
            <div className="container">
                <Title underline={true}>The <span>tokenomics</span></Title>
                <div className="tokenomics">
                    <div className="item">
                        <div className="thumb">
                            <Image alt="kompete-token" src={png.T12}/>
                        </div>
                        <h3>Token Supply</h3>
                        <p>Only 850 million KOMPETE Token will ever be in circulation. There is a wallet limit set to 10
                            million
                            tokens.</p>
                    </div>
                    <div className="item">
                        <div className="thumb">
                            <Image alt="komoete-token" src={png.T13}/>
                        </div>
                        <h3>Token TAX</h3>
                        <p>There is a 3% tax on Mainnet swaps, and a 1% tax on Base swaps. There are no taxes on transfers.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TokenThree