import {userGetVoolahBalance} from "../AuthV2/AuthV2Slice";
import {LIMITED_SHOP, XSOLLA_STORE} from "../endpoint";
import {createFetch} from "config/createFetch";
import {SERVER} from "../../utils";

const payForInventoryWithVoolah = async (data, thunkAPI) => {
    const {quantity, sku, currency} = data
    const projectId = process.env.REACT_APP_XSOLLA_KOMPETE_PROJECT_ID
    const jwt = thunkAPI.getState().authV2.signIn.userData.accessToken

    const requestGroup = [];

    for (let i = 0; i < quantity; i++) {
        const request = fetch(`https://store.xsolla.com/api/${XSOLLA_STORE.INVENTORY_PAYMENT(projectId, sku, currency)}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        });
        requestGroup.push(request);
    }

    return Promise.allSettled(requestGroup)
        .then((result) => {
            const fulfilledResponses = result
                .filter((result) => result.status === "fulfilled")
                .map((result) => result.value)
            return Promise.all(fulfilledResponses.map((response) => response.json()))
        })
        .then((orders) => {
            const data = {
                sku,
                quantity: orders.length,
                order_id: orders.map(order => order.order_id)
            }

            thunkAPI.dispatch(userGetVoolahBalance())

            return {quantitySuccess: orders.length}
        })
        .catch(error => thunkAPI.rejectWithValue(error?.message || error))
}

const saveVoolahSpentHistory = async (data) => {
    await createFetch(SERVER.MARKET, LIMITED_SHOP.VOOLAH_HISTORY, "POST", {authorization: true}, {data})
}

export const limitedShopService = {
    saveVoolahSpentHistory,
    payForInventoryWithVoolah,
}