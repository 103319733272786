import React, {useEffect, useState} from "react";

import {useWindowSize} from "hooks"

import ProgressiveImage from "components/atoms/ProgressiveImage";
import UnderLine from "components/atoms/UnderLine";
import Title from "components/atoms/TitleTemplate";
import Button from "components/atoms/Button";
import Toast from "components/atoms/Toast";

import {png} from "assets/png";

import "./style.scss"

const HowToBuyTokenTwo = () => {
    const [errorOptions, setErrorOptions] = useState({text: "", variant: ""})

    useEffect(() => stopUniswapAutoScroll(), []);

    const stopUniswapAutoScroll = () => {
        document.body.style.position = 'fixed';
        document.body.style.width = '100%';

        setTimeout(() => {
            document.body.style.position = '';
            document.body.style.width = '';
        }, 2000);
    }

    const integrateToken = async () => {
        const tokenAddress = '0x8f019931375454Fe4EE353427eB94E2E0C9e0a8C';
        const tokenSymbol = 'KOMPETE';
        const tokenDecimals = 10;
        const tokenImage = 'https://kompete.game/images/Sites_KOMPETEToken.png';

        try {
            const wasAdded = await window.ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20',
                    options: {
                        address: tokenAddress,
                        symbol: tokenSymbol,
                        decimals: tokenDecimals,
                        image: tokenImage,
                    },
                },
            });

            if (wasAdded) {
                setErrorOptions({text: "Success", variant: "success"})
            }
        } catch (error) {
            setErrorOptions({text: "Reject", variant: "error"})
        }
    }

    return (
        <React.Fragment>
            <section className="how-to-buy-token_two">
                <Title underline={true}>How to <span>buy</span></Title>
                <div className="how-to-buy-token_two_step-one">
                    <div className="how-to-buy-token_two_step-one_container container">
                        <div className="how-to-buy-token_two_step-one_container_block-one">
                            <ProgressiveImage
                                alt="ethereum"
                                imgSrc={png.CoinbaseIconPng}
                                previewSrc={png.CoinbaseIconWebp}
                            />
                        </div>
                        <div className="how-to-buy-token_two_step-one_container_block-two">
                            <h2 className=" how-to-buy-token_two_step-one_container_block-two_title">
                                <span>Step 1</span><br/>
                                Create a Coinbase <br/> wallet
                            </h2>
                            <UnderLine/>
                            <p className="how-to-buy-token_two_step-one_container_block-two_text">
                                To get started, first create a Coinbase Wallet. This is where your KOMPETE Tokens will
                                be stored. Remember to keep your seed phrase securely offline!
                            </p>
                            <div className="how-to-buy-token_two_step-one_container_block-two_btn">
                                <Button
                                    view="secondary"
                                    onClick={() => window.open("https://www.coinbase.com/en/wallet", "_target")}
                                >
                                    coinbase wallet
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="how-to-buy-token_two_step-two container" id="test">
                    <div className="how-to-buy-token_two_step-two_block-one">
                        <h2 className=" how-to-buy-token_two_step-two_block-one_title">
                            <span>Step 2</span><br/>
                            Fund wallet with eth
                        </h2>
                        <UnderLine/>
                        <p className="how-to-buy-token_two_step-two_block-one_text">
                            To acquire KOMPETE Tokens, you'll first need to purchase Ethereum. Utilize our Onramper tool
                            for the purchase, and then send it to your newly created wallet address.
                        </p>
                    </div>
                    <div className="how-to-buy-token_two_step-two_block-two">
                        <Iframe
                            src='https://widget.onramper.com?color=266677&apiKey=pk_prod_VAaUZ80TevjccpS0t1huZ7HaHpXRyO2Vjt8xW1AO2zA0&defaultCrypto=ETH'/>
                    </div>
                </div>
                <div className="how-to-buy-token_two_step-three">
                    <div className="how-to-buy-token_two_step-three_container container">
                        <div className="how-to-buy-token_two_step-three_container_block-one">
                            <Iframe
                                src={"https://app.uniswap.org/#/swap?outputCurrency=0x8f019931375454Fe4EE353427eB94E2E0C9e0a8C&use=v2&slippage=12000&chain=base"}/>
                        </div>
                        <div className="how-to-buy-token_two_step-three_container_block-two">
                            <h2 className=" how-to-buy-token_two_step-three_container_block-two_title">
                                <span>Step 3</span><br/>
                                swap eth FOR kompete <br/> token
                            </h2>
                            <UnderLine/>
                            <p className="how-to-buy-token_two_step-three_container_block-two_text">
                                Select the amount of Ethereum you want to swap for KOMPETE Token. Connect your wallet
                                and follow the prompts to execute the swap!
                            </p>
                            <div className="how-to-buy-token_two_step-three_container_block-two_btn">
                                <Button
                                    onClick={() => window.open("https://app.uniswap.org/#/swap?outputCurrency=0x1E0b2992079B620AA13A7c2E7c88D2e1E18E46E9&use=v2&slippage=12000&chain=mainnet")}
                                >
                                    uniswap
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="how-to-buy-token_two_step-four container">
                    <div className="how-to-buy-token_two_step-four_block-one">
                        <h2 className=" how-to-buy-token_two_step-four_block-one_title">
                            <span>Step 4</span><br/>
                            Add tokens to wallet
                        </h2>
                        <UnderLine/>
                        <p className="how-to-buy-token_two_step-four_block-one_text">
                            Lastly, make sure to add the tokens to your Coinbase wallet so that you can see them. You
                            can achieve this by either clicking the button below to import the KOMPETE token or manually
                            importing this address: <span>0x8f019931375454Fe4EE353427eB94E2E0C9e0a8C</span>.
                        </p>
                        <div className="how-to-buy-token_two_step-four_block-one_btn">
                            <Button onClick={() => integrateToken()}>
                                Add token To Wallet
                            </Button>
                        </div>
                    </div>
                    <div className="how-to-buy-token_two_step-four_block-two">
                        <ProgressiveImage
                            alt="ethereum"
                            imgSrc={png.KompeteInBasePng}
                            previewSrc={png.KompeteInBaseWebp}
                        />
                    </div>
                </div>
            </section>
            <Toast text={errorOptions.text} clearState={setErrorOptions} variant={errorOptions.variant}/>
        </React.Fragment>
    )
}

export default HowToBuyTokenTwo

const Iframe = ({src}) => {
    const [iframeStyle, setIframeStyle] = useState({width: "", height: ""})
    const {width} = useWindowSize()

    useEffect(() => {
        if (width > 1200) setIframeStyle({width: "520", height: "635"})
        if (width > 991 && width < 1200) setIframeStyle({width: "430", height: "635"})
        if (width < 991) setIframeStyle({width: "334", height: "635"})
    }, [width])

    return (
        <div id="rubics-widget-root" align="center">
            <iframe
                src={src}
                title="Onramper widget"
                frameBorder="0"
                allow="accelerometer;autoplay; camera; gyroscope; payment"
                className="how-to-buy-token_two_step-three_block-one_iframe"
                width={iframeStyle.width}
                height={iframeStyle.height}
                style={{boxShadow: "1px 1px 1px 1px"}}
            >
                <a href="https://widget.onramper.com" target="_blank" rel="noreferrer">Buy crypto</a>
            </iframe>
        </div>
    )
}