import React from "react";
import {useDispatch} from "react-redux";

import {reset} from "store/VoolahShop/voolahShopSlice"
import {STEPS_STATUS, voolahPackageDetails} from "utils";

import {ButtonV2, Image, Modal} from "components/atoms";
import {PaymentMethod} from "components/molecules";

import {png} from "assets/png";

const VoolahOrderModal = ({show, setCurrentModal, currentPackageIndex}) => {
    const dispatch = useDispatch()

    const cancelOrder = () => {
        setCurrentModal({status: STEPS_STATUS.CLOSE})
        dispatch(reset({section: "paymentUIvoolah"}))
    }

    const openWidget = () => {
        setCurrentModal({status: STEPS_STATUS.CLOSE})
        setTimeout(() => {
            window.XPayStationWidget.open()
        }, 10)
        dispatch(reset({section: "paymentUIvoolah"}))
    }

    return (
        <Modal
            isOpen={show}
            title="Order Summary"
            onClose={() => cancelOrder()}
        >
            <div className="shop-voolah_order-summery_content">
                <div className="shop-voolah_order-summery_content_info">
                    <div className="shop-voolah_order-summery_content_info_img">
                        <Image alt="cosmetic" src={voolahPackageDetails[currentPackageIndex].src.small}/>
                    </div>
                    <div className="shop-voolah_order-summery_content_info_details">
                        <div className="shop-voolah_order-summery_content_info_details_title">
                            <Image alt="voolah" src={png.VoolahCoin}/>
                            <h5>{voolahPackageDetails[currentPackageIndex].value} Voolah</h5>
                        </div>
                    </div>
                </div>
                <div className="shop-voolah_order-summery_content_payments-methods">
                    <div className="shop-voolah_order-summery_content_payments-methods_head">
                        <div className="shop-voolah_order-summery_content_payments-methods_head_quantity">
                            <span>Available payment methods</span>
                            <span> (1)</span>
                        </div>
                    </div>
                    <PaymentMethod
                        method="xsolla"
                        checked={true}
                        recommended={true}
                        disable={true}
                    />
                </div>
                <div className="shop-voolah_order-summery_content_total">
                    <div>Total</div>
                    <div>${voolahPackageDetails[currentPackageIndex].price} USD</div>
                </div>
                <div className="shop-voolah_order-summery_content_buttons">
                    <ButtonV2 onClick={openWidget}>Pay Now</ButtonV2>
                </div>
                <p className="shop-voolah_order-summery_content_terms">To purchase Voolah, you may be required to
                    disclose personal information, including but not limited to your name, date of birth, address, and
                    identification number (“Personal Data”). You understand and acknowledge that Modernize Games may be
                    required to deliver such Personal Data and other information to U.S. regulatory authorities upon
                    request.</p>
            </div>
        </Modal>
    )
}

export default VoolahOrderModal