import React from "react";

import {createWeb3Modal, defaultConfig, useWeb3ModalTheme} from "@web3modal/ethers5/react"

import config from "../config"

const projectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID
const sepoliaChain = config.chains.testNet[0]
const ethChain = config.chains.mainNet[0]

if (projectId === undefined) {
    throw new Error("Wallet Connect Project ID is not defined");
}

const eth = {
    chainId: ethChain.chainId,
    name: ethChain.name,
    currency: ethChain.currency,
    explorerUrl: ethChain.explorerUrl,
    rpcUrl: ethChain.rpcUrl
}

const sepolia = {
    chainId: sepoliaChain.chainId,
    name: sepoliaChain.name,
    currency: sepoliaChain.currency,
    explorerUrl: sepoliaChain.explorerUrl,
    rpcUrl: sepoliaChain.rpcUrl
}

const metadata = {
    name: "KOMPETE",
    description: "KOMPETE is a team-based multiplayer game with Battle Royale, Kart Race, Golf, Basketball & More. Free to play with Crossplay.",
    url: `${window.location.origin}`,
    icons: [`${window.location.origin}/images/kompete-logo.png`],
    rpcUrl: ethChain.rpcUrl,
    defaultChainId: 1,
}

createWeb3Modal({
    ethersConfig: defaultConfig({metadata}),
    chains: [eth, sepolia],
    projectId,
    privacyPolicyUrl: `${window.location.origin}/privacy`,
    termsConditionsUrl: `${window.location.origin}/terms`,
    tokens: [
        ethChain.contractAddress.payment,
        sepoliaChain.contractAddress.payment,
    ]
})

export const Web3ModalProvider = ({children}) => {
    const {setThemeVariables} = useWeb3ModalTheme()

    setThemeVariables({
        "--w3m-color-mix": "#000000",
        "--w3m-color-mix-strength": 40,
        "--w3m-border-radius-master": "10px",
        "--w3m-z-index": 100000000
    })

    return <React.Fragment>{children}</React.Fragment>
}