import React, {useEffect, useState, memo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {ethers} from "ethers";

import {useAuthorization, useConnectedWallet, useConnectWallet, useGMTEvents, useLocalStorage} from "hooks";
import {addressReduction, ERROR, errorHandler, GLOBAL_MODALS, PROCESS, SUCCESS, wait} from "utils";
import {authV2Service} from "store/AuthV2/AuthV2Service";
import {globalService} from "store/Global/globalService";
import {setData} from "store/AuthV2/AuthV2Slice";
import {useGlobalModalsContext} from "layouts";

import ProfileInfoHeader from "./ProfileInfoHeader";
import ProfileInfoBlock from "./ProfileInfoBlock";
import {ButtonV2} from "components/atoms";

const ProfileWalletBlock = () => {
    const dispatch = useDispatch()

    const {isConnected, address, isWalletConnection, providers} = useConnectedWallet()
    const {removeSyncDate, setWalletConnectionSuccess, removeWalletConnectionSuccess} = useLocalStorage()
    const {setModal, setMessage, resetStatesAndClose} = useGlobalModalsContext()
    const {openWalletModal, disconnectWallet} = useConnectWallet()
    const {accessToken} = useAuthorization()
    const {walletConnectSigner} = providers()
    const {updateWallet} = useGMTEvents()

    const [processMessage, setProcessMessage] = useState("")

    const {userData} = useSelector(state => state.authV2.signIn)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => controlWalletConnectProcess(), [isConnected])

    const resetProcessInfo = () => {
        setTimeout(() => setProcessMessage(""), 5000)
    }

    const controlWalletConnectProcess = async () => {
        if (isConnected && !isWalletConnection) {
            setModal(GLOBAL_MODALS.PROCESS)
            setWalletConnectionSuccess()

            await walletConnectSigner.signMessage(PROCESS.WALLET_CONFIRM)
                .then(async (signature) => {
                    const signerAddress = ethers.utils.verifyMessage(PROCESS.WALLET_CONFIRM, signature);

                    if (userData.wallet) {
                        if (signerAddress === userData.wallet) {
                            await authV2Service.removeWallet()

                            dispatch(setData({
                                path1: "signIn",
                                path2: "userData",
                                data: {...userData, wallet: null, kompeteBalance: 0, ethBalance: 0}
                            }))

                            setModal(GLOBAL_MODALS.SUCCESS)
                            setMessage(SUCCESS.WALLET_REMOVED)
                            resetStatesAndClose()

                            disconnectWallet(true)
                            updateWallet(userData.id)
                            resetProcessInfo()
                            removeSyncDate()

                            globalService.checkSyncDateAndSendInventories()
                        } else {
                            disconnectWallet(true)
                            errorHandler(ERROR.INVALID_WALLET)
                        }
                    } else {
                        if (signerAddress === address) {
                            await authV2Service.addWallet(address, accessToken)

                            await wait(1000)
                            dispatch(setData({
                                path1: "signIn",
                                path2: "userData",
                                data: {...userData, wallet: address}
                            }))

                            setModal(GLOBAL_MODALS.SUCCESS)
                            setMessage(SUCCESS.WALLET_ADDED)
                            resetStatesAndClose()

                            updateWallet(userData.id)
                            resetProcessInfo()
                            removeSyncDate()

                            globalService.checkSyncDateAndSendInventories()
                        } else {
                            disconnectWallet()
                            errorHandler(ERROR.INVALID_WALLET)
                        }
                    }
                })
                .catch((error) => {
                    disconnectWallet()
                    removeWalletConnectionSuccess()
                    setModal(GLOBAL_MODALS.ERROR)
                    setMessage(error?.message ?? ERROR.FAIL_REQUEST)
                    resetStatesAndClose()
                })
        }
    }

    const connectWallet = async () => {
        disconnectWallet()
        await wait(1000)
        await openWalletModal()
    }

    const infoText = userData.wallet ? addressReduction(userData.wallet, 6) : "No wallet added..."
    const buttonText = userData.wallet ? "Remove wallet" : "Add wallet"

    return (
        <div className="profile_container_info_wallet">
            <ProfileInfoHeader title="Wallet" processInfo={processMessage}/>
            <ProfileInfoBlock text={infoText}/>
            <p className="profile_container_info_wallet_note">
                Some items in your locker will only be visible if they're linked to the wallet you've added.
            </p>
            <ButtonV2 onClick={connectWallet}>{buttonText}</ButtonV2>
        </div>
    )
}

export default memo(ProfileWalletBlock)