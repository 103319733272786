import {useWeb3ModalAccount, useWeb3ModalProvider} from "@web3modal/ethers5/react";
import {useDispatch, useSelector} from "react-redux";
import {formatUnits} from "ethers/lib/utils";
import {ethers} from "ethers";

import {setBalance} from "store/AuthV2/AuthV2Slice";
import {web3Utils} from "web3/utils";

export const useConnectedWallet = () => {
    const dispatch = useDispatch()

    const {address, isConnected, chainId: connectedChainId} = useWeb3ModalAccount()
    const {walletProvider} = useWeb3ModalProvider()

    const {userData} = useSelector(state => state.authV2.signIn)
    const {currentChain} = useSelector(state => state.web3)

    const isWalletConnection = localStorage.getItem("wallet-connection") === "CONNECT_SUCCESS"
    const isConnectedAddressCorrect = userData?.wallet?.toLowerCase() === address?.toLowerCase()
    const isConnectedChainCorrect = currentChain?.chainId === connectedChainId

    const providers = () => {
        let browserProvider = null
        let browserSigner = null
        let walletConnectProvider = null
        let walletConnectSigner = null

        if (window.ethereum) {
            browserProvider = new ethers.providers.Web3Provider(window.ethereum)
            browserSigner = browserProvider.getSigner()
        }

        if (walletProvider) {
            walletConnectProvider = new ethers.providers.Web3Provider(walletProvider)
            walletConnectSigner = walletConnectProvider.getSigner()
        }

        return {browserProvider, browserSigner, walletConnectProvider, walletConnectSigner}
    }

    const getEthBalance = async (address) => {
        let ethBalance = 0
        try {
            const {walletConnectProvider} = providers()
            const balance = await walletConnectProvider.getBalance(address)
            ethBalance = Number(formatUnits(balance.toString()))

        } catch {
            ethBalance = 0
        }

        dispatch(setBalance({currencyType: "ethBalance", balance: ethBalance}))
    }

    const getKompeteBalance = async (address, chainId) => {
        let kompeteBalance = 0
        try {
            const {paymentToken} = await web3Utils.getConfiguration(0, chainId ?? currentChain?.chainId)
            const decimals = await paymentToken.decimals()
            const getKompeteBalance = await paymentToken.balanceOf(address)

            kompeteBalance = Number(formatUnits(getKompeteBalance, decimals))
        } catch {
            kompeteBalance = 0
        }

        dispatch(setBalance({currencyType: "kompeteBalance", balance: kompeteBalance}))
    }

    return {
        isConnectedAddressCorrect,
        isConnectedChainCorrect,
        isWalletConnection,
        connectedChainId,
        isConnected,
        address,
        getKompeteBalance,
        getEthBalance,
        providers
    }
}