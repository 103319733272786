import React from "react"
import classNames from "classnames"
import "./style.scss"
const CheckBox = ({name, onChange, checked, extent, view, customTextStyle, children, type, disable = false}) => {
    const extendStyle = classNames("checkBox", extent ?? "large", view ?? "green",type)
    const formControlStyle = classNames("checkbox-form-control", {"text-none": !children}, extent ?? "large", customTextStyle)

    return (
        <label data-testid="checkbox-label-test" className={formControlStyle}>
            <input
                data-testid="checkbox-test"
                name={name}
                onChange={onChange}
                type="checkbox"
                checked={checked}
                className={extendStyle}
                disabled={disable}
            />
            {children}
        </label>
    )
}
export default CheckBox