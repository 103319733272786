import React from "react";
import Row from "./components/Row";
import Title from "components/atoms/TitleTemplate";
import {png} from "assets/png";
import "./style.scss"

const DeckRetainBetter = () => {
    return (
        <section className="deck_retain-better">
            <Title underline={true}>We engage & <span>retain better</span></Title>
            <div className="deck_retain-better_content container">
                <Row src={png.HeroIndividual10} title="There's more to do in the game.">
                    <ul>
                        <li>Multiple in-depth game modes at a high quality.</li>
                        <li>Bored? Frustrated? Switch the game mode to something unique that you’re already familiar with.</li>
                        <li>Audience plays 2-5 multiplayer games at a time, and we’re putting those game modes into KOMPETE.</li>
                        <li>Frequent content updates to get the audience coming back.</li>
                        <li>Constant evolving environment to get the audience coming back.</li>
                    </ul>
                </Row>
            </div>
        </section>
    )
}
export default DeckRetainBetter