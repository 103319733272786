import {useSelector} from "react-redux";
import React, {memo} from "react";

import {Image} from "components/atoms";

import {png} from "assets/png";
import "./style.scss"

const ProfileAvatarBlock = () => {
    const {userData} = useSelector(state => state.authV2.signIn)
    const {nickname, picture} = userData

    return (
        <div className="profile_container_info_avatar">
            <div className="profile_container_info_avatar_img">
                <Image alt="avatar" src={picture ? picture : png.NewAvatar}/>
            </div>
            <h4 className="profile_container_info_avatar_name">{nickname}</h4>
        </div>
    )
}

export default memo(ProfileAvatarBlock)