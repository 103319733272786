import {createApi} from "@reduxjs/toolkit/query/react";

import {createFetchBaseServerQuery} from "config/createFetchBaseServerQuery";
import {FREE_ITEM} from "../endpoint";
import {ENVIRONMENTS} from "utils";

export const freeItemApi = createApi({
    reducerPath: "free/item/api",
    tagTypes: ["Claim"],
    baseQuery: createFetchBaseServerQuery({baseUrl: ENVIRONMENTS.MARKET_URL, isAuthorization: false}),
    endpoints: (builder) => ({
        //QUERY
        getActiveReward: builder.query({
            query: (data) => ({
                url: FREE_ITEM.GET_REWARD(data.uuid),
                method: "GET",
            }),
            keepUnusedDataFor: 10,
            transformResponse: (response) => {
                const {data} = response

                if (data) {
                    const itemFormatter = (inventory) => {
                        const {groups, rarity} = inventory
                        const {name: sub_category, parent} = groups[0]

                        return {
                            sku: inventory.sku,
                            rarity: rarity.name,
                            name: inventory.name,
                            inventory_id: inventory.id,
                            sub_category: sub_category,
                            image_url: inventory.image_url,
                            description: inventory.description,
                            category: parent ? parent.value : '-',
                        }
                    }

                    return {
                        ...data,
                        reward: {
                            ...data.reward,
                            inventory: itemFormatter(data.reward.inventory)
                        }
                    }
                }

                return null
            },
            transformErrorResponse: () => null,
            providesTags: ["Claim"]
        }),

        //MUTATION
        claimInventory: builder.mutation({
            query: () => ({
                url: FREE_ITEM.CLAIM,
                method: "POST",
                body: {jwt: localStorage.getItem("accessToken")},
            }),
            transformResponse: (response) => response,
            invalidatesTags: ["Claim"]
        })
    })
})

export const {useGetActiveRewardQuery, useClaimInventoryMutation} = freeItemApi