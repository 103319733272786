import {useParams} from "react-router-dom";
import React, {useEffect} from "react";

import {useGetCurrentInventoryQuery} from "store/Locker/locker.api";
import {ModalsProvider, DefaultLayout} from "layouts";
import {TRANSACTION_HISTORY_SECTION} from "utils";
import {useGMTEvents} from "hooks";

import {
    BazaarCurrentCosmeticSteps,
    BazaarCurrentCosmeticPriceHistory,
    BazaarCurrentCosmeticTransactions,
    BazaarCurrentAlreadyListedWeb3CosmeticSection
} from "components/organisms/Bazaar";
import {RecentlyViewedItemsSection,} from "components/molecules";

const BazaarCurrentAlreadyListedWeb3Cosmetic = () => {
    const viewStart = new Date().getTime()

    const {sku, order_id, token_id} = useParams()
    const {viewSpentTimeProcess} = useGMTEvents()

    const {data, isLoading, isSuccess} = useGetCurrentInventoryQuery(sku)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => () => viewSpentTimeProcess(viewStart, token_id), []);

    return (
        <DefaultLayout>
            <ModalsProvider>
                <BazaarCurrentAlreadyListedWeb3CosmeticSection
                    sku={sku}
                    data={data}
                    orderId={order_id}
                    isSuccess={isSuccess}
                    isLoading={isLoading}
                />
                <BazaarCurrentCosmeticPriceHistory data={data}/>
                <BazaarCurrentCosmeticTransactions
                    data={data}
                    transactionHistorySection={TRANSACTION_HISTORY_SECTION.INVENTORY_ACTIVITY}
                />
                <RecentlyViewedItemsSection/>
                <BazaarCurrentCosmeticSteps/>
            </ModalsProvider>
        </DefaultLayout>
    )
}

export default BazaarCurrentAlreadyListedWeb3Cosmetic