import React from "react";
import Title from "components/atoms/TitleTemplate";
import Image from "components/atoms/Image";

import {png} from "assets/png";
import "./style.scss"

const DeckGoal = () => {
    return (
        <section className="deck_goal">
            <Title>Goal to be the <br/><span>#1 multiplayer game</span></Title>
            <div className="deck_goal_content">
                <div className="deck_goal_content_img">
                    <div className="deck_goal_content_img_gradient-background"/>
                    <Image alt="hero" src={png.HeroIndividual6}/>
                </div>
                <div className="deck_goal_content_text">
                    <p>We’re coming for the throne <span>Roblox</span> and <span>Fortnite</span>! Everything about KOMPETE is designed
                        to attract a mass-market audience & retain them for years to come.</p>
                </div>
            </div>
        </section>
    )
}
export default DeckGoal