import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {AUTH_NETWORK, BUTTON_VARIANT, ERROR, GLOBAL_MODALS, INPUT_TYPE, REG_EXP} from "utils";
import {useGlobalModalsContext} from "layouts/context/GlobalModalsProvider";
import {userSignIn, reset} from "store/AuthV2/AuthV2Slice";
import {useLocalStorage} from "hooks";

import {ButtonV2, InputV2} from "components/atoms";

const EmailAuth = () => {
    const dispatch = useDispatch()

    const {setModal} = useGlobalModalsContext()
    const {setSignInNetwork} = useLocalStorage()

    const {
        userData,
        error: signInError,
        loading: signInLoading,
        success: signInSuccess
    } = useSelector(state => state.authV2.signIn)

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [errorMessage, setErrorMessage] = useState(null)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => followLoginProcess(), [signInSuccess, signInError]);

    const followLoginProcess = () => {
        if (signInSuccess && userData.nickname) setModal(GLOBAL_MODALS.CLOSE)

        if (signInError) {
            dispatch(reset("signIn"))
            setErrorMessage(ERROR.INVALID_DETAILS)
        }
    }

    const login = () => {
        const isValidEmail = email.match(REG_EXP.EMAIL)

        setErrorMessage(ERROR.CLEAN)

        if (!isValidEmail) setErrorMessage(ERROR.INVALID_DETAILS)

        setSignInNetwork(AUTH_NETWORK.EMAIL)
        dispatch(userSignIn({username: email, password: password}))
    }

    return (
        <div className="sign-in-modal_email-auth">
            <div className="sign-in-modal_email-auth_or-line"><span/><span>Or</span><span/></div>
            <InputV2
                name="email"
                value={email}
                label="Email address"
                type={INPUT_TYPE.TEXT}
                disabled={signInLoading}
                errorMessage={errorMessage}
                onChange={e => setEmail(e.target.value)}
            />
            <InputV2
                name="password"
                label="Password"
                value={password}
                disabled={signInLoading}
                isError={!!errorMessage}
                type={INPUT_TYPE.PASSWORD}
                labelClickText="Forgot Password?"
                onChange={e => setPassword(e.target.value)}
                labelClick={() => setModal(GLOBAL_MODALS.CHANGE_PASSWORD)}
            />
            <ButtonV2
                onClick={login}
                disabled={!password || !email}
                variant={signInLoading ? BUTTON_VARIANT.LOADING : BUTTON_VARIANT.DEFAULT}
            >
                Sign In
            </ButtonV2>
        </div>
    )
}

export default EmailAuth;