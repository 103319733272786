import React from "react"
import "./style.scss"

const TableBodySkeleton = ({headOption, limitValue, dataCount}) => {
    const colSpanCount = headOption.length

    const bodyTrCount = new Array(dataCount ? dataCount : limitValue).fill("")

    return (
        <tbody className="table-container_main_skeleton">
        {bodyTrCount.map((_, index) => (
            <tr key={index}>
                <td className="skeleton" colSpan={colSpanCount}></td>
            </tr>
        ))}
        </tbody>
    )
}

export default TableBodySkeleton