import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {BUTTON_VARIANT, ERROR, GLOBAL_MODALS, INPUT_TYPE, REG_EXP} from "utils";
import {useGlobalModalsContext} from "layouts/context/GlobalModalsProvider";
import {userSignUp, reset} from "store/AuthV2/AuthV2Slice";

import {InputV2, Modal, ButtonV2} from "components/atoms";

import "./style.scss"
import {useConnectedWallet, useConnectWallet} from "hooks";
import {Link} from "react-router-dom";

const SignUpModal = () => {
    const dispatch = useDispatch()

    const {setModal} = useGlobalModalsContext()
    const {disconnectWallet} = useConnectWallet()
    const {isConnected} = useConnectedWallet()

    const {
        error: signUpError,
        loading: signUpLoading,
        success: signUpSuccess,
        errorMessage: signUpErrorMessage
    } = useSelector(state => state.authV2.signUp)

    const [password, setPassword] = useState("")
    const [email, setEmail] = useState("")
    const [error, setError] = useState("")

    useEffect(() => followSignUpProcess(), [signUpError, signUpSuccess]);

    const followSignUpProcess = () => {
        if (signUpSuccess) {
            if (isConnected) disconnectWallet()
            setModal(GLOBAL_MODALS.CONNECT_WALLET)
        }

        if (signUpError) setError(signUpErrorMessage)

        dispatch(reset("signUp"))
    }

    const signUp = () => {
        setError(ERROR.CLEAN)

        const validations = [
            {isValid: email.match(REG_EXP.EMAIL), error: ERROR.INVALID_EMAIL},
            {isValid: password.match(REG_EXP.PASSWORD), error: ERROR.INVALID_PASSWORD}
        ];

        for (const {isValid, error} of validations) {
            if (!isValid) {
                setError(error);
                return;
            }
        }

        dispatch(userSignUp({email, password, terms: true, offers: true, is13years: true,}))
    }

    const handleClick = () => {
        setModal(GLOBAL_MODALS.CLOSE)
    };

    return (<Modal
        isOpen={true}
        title="Create your kompete account"
        onClose={() => setModal(GLOBAL_MODALS.CLOSE)}
    >
        <div className="sign-up-modal">
            <InputV2
                name="email"
                value={email}
                errorMessage={error}
                label="Email address"
                disabled={signUpLoading}
                onChange={(e) => setEmail(e.target.value)}
            />
            <InputV2
                isError={error}
                name="password"
                label="Password"
                value={password}
                disabled={signUpLoading}
                type={INPUT_TYPE.PASSWORD}
                onChange={(e) => setPassword(e.target.value)}
            />
            <p>By accessing KOMPETE, you agree to our <Link to="/terms" onClick={handleClick}>Terms</Link> and <Link
                to="/privacy" onClick={handleClick}>Privacy Policy</Link>, confirm you are over 13 years old, and
                acknowledge that you may receive email notifications from us, which you can opt out of at any time.</p>
            <ButtonV2
                onClick={signUp}
                disabled={!email || !password}
                variant={signUpLoading ? BUTTON_VARIANT.LOADING : BUTTON_VARIANT.DEFAULT}
            >
                Sign up
            </ButtonV2>
        </div>
    </Modal>)
}

export default SignUpModal