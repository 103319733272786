import React, {memo, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import classNames from "classnames";

import {useConnectWallet, useOutside, useWindowSize} from "hooks";

import {Icon, Image} from "components/atoms";

import {png} from "assets/png";

const OptionsBlock = ({signOut, setMobileMenu}) => {
    const navigate = useNavigate();

    const {userData, loading} = useSelector(state => state.authV2.signIn)
    const [isOpenUserMenu, setIsOpenUserMenu] = useState(false)

    const {disconnectWallet,} = useConnectWallet()
    const {width} = useWindowSize()

    const avatarStyle = classNames('navigation_auth_user-details_panel_options_img', {"skeleton": loading})
    const wrapperRef = useRef(null)

    useOutside(wrapperRef, setIsOpenUserMenu)

    const openItemsClick = (currentItem) => {
        switch (currentItem) {
            case "profile":
                navigate("/profile")
                break
            case "walletDisconnect":
                disconnectWallet()
                break
            case "logOut":
                signOut()
                break
            default:
                signOut()
        }

        setIsOpenUserMenu(false)
    }

    return (
        <div ref={wrapperRef} className="navigation_auth_user-details_panel_options">
            <button
                onClick={() => width > 991 ? setIsOpenUserMenu(!isOpenUserMenu) :
                    setMobileMenu({
                        open: true, section: "userMenu"
                    })}
            >
                <div className={avatarStyle}>
                    <Image alt="avatar" src={!loading ? userData?.picture ? userData?.picture : png.NewAvatar : ""}/>
                    <span className="online"></span>
                </div>
                <div className="navigation_auth_user-details_panel_options_selectDown">
                    <Icon name="select"/>
                </div>
            </button>
            {
                isOpenUserMenu &&
                <div className="navigation_auth_user-details_panel_options_hidden-block">
                    <OptionItem onClickCallBack={(currentItem) => openItemsClick(currentItem)} icon="profile">
                        Profile
                    </OptionItem>
                    {/*<OptionItem*/}
                    {/*    onClickCallBack={(currentItem) => openItemsClick(currentItem)}*/}
                    {/*    icon="walletDisconnect"*/}
                    {/*    disabled={!isConnected}*/}
                    {/*>*/}
                    {/*    Wallet Disconnect*/}
                    {/*</OptionItem>*/}
                    <OptionItem onClickCallBack={(currentItem) => openItemsClick(currentItem)} icon="logOut">
                        Log out
                    </OptionItem>
                </div>
            }
        </div>
    )
}

export default memo(OptionsBlock)

const OptionItem = ({onClickCallBack, icon, disabled, children}) => {
    return (
        <button
            disabled={disabled}
            onClick={() => onClickCallBack(icon)}
            className="navigation_auth_user-details_panel_options_hidden-block_option-item"
        >
            {children} <Icon name={icon}/>
        </button>
    )
}