import {useDispatch} from "react-redux";
import React from "react";

import {setRefetchInventory} from "store/Locker/lockerSlice";
import {useModalsContext} from "layouts";

import {CosmeticModalTemplate} from "components/molecules";

const LockerConvertSuccessModal = ({changeTab}) => {
    const {setCurrentModal} = useModalsContext()
    const dispatch = useDispatch()

    return (
        <CosmeticModalTemplate
            show={true}
            showInfo={false}
            title="Konvert successful!"
            buttonOne={{
                callBack: () => {
                    setCurrentModal({status: ""})
                    changeTab("owned", true)
                    dispatch(setRefetchInventory())
                },
                text: "Go to locker"
            }}
            buttonTwo={{callBack: () => setCurrentModal({status: ""}), text: "Return to cosmetic"}}
        />
    )
}

export default LockerConvertSuccessModal