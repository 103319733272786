import React, {useEffect, useRef, useState, memo} from "react"
import {useOutside} from "hooks";

import TabNavItem from "./TabNavItem";
import TabBlockDisplay from "./TabBlockDisplay";
import TabBlockMobile from "./TabBlockMobile";

import {Icon, Image} from "components/atoms";

const TabDynamicBlock = ({options, activeTab, setActiveTab, isSetUrlParams, setParams}) => {
    const [tabBlockMobile, setTabBlockMobile] = useState(false)
    const currentTab = options.filter(option => option.id === activeTab)[0]
    const blockRef = useRef(null)

    useOutside(blockRef, setTabBlockMobile)

    useEffect(() => {
        setTabBlockMobile(false)
    }, [activeTab])

    return (
        <React.Fragment>
            <TabBlockDisplay>
                {
                    options.map((tab, index) =>
                        <React.Fragment key={index}>
                            <TabNavItem isSetUrlParams={isSetUrlParams} setParams={setParams} id={tab.id}
                                        activeTab={activeTab} setActiveTab={setActiveTab}>
                                <Image src={tab.icon} alt={tab.name}/>
                                <div>{tab.name}</div>
                            </TabNavItem>
                        </React.Fragment>
                    )
                }
            </TabBlockDisplay>
            <TabBlockMobile>
                <div className="tab-block-mobile_block" ref={blockRef}>
                    <div className="tab-block-mobile_block_head" onClick={() => setTabBlockMobile(!tabBlockMobile)}>
                        <div>
                            <Image src={currentTab?.icon} alt={currentTab?.name}/>
                            <div>{currentTab?.name}</div>
                        </div>
                        <div className={`${tabBlockMobile ? "icon-rotate" : ""}`}>
                            <Icon name="select"/>
                        </div>
                    </div>
                    {
                        tabBlockMobile &&
                        <div className="tab-block-mobile_block_navItems">
                            {
                                options.map((tab, index) =>
                                    <React.Fragment key={index}>
                                        <TabNavItem isSetUrlParams={isSetUrlParams} setParams={setParams} id={tab.id}
                                                    activeTab={activeTab} setActiveTab={setActiveTab}>
                                            <Image alt={tab.name} src={tab.icon}/>
                                            <div>{tab.name}</div>
                                        </TabNavItem>
                                    </React.Fragment>
                                )
                            }
                        </div>
                    }
                </div>
            </TabBlockMobile>

        </React.Fragment>
    )
}

export default memo(TabDynamicBlock)