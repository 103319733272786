import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import shopService from "./voolahShopService";

const initialState = {

    paymentUIvoolah: {
        loading: false,
        error: false,
        success: false,
        errorMessage: null,
        token: null
    },
}

export const buyVoolah = createAsyncThunk("xsolla/buy/voolah", async (data, thunkAPI) => {
    try {
        const accessToken = thunkAPI.getState().authV2.signIn.userData.accessToken
        return await shopService.buyVoolah({...data, accessToken}, thunkAPI)
    } catch (error) {
        return thunkAPI.rejectWithValue("Something went wrong")
    }
})

const shopSlice = createSlice({
    name: "voolahShop",
    initialState,
    reducers: {
        reset: (state, data) => {
            const {section} = data.payload

            state[section].loading = false
            state[section].error = false
            state[section].success = false
            state[section].errorMessage = null

            if (state[section].token) {
                state[section].token = null
            }

            if (state[section].data) {
                state[section].data = null
            }
        },

        setData: (state, action) => {
            const {path1, path2, data} = action.payload

            state[path1][path2] = data
        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(buyVoolah.pending, (state) => {
                state.paymentUIvoolah.loading = true
            })
            .addCase(buyVoolah.fulfilled, (state, action) => {
                state.paymentUIvoolah.loading = false
                state.paymentUIvoolah.success = true
                state.paymentUIvoolah.token = action.payload.token
            })
            .addCase(buyVoolah.rejected, (state, action) => {
                state.paymentUIvoolah.loading = false
                state.paymentUIvoolah.success = false
                state.paymentUIvoolah.errorMessage = action.payload
            })
    }
})

export const {reset, setData} = shopSlice.actions
export default shopSlice.reducer