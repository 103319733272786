import React from "react";
import "./style.scss"

const HomeComingSoon = () => {
    return (
        <section className="home_coming-soon">
            <h1><span>More game modes</span> <br/>Coming soon!</h1>
        </section>
    )
}
export default HomeComingSoon