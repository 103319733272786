export const SUCCESS = {
    RECOVER_PASSWORD: "Check your registered KOMPETE email for a password recovery link.",
    NICKNAME_UPDATED: "Nickname updated successfully!",
    ACCOUNT_CREATED: "Your account has been created.",
    WALLET_REMOVED: "Wallet removed successfully",
    WALLET_CONNECTED: "Web3 Wallet connected!",
    WALLET_ADDED: "Wallet added successfully",
    NICKNAME_VALID: "Nickname is valid",
    SWITCHED: "Switched successfully",
    ADDED: "added successfully"
}