import React from "react"
import DefaultLayout from "layouts/DefaultLayout";
import NewsMain from "components/organisms/News/NewsMain";
import NewsContent from "components/organisms/News/NewsContent";
import Seo from "components/atoms/Seo";

const News = () => {

    return (
        <DefaultLayout>
            <Seo
                title="News - KOMPETE"
                description="Catch up on the latest updates for KOMPETE."
            />
            <NewsMain/>
            <NewsContent/>
        </DefaultLayout>
    )
}
export default News