import React from "react";
import Title from "components/atoms/TitleTemplate";
import Image from "components/atoms/Image";
import {png} from "assets/png";

const DeckModernizeGame = () => {
    return (
        <section className="deck_modernize-game">
            <Title underline={true}>Modernize <span>Game</span></Title>
            <div className="deck_modernize-game_content container">
                <div>
                    <Image alt="founder" src={png.Founder}/>
                </div>
                <div>
                    <div className="deck_modernize-game_content_founder">
                        <h4>Justin Meister</h4>
                        <ul>
                            <li>Founder and CEO of Modernize Games</li>
                            <li>Ex pro gamer (Madden, Fortnite, NBA2K*)</li>
                            <li>Pivoted successful marketing company to make KOMPETE.</li>
                        </ul>
                    </div>
                    <div className="deck_modernize-game_content_members">
                        <h4>Notable Team Members</h4>
                        <ul>
                            <li>
                                <span>Alex Arzhyani</span>
                                <span>Tech Lead</span>
                            </li>
                            <li>
                                <span>Alex Arzhyani</span>
                                <span>CMO</span>
                            </li>
                            <li>
                                <span>Alex Arzhyani</span>
                                <span>Tech Lead</span>
                            </li>
                            <li>
                                <span>Alex Arzhyani</span>
                                <span>Cmo</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default DeckModernizeGame