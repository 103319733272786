import React from "react";
import Title from "components/atoms/TitleTemplate";

const DeckWin = () => {
    return (
        <section className="deck_win">
            <Title underline={true}>Why we will <span>win</span></Title>
            <div className="deck_win_content container">
                <div className="deck_win_content_option-item">
                    <h4>We break down high barriers to entry in hungry niches</h4>
                    <ul>
                        <li>No free-to-play sports games</li>
                        <li>No substitute for many sports games</li>
                        <li>Few cross platform options for targeted game modes</li>
                        <li>Limited mobile availability for targeted game modes at substitutable quality level</li>
                    </ul>
                </div>
                <div className="deck_win_content_option-item">
                    <h4>We are designed to scale to the masses</h4>
                    <ul>
                        <li>Popular & proven game modes with gameplay that’s not too easy or too difficult</li>
                        <li>Ability to retain players with substitutable gameplay and more game modes</li>
                        <li>Huge audience pools to advertise to because of multiple game modes</li>
                        <li>Ability to acquire players through Mobile</li>
                    </ul>
                </div>
                <div className="deck_win_content_option-item">
                    <h4>We’re hard to kill off</h4>
                    <ul>
                        <li>Targeted game modes won’t die. People will always play multiplayer games such as shooting, basketball,
                            racing, golf, hockey, futbol, etc...I have been for 23 years!
                        </li>
                        <li>Retain audience because multiple game modes are available at a high quality, and new game modes are
                            added.
                        </li>
                        <li>Infiltrate new audiences quickly because we can add new game modes easily.</li>
                    </ul>
                </div>
            </div>
        </section>
    )
}
export default DeckWin