import React from "react";

import {useModalsContext} from "layouts";
import {STEPS_STATUS} from "utils";

import VoolahPurchaseSuccessful from "./VoolahPurchaseSuccessful";
import VoolahOrderModal from "./VoolahOrderModal";

const VoolahSteps = ({currentPackageIndex}) => {
    const {currentModal, setCurrentModal} = useModalsContext()

    return (
        <React.Fragment>
            {
                {
                    [STEPS_STATUS.ORDER]:
                        <VoolahOrderModal
                            show={true}
                            currentPackageIndex={currentPackageIndex}
                            setCurrentModal={() => setCurrentModal({status: STEPS_STATUS.CLOSE})}
                        />,
                    [STEPS_STATUS.SUCCESS]:
                        <VoolahPurchaseSuccessful
                            show={true}
                            currentPackageIndex={currentPackageIndex}
                            setCurrentModal={() => setCurrentModal({status: STEPS_STATUS.CLOSE})}
                        />
                }[currentModal.status]
            }
        </React.Fragment>
    )
}

export default VoolahSteps