import React, {useEffect} from "react";

import {ButtonV2, Image, Modal} from "components/atoms";

import {STEPS_STATUS, voolahPackageDetails} from "utils";
import {png} from "assets/png";

const VoolahPurchaseSuccessful = ({show, currentPackageIndex, setCurrentModal}) => {
    const valueOfPurchase = voolahPackageDetails[currentPackageIndex].value

    useEffect(() => {
        window._paq.push(['trackEvent', "Voolah Purchase", `Amount ${valueOfPurchase}`, 'Purchase'])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Modal
            isOpen={show}
            title="Purchase successful!"
            onClose={() => setCurrentModal({status: STEPS_STATUS.CLOSE})}
        >
            <div className="shop-voolah_purchase-successful_content">
                <div className="shop-voolah_purchase-successful_content_info">
                    <div className="shop-voolah_purchase-successful_content_info_img">
                        <Image alt="cosmetic" src={voolahPackageDetails[currentPackageIndex].src.small}/>
                    </div>
                    <div className="shop-voolah_purchase-successful_content_info_details">
                        <div className="shop-voolah_purchase-successful_content_info_details_title">
                            <Image alt="voolah" src={png.VoolahCoin}/>
                            <h5>{valueOfPurchase} Voolah</h5>
                        </div>
                    </div>
                </div>
                <ButtonV2 onClick={() => setCurrentModal({status: STEPS_STATUS.CLOSE})}>Go to shop</ButtonV2>
            </div>
        </Modal>
    )
}

export default VoolahPurchaseSuccessful