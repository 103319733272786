import React, {useState, useEffect, useRef} from "react";
import classNames from "classnames";

import {
    useUtils,
    useLocalStorage,
    useAuthorization,
    useConnectWallet,
    useConnectedWallet
} from "hooks";
import {useGetActiveDivineShopQuery} from "store/Global/global.api";
import {ERROR, GLOBAL_MODALS, STEPS_STATUS, SUCCESS} from "utils";
import {useGlobalModalsContext, useModalsContext} from "layouts";

import CosmeticViewTemplate from "components/molecules/CosmeticViewTemplate";
import EligibilityItemBlock from "./EligibilityItemBlock";
import DeadlineCountdown from "./DeadlineCountdown";
import EligibilityBlock from "./EligibilityBlock";
import DivineSteps from "./modals/DivineSteps";
import Description from "./Description";
import PriceInfo from "./PriceInfo";
import BuyButton from "./BuyButton";

import {png} from "assets/png";
import "./style.scss"

const DivineShop = () => {
    const {isConnected, isWalletConnection, isConnectedAddressCorrect} = useConnectedWallet()
    const {setModal, setMessage, resetStatesAndClose} = useGlobalModalsContext()
    const {setWalletConnectionSuccess} = useLocalStorage()
    const {disconnectWallet} = useConnectWallet()
    const {setCurrentModal} = useModalsContext()
    const {isAuth} = useAuthorization()
    const {addChain} = useUtils()

    const [quantityValue, setQuantityValue] = useState(1)

    const {data, isLoading, isFetching, refetch} = useGetActiveDivineShopQuery(null, {
        pollingInterval: 240000
    })

    const divineShopStyle = classNames("shop-divine", {"divine": true})
    const isFirstRender = useRef(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => checkWalletConnectionAndAddressMatch(), [isConnected]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => refectDivineShopQueryAfterLogin(), [isAuth])

    const checkWalletConnectionAndAddressMatch = async () => {
        if (isConnected && !isWalletConnection) {
            setModal(GLOBAL_MODALS.PROCESS)
            const {status} = await addChain()

            if (!status) {
                setModal(GLOBAL_MODALS.ERROR)
                setMessage(ERROR.FAIL_REQUEST)

                resetStatesAndClose()
                disconnectWallet()
            } else {
                if (!isConnectedAddressCorrect) {
                    setCurrentModal({status: STEPS_STATUS.CLOSE, message: ""})
                    setModal(GLOBAL_MODALS.ERROR)
                    setMessage(ERROR.WALLET_CONNECTED_MATCH_INVALID)

                    resetStatesAndClose()
                    disconnectWallet()
                } else {
                    setModal(GLOBAL_MODALS.SUCCESS)
                    setMessage(SUCCESS.WALLET_CONNECTED)
                    resetStatesAndClose()
                    setWalletConnectionSuccess()
                }
            }
        }
    }

    const refectDivineShopQueryAfterLogin = () => {
        isFirstRender.current = false;
        if (!isFirstRender.current) refetch()
    }

    const inventory = data?.inventory
    const inventoryAttribute = inventory?.category ?  [{name: inventory?.category}] : []
    const inventoryImage = inventory?.image_url || png.RedQuestionMark
    const inventoryTitle = inventory ? inventory.name : "??????????????"
    const isEligibilityAvailable = data?.eligibility
    const isEligible = data?.isEligible

    return (
        <section className={divineShopStyle}>
            <CosmeticViewTemplate
                rarity="divine"
                img={inventoryImage}
                title={inventoryTitle}
                isNeverSoldAgain={true}
                attribute={inventoryAttribute}
                classname="shop-divine_content"
                loading={isLoading || isFetching}
            >
                {isEligibilityAvailable && !isLoading && !isFetching && <EligibilityBlock isEligible={isEligible}/>}
                <DeadlineCountdown
                    data={data}
                    isLoading={isLoading || isFetching}
                />
                <EligibilityItemBlock
                    data={data}
                    isLoading={isLoading || isFetching}/>
                <PriceInfo
                    data={data}
                    quantity={quantityValue}
                    setQuantity={setQuantityValue}
                    isLoading={isLoading || isFetching}
                />
                <BuyButton
                    data={data}
                    quantity={quantityValue}
                    isEligible={isEligible}
                    isLoading={isLoading || isFetching}
                    isEligibilityAvailable={isEligibilityAvailable}
                />
                <Description
                    data={data}
                    isLoading={isLoading || isFetching}
                />
            </CosmeticViewTemplate>
            <DivineSteps/>
        </section>
    )
}
export default DivineShop