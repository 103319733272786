import {useEffect, useState} from "react"

export const useInitializeWebSocketConnection = ({server, isReConnectWebSocket}) => {
    const [isLoading, setIsLoading] = useState(true)
    const [isSuccess, setIsSuccess] = useState(false)
    const [isError, setIsError] = useState(false)
    const [isReceivedData, setIsReceivedData] = useState(null)
    const [socket, setSocket] = useState(null)

    useEffect(() => {
        const socket = new WebSocket(server)

        socket.onopen = () => {
            setSocket(socket)
            setIsLoading(true)
        }

        socket.onmessage = (event) => {
            setIsSuccess(true)
            setIsLoading(false)
            setIsReceivedData(event.data)
        }

        socket.onerror = () => {
            setSocket(null)
            setIsError(true)
            setIsSuccess(false)
            setIsLoading(false)
            setIsReceivedData(null)
        }

        socket.onclose = () => {
            setSocket(null)
            setIsError(true)
            setIsSuccess(false)
            setIsLoading(false)
            setIsReceivedData(null)
        }

        return () => {
            if (socket && isReConnectWebSocket) socket.close()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReConnectWebSocket])

    return {isLoading, isSuccess, isError, isReceivedData, socket, setIsLoading}
}
