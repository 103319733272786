import {useNavigate, useParams} from "react-router-dom";
import React, {memo, useEffect, useState} from "react";

import {INVENTORY_CORRESPOND_END, INVENTORY_TYPE, isEmpty} from "utils";
import {useGetRecentlyViewedItemsQuery} from "store/Global/global.api";
import {useAuthorization} from "hooks";

import {SkeletonNftCart} from "components/atoms";
import {OwnedCard} from "../Cards";

import "./style.scss"

const RecentlyViewedItemsSection = () => {
    const {order_id} = useParams()
    const navigate = useNavigate()

    const {accessToken} = useAuthorization()

    const [requestData, setRequestData] = useState({items: null, jwt: accessToken})

    const {
        data,
        isLoading,
        isSuccess,
        isError
    } = useGetRecentlyViewedItemsQuery(requestData, {skip: !requestData.items})

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => saveLastViewedOrder(), [order_id]);

    const navigateTo = (order) => {
        const {id, chain, inventory} = order
        const {token_id, sku} = inventory
        const inventoryChainSku = sku + INVENTORY_CORRESPOND_END[chain]

        navigate(`/bazaar/inventory/${inventoryChainSku}/${token_id}/${id}`)
    }

    const saveLastViewedOrder = () => {
        const currentSavedOrders = localStorage.getItem("recentlyViewedItems")
        const parsedItemsData = JSON.parse(currentSavedOrders)

        if (currentSavedOrders && Array.isArray(parsedItemsData)) {
            const isAlreadySave = parsedItemsData.includes(order_id)

            if (!isAlreadySave && order_id) {
                const newItemsData = [...parsedItemsData, order_id]

                if (newItemsData.length > 4) newItemsData.shift()

                localStorage.setItem("recentlyViewedItems", JSON.stringify(newItemsData))
            }
            setRequestData({...requestData, items: parsedItemsData})
        } else {
            setRequestData({...requestData, items: []})

            if (order_id) localStorage.setItem("recentlyViewedItems", JSON.stringify([order_id]))
        }

    }

    return (
        <section className="recently-viewed-items-section">
            <h2>Recently viewed cosmetics</h2>
            <div className="recently-viewed-items-section_block">
                {isLoading || isError
                    ? <SkeletonNftCart type="listed" cartLineCount={4}/>
                    : isSuccess && !isEmpty(data)
                        ? (
                            <React.Fragment>
                                {React.Children.toArray(
                                    data.map(order => (
                                        <OwnedCard
                                            price={order.price}
                                            chainId={order.chain}
                                            name={order.inventory.name}
                                            trade={INVENTORY_TYPE.WEB3}
                                            img={order.inventory.image_url}
                                            {...(order.status === 'listed' && {onClick: () => navigateTo(order)})}
                                            rarity={`card_${order.inventory.rarity.name}`}
                                        />
                                    ))
                                )}
                            </React.Fragment>
                        ) : <SkeletonNftCart type="listed" cartLineCount={4}/>
                }
            </div>
        </section>
    )
}

export default memo(RecentlyViewedItemsSection)