import {ATTRIBUTES_EXTERNAL_ID, ATTRIBUTES_VALUE_EXTERNAL_ID, CHAIN_ID} from "../constant/enum";
import {inventoryImageConvertor} from "./inventoryImageConvertor";
import {inventorySkuConvertor} from "./inventorySkuConvertor";
import config from "../../web3/config"

export const inventoryConvertor = (item) => {
    item.attributes.forEach(attribute => {
        if (attribute.external_id === ATTRIBUTES_EXTERNAL_ID.RARITY) {
            item.rarity = attribute.values[0].external_id
        }

        if (attribute.external_id === ATTRIBUTES_EXTERNAL_ID.WEB2_OR_WEB3) {
            const initialSKU = inventorySkuConvertor(item.sku, attribute.values[0].value)
            const currentChains = config.chains[config.networkEnvironment]

            item.initial_sku = initialSKU
            item.image_url = inventoryImageConvertor(initialSKU)

            if (attribute.values[0].external_id === ATTRIBUTES_VALUE_EXTERNAL_ID.WEB3_ETH) {
                item.chain_id = currentChains[0].chainId ?? CHAIN_ID.ETH_MAINNET
                item.is_nft = true
            }

            if (attribute.values[0].external_id === ATTRIBUTES_VALUE_EXTERNAL_ID.WEB3_BASE) {
                item.chain_id = currentChains[1].chainId ?? CHAIN_ID.BASE_MAINNET
                item.is_nft = true
            }

            if (attribute.values[0].external_id === ATTRIBUTES_VALUE_EXTERNAL_ID.WEB2) {
                item.chain_id = null
                item.is_nft = false
            }
        }
    })

    if (item?.custom_attributes) {
        item.token_id = Number(item.custom_attributes.token_id)

        delete item.custom_attributes
    }

    delete item.attributes
}