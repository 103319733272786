import React from 'react';

import {useGlobalModalsContext} from "layouts/context/GlobalModalsProvider";
import {GLOBAL_MODALS} from "utils";

import CreateAccount from "./CreateAccount";
import {Modal} from "components/atoms";
import SocialAuth from "./SocialAuth";
import EmailAuth from "./EmailAuth";

import "./style.scss"

const SignInModal = () => {
    const {setModal} = useGlobalModalsContext()

    return (
        <Modal
            isOpen={true}
            title="sign in To KOMPETE"
            onClose={() => setModal(GLOBAL_MODALS.CLOSE)}
        >
            <div className="sign-in-modal">
                <SocialAuth/>
                <EmailAuth/>
                <CreateAccount/>
            </div>
        </Modal>
    )
}

export default SignInModal