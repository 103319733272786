import React from 'react';

import DownloadGameVideo from "./DownloadGameVideo";
import {ButtonLink} from "components/atoms";

const DownloadPcContent = () => (
    <div className="download_main_content_pc container">
        <h1>Download for free</h1>
        <h2>& get a free legendary skin in discord</h2>
        <div className="download_main_content_pc_video-block">
            <DownloadGameVideo/>
        </div>
        <div className="download_main_content_pc_links">
            <ButtonLink href="https://www.modernizegames.com/ModernizeInstaller.exe">
                Pc download
            </ButtonLink>
            <ButtonLink href="https://discord.gg/kompete-921684972786573333" isTarget={true}>
                Free skin in discord
            </ButtonLink>
        </div>
    </div>
)

export default DownloadPcContent