export const calculateVoolahPriceToEth = async (price) => {
    return fetch("https://api.dexscreener.com/latest/dex/pairs/bsc/0xb125aa15ad943d96e813e4a06d0c34716f897e26,0x16b9a82891338f9ba80e2d6970fdda79d1eb0dae")
        .then(result => result.json())
        .then(result => {
            const ethPrice = result.pairs[0].priceUsd //1 ETH => USD
            const voolahPrice = 0.01 //1 VOOLAH => USD

            return (parseFloat(price.toString().replace(/,/g, '')) * voolahPrice) / ethPrice
        })
        .catch(() => 1000)
}