import {useSelector} from "react-redux";
import {useDetectMobile} from "./useDetectMobile";

export const useGMTEvents = () => {
    const {userData} = useSelector(state => state.authV2.signIn)
    const {isMobile} = useDetectMobile()

    const userSignIn = (userUuid, loginMethod) => {
        window.dataLayer.push({
            'event': 'user_login',
            'login_method': loginMethod,
            'user_uuid': userUuid
        });
    }

    const userSignUp = (userUuid, loginMethod) => {
        window.dataLayer.push({
            'event': 'user_sign_up',
            'login_method': loginMethod,
            'user_uuid': userUuid
        });
    }

    const NFTConversionStart = (userUuid, cosmeticIds, cosmeticNames) => {
        window.dataLayer.push({
            'event': 'nft_conversion_start',
            'user_uuid': userUuid,
            'cosmetic_ids': cosmeticIds,
            'cosmetic_names': cosmeticNames
        })
    }

    const NFTConversionComplete = (userUuid, cosmeticIds, nftIds, transactionHash) => {
        window.dataLayer.push({
            'event': 'nft_conversion_complete',
            'user_uuid': userUuid,
            'cosmetic_ids': cosmeticIds,
            'nft_ids': nftIds,
            'transaction_hash': transactionHash
        })
    }

    const NFTListing = (userUuid, nftId, listPrice, currency) => {
        window.dataLayer.push({
            'event': 'nft_list',
            'user_uuid': userUuid,
            'nft_id': nftId,
            'list_price': listPrice,
            'currency_type': currency
        })
    }

    const NFTSaleBazaar = (buyerUuid, nftId, salePrice, currency, transactionHash) => {
        window.dataLayer.push({
            'event': 'nft_sale',
            'buyer_uuid': buyerUuid,
            'nft_id': nftId,
            'sale_price': salePrice,
            'currency_type': currency,
            'transaction_hash': transactionHash,
        })
    }

    const NFTPurchaseShop = (userUuid, nftId, purchasePrice, currency, transactionHash) => {
        window.dataLayer.push({
            'event': 'nft_purchase',
            'user_uuid': userUuid,
            'nft_id': nftId,
            'purchase_price': purchasePrice,
            'currency_type': currency,
            'transaction_hash': transactionHash
        });
    }

    const updateWallet = (userUuid) => {
        window.dataLayer.push({
            'event': 'wallet_update',
            'user_uuid': userUuid,
            'updated_fields': ['wallet']
        });
    }

    const web2ItemPurchase = (userUuid, itemId, itemName, price, currency) => {
        window.dataLayer.push({
            'event': 'web2_purchase',
            'user_uuid': userUuid,
            'item_id': itemId,
            'item_name': itemName,
            'price': price,
            'currency_type': currency,
        });
    }

    const voolahPurchaseComplete = () => {
        window.gtag('event', 'voolah_purchase_complete', {
            'user_uuid': `{{user_uuid}}`
        });
    }

    const NFTView = (userUuid, nftId, timeSpent) => {
        window.dataLayer.push({
            'event': 'nft_view',
            'user_uuid': userUuid,
            'nft_id': nftId,
            'time_spent': timeSpent,
        });
    }

    const setDataLayerDefaultData = () => {
        const isUserDataAvailable = window.dataLayer.find(data => data.user_uuid)

        if (!isUserDataAvailable) {
            window.dataLayer.push({user_uuid: userData.id, platform_type: isMobile ? 'mobile' : 'desktop'});
        }
    }

    const viewSpentTimeProcess = (viewStart, token_id) => {
        const viewEnd = new Date().getTime()
        const spentTime = (viewEnd - viewStart) / 1000

        NFTView(userData.id, token_id, spentTime)
    }

    return {
        NFTView,
        userSignUp,
        userSignIn,
        NFTListing,
        updateWallet,
        NFTSaleBazaar,
        NFTPurchaseShop,
        web2ItemPurchase,
        NFTConversionStart,
        viewSpentTimeProcess,
        NFTConversionComplete,
        voolahPurchaseComplete,
        setDataLayerDefaultData
    }
}