import React from "react";
import {png} from "assets/png";
import Icon from "components/atoms/Icon";
import Title from "components/atoms/TitleTemplate";

import "./style.scss"
import Image from "components/atoms/Image";

const DeckAudience = () => {

    return (
        <section className="deck_audience">
            <Title underline={true}>The <span>Audience</span></Title>
            <div className="deck_audience_content container">
                <div className="deck_audience_content_diagram">
                    <div className="deck_audience_content_diagram_img">
                        <Image alt="diagram" src={png.GlobalPlayersDiagram}/>
                        <p className="deck_audience_content_diagram_img_source">source: newzoo.com/globalgamesreport</p>
                    </div>
                    <div className="deck_audience_content_diagram_info">
                        <h4 className="deck_audience_content_diagram_info_title">2015-2023 global players</h4>
                        <span className="deck_audience_content_diagram_info_underTitle">Forecast Toward 2023</span>
                        <div className="deck_audience_content_diagram_info_img">
                            <Image alt="diagram" src={png.GlobalPlayersDiagram}/>
                            <p className="deck_audience_content_diagram_img_source">source: newzoo.com/globalgamesreport</p>
                        </div>
                        <p>Bros aged 10-40 who primarly only like to play multiplayer games on console and play them “with the
                            boys”. </p>
                        <p>Likes to play 2-5 games in rotation throughout the year. Plays multiplayer games like Fortnite, PubG,
                            Warzone, NBA2K, Fall Guys, PGA2K, GTA Online, Rocket League, FIFA, Apex Legends, & more.</p>
                    </div>
                </div>
                <div className="deck_audience_content_players-details">
                    <PlayersItem icon="phone" type="Mobile" count="2.5"/>
                    <PlayersItem icon="gamePad" type="Console" count="0.8"/>
                    <PlayersItem icon="display" type="PC" count="1.3"/>
                    <div className="deck_audience_content_players-details_total-count">
                        <div className="deck_audience_content_players-details_total-count_block">
                            <div>
                                <div>
                                    <Icon name="increase"/>
                                </div>
                                <div>
                                    <span>+5.6%</span>
                                </div>
                            </div>
                            <div>
                                <h4>3.07 billion</h4>
                                <div>
                                    <div>
                                        <Icon name="increase"/>
                                    </div>
                                    <div>
                                        <span>+5.6%</span>
                                    </div>
                                </div>
                                <span>Total players cagr 2015-2023</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default DeckAudience

const PlayersItem = ({icon, count, type}) => {
    return (
        <div className="deck_audience_content_players-details_count-item">
            <div className="deck_audience_content_players-details_count-item_block">
                <div>
                    <Icon name={icon}/>
                </div>
                <div>
                    <span>{count} Billion</span>
                    <span>{type} players in 2020</span>
                </div>
            </div>
        </div>
    )
}