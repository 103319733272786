import React from "react";
import classNames from "classnames";
import Icon from "components/atoms/Icon";
import "./style.scss"

const NetworkButton = ({link, network, size,icon,description}) => {
    const buttonType = classNames("network-button", network, size)

    return (
        <a
            href={link}
            rel="noreferrer"
            target="_blank"
            className={buttonType}
            aria-label={description}
        >
            <Icon name={icon}/>
        </a>
    )
}

export default NetworkButton