import {Navigate, useLocation, useParams} from "react-router-dom";
import React from "react";

import {LockerCurrentWeb2CosmeticSection} from "components/organisms/Locker";
import {RecentlyViewedItemsSection} from "components/molecules";
import ProtectedPage from "components/molecules/ProtectedPage";
import {ModalsProvider, DefaultLayout} from "layouts";

const LockerCurrentWeb2Cosmetic = () => {
    const {state} = useLocation()
    const {sku} = useParams()

    if (!state?.request) {
        return <Navigate to={"/locker"}/>
    }

    return (
        <DefaultLayout>
            <ProtectedPage>
                <ModalsProvider>
                    <LockerCurrentWeb2CosmeticSection sku={sku}/>
                    <RecentlyViewedItemsSection/>
                </ModalsProvider>
            </ProtectedPage>
        </DefaultLayout>
    )
}

export default LockerCurrentWeb2Cosmetic