import React from "react";

import Title from "components/atoms/TitleTemplate";
import ReactPlayer from "components/atoms/ReactPlayer";
import {useWindowSize} from "hooks";
import "./style.scss"

const HomeLetsKompete = () => {
    const {width} = useWindowSize()

    return (
        <section className="home_lets-kompete container">
            <Title underline={true}>Let's <span>kompete</span></Title>
            <div className="home_lets-kompete_content">
                <div className="home_lets-kompete_content_text first-block-content">
                    <h2>We are <span>built different.</span></h2>
                    <p>Create your KOMPETITOR, lock in your playstyle, and drop into some of the best multiplayer game
                        modes of all time.</p>
                    <p>You might never have to play another multiplayer game again!</p>
                    <p>Free-to-play. Cross-platform. Cross-progression. It's time to KOMPETE!!!</p>
                </div>
                <div className="home_lets-kompete_content_img second-block img_right">
                    <div className="video-block">
                        {
                            width > 991
                                ? <ReactPlayer
                                    src="https://player.vimeo.com/video/894403641?h=3a75b39898&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&muted=1&autoplay=1&loop=1&controls=0"
                                    title="VSL_02_4K.mp4"/>
                                : <ReactPlayer
                                    src="https://player.vimeo.com/video/894403641?h=3a75b39898&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&muted=1&autoplay=1&quality=540p&loop=1&controls=0"
                                    title="VSL_02_4K.mp4"/>
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HomeLetsKompete