import React from "react";
import {useWindowSize} from "hooks";
import classNames from "classnames";

import Title from "components/atoms/TitleTemplate";
import {ReactPlayer} from "components/atoms";

import "./style.scss"

const HomeCurrentGameMode = () => {
    const {width} = useWindowSize()

    return (
        <section className="home_current-game-mode">
            <Title underline={true}>Multiple fleshed out game modes<span> all under 1 roof</span></Title>
            <div className="home_current-game-mode_content container">
                <div className="home_current-game-mode_content_one">
                    <div className="second-block img_left">
                        <h2>Blitz Royale</h2>
                        <Video textGameplay="Early Access" position={width > 991 ? "left" : "right"}>
                            {width > 991
                                ? <ReactPlayer
                                    src="https://player.vimeo.com/video/1022732509?h=dd2d11d892&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&muted=1&autoplay=1&loop=1&controls=0"
                                    title="Website-BR"/>
                                : <ReactPlayer
                                    src="https://player.vimeo.com/video/1022732509?h=dd2d11d892&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&muted=1&autoplay=1&quality=540p&loop=1&controls=0"
                                    title="VSL_BR_4K.mp4"
                                />}
                        </Video>
                    </div>
                    <div className="first-block-content">
                        <h2>Blitz Royale</h2>
                        <p>A fast-paced battle royale with respawns and exosuits. In less than 10 minutes, the last team
                            standing wins. Enjoy constant action, powerful loot, and redemption opportunities.
                            Strategically spawn items with your drone. #NextGenBR</p>
                    </div>
                </div>
                <div className="home_current-game-mode_content_two">
                    <div className="first-block-content">
                        <h2>Kart Race</h2>
                        <p>Rev up for an action-packed kart race. The first player across the finish line wins. Utilize
                            weapons and utility to get an edge, zoom past rivals with nitro boosts, and master your
                            handbrake for those tight corners. Don’t forget to dodge the hazards! #Skkkrrrrrrt</p>
                    </div>
                    <div className="second-block img_right">
                        <h2>Kart Race</h2>
                        <Video textGameplay="Early Access" position="right">
                            {width > 991
                                ? <ReactPlayer
                                    src="https://player.vimeo.com/video/1022732069?h=9a49efc268&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&muted=1&autoplay=1&loop=1&controls=0"
                                    title="Website-kartrace"
                                />
                                : <ReactPlayer
                                    src="https://player.vimeo.com/video/1022732069?h=9a49efc268&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&muted=1&autoplay=1&quality=540p&loop=1&controls=0"
                                    title="Website-kartrace"
                                />}
                        </Video>
                    </div>
                </div>
                <div className="home_current-game-mode_content_three">
                    <div className="second-block img_left">
                        <h2>Social Deduction</h2>
                        <Video textGameplay="Early Access" position={width > 991 ? "left" : "right"}>
                            {width > 991
                                ? <ReactPlayer
                                    src="https://player.vimeo.com/video/1022731517?h=8da43713e8&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&muted=1&autoplay=1&loop=1&controls=0"
                                    title="Website-SocialDeduction"
                                />
                                : <ReactPlayer
                                    src="https://player.vimeo.com/video/1022731517?h=8da43713e8&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&muted=1&autoplay=1&quality=540p&loop=1&controls=0"
                                    title="Website-SocialDeduction"
                                />}
                        </Video>
                    </div>
                    <div className="first-block-content">
                        <h2>Social Deduction</h2>
                        <p>Embark on an exhilarating mystery armed with weapons. Each player takes on the role of Thug,
                            Guard, or Civilian. The Thug aims to eliminate everyone. The Guard protects the Civilians
                            from the Thug using a weapon. The Civilians work with the Guard to expose the Thug.
                            #WhoDidIt</p>
                    </div>
                </div>
                <div className="home_current-game-mode_content_four">
                    <div className="first-block-content">
                        <h2>Basketball</h2>
                        <p>Simulation 3-on-3 and 5-on-5 basketball. Showcase your dribbling skills, break down
                            opponents, throw down dunks, perfectly time your jump shots, and clamp up on defense.
                            #FreeBasketball</p>
                    </div>
                    <div className="second-block img_right">
                        <h2>Basketball</h2>
                        <Video textGameplay="In Development" position="right">
                            {width > 991
                                ? <ReactPlayer
                                    src="https://player.vimeo.com/video/1023013603?h=fe0fc0534d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&muted=1&autoplay=1&loop=1&controls=0"
                                    title="Website_Basketball"
                                />
                                : <ReactPlayer
                                    src="https://player.vimeo.com/video/1023013603?h=fe0fc0534d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&muted=1&autoplay=1&quality=540p&loop=1&controls=0"
                                    title="Website_Basketball"
                                />}
                        </Video>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default HomeCurrentGameMode

export const Video = ({position, textGameplay, children}) => {
    const videoStyle = classNames("gameplay", position)
    const videBlock = classNames("video-block_video", position)
    const vectorStyle = classNames("gameplay_vector", position)

    return (
        <div className="video-block">
            <div className={videBlock}>{children}</div>
            <div className={videoStyle}>{textGameplay}</div>
            <div className={vectorStyle}/>
        </div>
    )
}