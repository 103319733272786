import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {limitedShopService} from "./limitedShopService";

const initialState = {
    cosmeticPayment: {
        loading: false,
        success: false,
        error: false,
        errorMessage: null,
        data: {}
    }
}

export const payForInventoryWithVoolah = createAsyncThunk("pay/for/inventory/web2", async (data, thunkAPI) => {
    try {
        return limitedShopService.payForInventoryWithVoolah(data, thunkAPI)
    } catch {
        return thunkAPI.rejectWithValue("Something went wrong")
    }
})

export const saveVoolahSpentHistory = createAsyncThunk("save/voolah/spent/history", async (data, thunkAPI) => {
    try {
        return limitedShopService.saveVoolahSpentHistory(data, thunkAPI)
    } catch {
        return thunkAPI.rejectWithValue("Something went wrong")
    }
})

const limitedShopSlice = createSlice({
    name: "limitedShop",
    initialState,
    reducers: {
        limitedReset: (state, data) => {
            const {section} = data.payload

            state[section].loading = false
            state[section].error = false
            state[section].success = false
            state[section].errorMessage = null
            state[section].data = {}
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(payForInventoryWithVoolah.pending, (state) => {
                state.cosmeticPayment.loading = true
            })
            .addCase(payForInventoryWithVoolah.fulfilled, (state, action) => {
                state.cosmeticPayment.loading = false
                state.cosmeticPayment.success = true
                state.cosmeticPayment.error = false
                state.cosmeticPayment.data = action.payload
            })
            .addCase(payForInventoryWithVoolah.rejected, (state, action) => {
                state.cosmeticPayment.loading = false
                state.cosmeticPayment.success = false
                state.cosmeticPayment.error = true
                state.cosmeticPayment.errorMessage = action.payload
            })
    }
})

export const {limitedReset} = limitedShopSlice.actions
export default limitedShopSlice.reducer