import React, {memo, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import classNames from "classnames";

import {getUserCurrentListedCosmetic, reset} from "store/Locker/lockerSlice";
import {useAuthorization} from "hooks";

import BazaarCurrentCosmeticOwnerBlock from "./BazaarCurrentCosmeticOwnerBlock";
import BazaarCurrentCosmeticUserBlock from "./BazaarCurrentCosmeticUserBlock";
import CosmeticViewTemplate from "components/molecules/CosmeticViewTemplate";

import "./style.scss"

const BazaarCurrentAlreadyListedWeb3CosmeticSection = ({sku, orderId, data, isLoading, isSuccess}) => {
    const {accessToken} = useAuthorization()

    const {currentListedCosmetic} = useSelector(state => state.locker)
    const {userData} = useSelector(state => state.authV2.signIn)

    const [isOwner, setIsOwner] = useState({process: "checking", status: false})

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const currentCosmeticStyle = classNames("bazaar-current-cosmetic-section", data?.rarity ?? "ordinary")

    useEffect(() => {
        dispatch(getUserCurrentListedCosmetic({order_id: orderId, sku}))

        return () => dispatch(reset({section: "currentListedCosmetic"}))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderId])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => navigateAndResetStore(), [currentListedCosmetic.error])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => ownerChecking(), [userData, currentListedCosmetic.data])

    const navigateAndResetStore = () => {
        if (currentListedCosmetic.error) {
            navigate("/")
            dispatch(reset({section: "listCurrentCosmetic"}))
        }
    }

    const ownerChecking = () => {
        if (userData?.id && currentListedCosmetic.data) {
            const loggedUserWallet = userData.wallet?.toLowerCase()
            const orderMakerWallet = currentListedCosmetic.data.order.maker.toLowerCase()

            if (loggedUserWallet === orderMakerWallet) {
                setIsOwner({process: "checked", status: true})
            } else {
                setIsOwner({process: "checked", status: false})
            }
        }

        if (!accessToken) {
            setIsOwner({process: "checked", status: false})
        }
    }

    const displayBlock = currentListedCosmetic.success && isSuccess && isOwner.process === "checked";
    const loadingStatus = isLoading || currentListedCosmetic.loading;

    return (
        <section className={currentCosmeticStyle}>
            <CosmeticViewTemplate
                isShare={true}
                title={data?.name}
                img={data?.image_url}
                rarity={data?.rarity}
                chain={data?.chain_id}
                attribute={data?.groups}
                loading={currentListedCosmetic.loading || isLoading}
                classname="bazaar-current-cosmetic-section_content container"
                isNeverSoldAgain={currentListedCosmetic.data?.inventory.never_sold_again}
            >
                {displayBlock &&
                    (isOwner.status
                            ? <BazaarCurrentCosmeticOwnerBlock
                                inventory={data}
                                isSuccess={displayBlock}
                                isLoading={loadingStatus}
                                data={currentListedCosmetic.data}
                            />
                            : <BazaarCurrentCosmeticUserBlock
                                inventory={data}
                                isSuccess={displayBlock}
                                isLoading={loadingStatus}
                                data={currentListedCosmetic.data}
                            />
                    )
                }
            </CosmeticViewTemplate>
        </section>
    )
}

export default memo(BazaarCurrentAlreadyListedWeb3CosmeticSection);