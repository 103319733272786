import React from "react"
import {useSelector} from "react-redux";

import {useAuthorization} from "hooks";

import {FooterModernize, LoadSpinner} from "components/atoms";
import KompeteToke from "./KompeteToke";
import SignIn from "./SignIn";

const ProtectedPage = ({checkBalance = false, children}) => {
    const {success, userData} = useSelector(state => state.authV2.signIn)
    const {isAuth, accessToken} = useAuthorization()

    return (
        <React.Fragment>
            {
                checkBalance ?
                    isAuth ?
                        success ?
                            userData.kompeteBalance !== null ?
                                userData.kompeteBalance >= 0.5 ?
                                    children
                                    :
                                    <div className="protected-container">
                                        <KompeteToke/>
                                        <FooterModernize/>
                                    </div>
                                :
                                <div className="spinner-container"><LoadSpinner/></div>
                            :
                            <div className="spinner-container"><LoadSpinner/></div>
                        :
                        <div className="protected-container">
                            <KompeteToke/>
                            <FooterModernize/>
                        </div>
                    :
                    accessToken ?
                        success && accessToken ? children : <div className="spinner-container"><LoadSpinner/></div>
                        :
                        <div className="protected-container"><SignIn/></div>
            }
        </React.Fragment>
    )
}
export default ProtectedPage
