import React, {memo, useState} from "react";
import classNames from "classnames";

import Icon from "../Icon";

import "./style.scss"

const Refresh = ({refreshCallBack}) => {
    const [isRefresh, setIsRefresh] = useState(false)

    const refreshStyle = classNames("refresh-tool", {"refreshed": isRefresh})

    const refresh = () => {
        setIsRefresh(!isRefresh)
        refreshCallBack()
    }

    return (
        <div onClick={() => refresh()} className={refreshStyle}>
            <Icon name="refresh"/>
        </div>
    )
}

export default memo(Refresh)