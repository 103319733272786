import React from "react";
import {Blocked} from "components/atoms";

const LockerConvertSelectorBlocked = ({text}) => {
    return (
        <div className="locker_cosmetics_content_general_konvert_selector_blocked">
            <Blocked text={text}/>
        </div>
    )
}
export default LockerConvertSelectorBlocked