export const timePastCalculation = (time) => {
    const currentTime = new Date()
    const createdTime = new Date(time)
    const timeDifference = currentTime - createdTime

    const seconds = Math.floor(timeDifference / 1000)
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const month = Math.floor(days / 30)
    const year = Math.floor(month / 12)

    return {
        second: {value: seconds, name: "second"},
        minute: {value: minutes, name: "minute"},
        hour: {value: hours, name: "hour"},
        day: {value: days, name: "day"},
        month: {value: month, name: "month"},
        year: {value: year, name: "year"}
    }
}