import React, {useState} from "react";

import Icon from "components/atoms/Icon";
import "./style.scss"

const DropdownItem = ({title, children, open = false}) => {
    const [isOpen, setIsOpen] = useState(open)
    return (
        <div className={`dropdown ${isOpen ? "active" : ""}`}>
            <div className="dropdown_head" onClick={() => setIsOpen(!isOpen)}>
                <div className={`dropdown_head_title ${isOpen && "active"}`}>
                    <h4>{title}</h4>
                </div>
                <div className="dropdown_head_btn">
                    <Icon name="select"/>
                </div>
            </div>
            <div className={`dropdown_content ${isOpen && "active"}`}>
                {children}
            </div>
        </div>
    )
}

export default DropdownItem