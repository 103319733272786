import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import classNames from "classnames";

import {formatNumberToEuropeanStyle, LG_SLOTS, LIMITED_SLOTS_DEFAULT_IMG, MD_SLOTS} from "utils";
import {useGetActiveLimitedShopQuery} from "store/LimitedShop/limitedShop.api";

import LimitedTimeCurrentCosmeticMain from "./LimitedTimeCurrentCosmetic/LimitedTimeCurrentCosmeticMain";
import LimitedTimeCountDown from "./LimitedTimeCountDown";
import LimitedShopLoading from "./LimitedShopLoading";
import LimitedShopClose from "./LimitedShopClose";
import {LimitedCard} from "components/molecules";

import "./style.scss"

const LimitedTimeShop = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const {data: shop, isLoading, isSuccess} = useGetActiveLimitedShopQuery()

    const [limitedShopData, setLimitedShopData] = useState(null)

    const shopLimitedClass = classNames("shop-limited", {close: !isLoading && shop !== undefined && shop === null})

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => checkSearchParams(), [searchParams])

    const checkSearchParams = () => {
        const isSkuAvailable = searchParams.get("sku")

        if (!isSkuAvailable) setLimitedShopData(null)
    }

    const chooseInventory = (inventory) => {
        window.scrollTo(0,0)
        searchParams.set("sku", inventory.sku)
        setSearchParams(searchParams)

        setLimitedShopData({inventory, expiration_date: shop.expiration_date})
    }

    return (
        <React.Fragment>
            {!limitedShopData ?
                <section className={shopLimitedClass}>
                    {isLoading ? <LimitedShopLoading/> : shop !== null && shop !== undefined ?
                        <div className="shop-limited_content container">
                            <LimitedTimeCountDown
                                isLoading={isLoading}
                                isSuccess={isSuccess && shop}
                                targetDateTime={isSuccess && shop ? shop.expiration_date : 0}
                            />
                            <div className="shop-limited_content_cosmetics">
                                <div className="shop-limited_content_cosmetics_big">
                                    {React.Children.toArray(
                                        LG_SLOTS.map((slot) =>
                                            <LimitedCartBlock
                                                size="lg"
                                                slot={slot}
                                                data={shop}
                                                isSuccess={isSuccess}
                                                currentInventoryCallBack={(inventory) => chooseInventory(inventory)}
                                            />
                                        )
                                    )}
                                </div>
                                <div className="shop-limited_content_cosmetics_small">
                                    {React.Children.toArray(
                                        MD_SLOTS.map((slot) =>
                                            <LimitedCartBlock
                                                size="md"
                                                slot={slot}
                                                data={shop}
                                                isSuccess={isSuccess}
                                                currentInventoryCallBack={(inventory) => chooseInventory(inventory)}
                                            />
                                        )
                                    )}
                                </div>
                            </div>
                        </div> : <LimitedShopClose/>}
                </section>
                : <LimitedTimeCurrentCosmeticMain shopData={limitedShopData}/>
            }
        </React.Fragment>
    )
}

export default LimitedTimeShop

const LimitedCartBlock = ({size, slot, data, isLoading, isSuccess, currentInventoryCallBack}) => {

    const findSlotData = () => {
        if (data) {
            const {queue_items} = data

            return queue_items.find((item) => item.slot === slot)
        }

        return null
    }

    return (
        <LimitedCard
            size={size}
            isLoading={isLoading}
            name={findSlotData()?.name}
            isSuccess={isSuccess && data}
            rarity={findSlotData()?.rarity}
            isNeverSold={findSlotData()?.never_sold_again}
            cartClickCallBack={() => currentInventoryCallBack(findSlotData())}
            image={findSlotData()?.image_url ?? LIMITED_SLOTS_DEFAULT_IMG[slot]}
            voolah={formatNumberToEuropeanStyle(+findSlotData()?.voolah_price ?? 0)}
        />
    )
}

