import React from "react";
import Image from "components/atoms/Image";
import {png} from "assets/png";
import {Link} from "react-router-dom";
import "./style.scss"

const FooterModernize = () => {
    return (
        <div className="footer-modernize">
            <div className="footer-modernize_block">
                <div className="footer-modernize_block_logo">
                    <Image
                        onClick={() => window.open("https://modernizegames.com", "_blank")}
                        src={png.LogoModernizeGame}
                        alt="logo modernize"
                    />
                </div>
                <div className="footer-modernize_block_reserved">
                    <p>© 2022 Modernize Games, KOMPETE and any other logos or trademarks of Modernize Games. All
                        Rights
                        Reserved.</p>
                </div>
                <div className="footer-modernize_block_term-links">
                    <ul>
                        <li><Link to="/privacy">privacy</Link></li>
                        <li><Link to="/terms">terms</Link></li>
                        <li><a href="#" target="_blank" rel="noreferrer">cookies</a></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
export default FooterModernize