const getAuthHeader = (requiresAuthorization,token) => {
    if (requiresAuthorization) {
        const bearerToken = localStorage.getItem('accessToken');
        return `Bearer ${token ?? bearerToken}`;
    }
    return null;
}

export const createXsollaFetch = async ({baseUrl, endpoint, method = 'GET', requiresAuthorization, body,token}) => {
    try {
        const headers = {'Content-Type': 'application/json'};
        const authHeader =  getAuthHeader(requiresAuthorization,token);

        if (authHeader) {
            headers['Authorization'] = authHeader;
        }

        const response = await fetch(`${baseUrl}/${endpoint}`, {
            method,
            body: body ? JSON.stringify(body) : undefined,
            headers
        });
        let data = null, error = null;
        if (response.ok) {
            data = await response.json();
        } else {
            error = {
                status: response.status,
                data: await response.json(),
            };
        }
        return {data, error};
    } catch (error) {
        return {
            data: null,
            error: {status: 'fetch error', data: error.message}
        };
    }
}