import React from "react";
import {useSelector} from "react-redux";

import {useGlobalModalsContext} from "layouts/context/GlobalModalsProvider";
import {AUTH_NETWORK, GLOBAL_MODALS} from "utils";

import {Icon} from "components/atoms";

const CreateAccount = () => {
    const {setModal} = useGlobalModalsContext()
    const {loading: signInLoading} = useSelector(state => state.authV2.signIn)

    return (
        <div className="sign-in-modal_create-account">
            <p>Don’t have an account?</p>
            <CreateAccountBtn
                disabled={signInLoading}
                network={AUTH_NETWORK.EMAIL}
                text="Create account with email"
                onClick={() => setModal(GLOBAL_MODALS.CREATE_ACCOUNT)}
            />
        </div>
    )
}

export default CreateAccount

const CreateAccountBtn = ({text, onClick, disabled}) => (
    <button disabled={disabled} onClick={onClick} className="sign-in-modal_create-account_btn">
        <div className="sign-in-modal_create-account_btn_img"/>
        <div className="sign-in-modal_create-account_btn_text">
            <span>{text}</span>
            <Icon name="selectArrow"/>
        </div>
    </button>
)