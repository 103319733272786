import React from "react";
import {useNavigate} from "react-router-dom"

import Title from "components/atoms/TitleTemplate";
import Button from "components/atoms/Button";
import Image from "components/atoms/Image";

import {png} from "assets/png";
import "./style.scss"

const DeckDownload = () => {
    const navigate = useNavigate()

    return (
        <section className="deck_download">
            <Title><span>Launching q1 2023</span><br/>closed beta access out now</Title>
            <Button
                view="secondary"
                icon="pc"
                iconDirection="left"
                path={true}
                onClick={() => navigate("/download")}
            >
                Download now
            </Button>
            <h4>Coming soon</h4>
            <div className="deck_download_content container">
                <LaunchingItem text="xbox" src={png.XboxPure}/>
                <LaunchingItem text="playstation" src={png.PlayStationPure}/>
                <LaunchingItem text="steam" src={png.EpicGamePure}/>
                <LaunchingItem text="app store" src={png.AppStorePure}/>
                <LaunchingItem text="google play" src={png.GooglePlayPure}/>
            </div>
        </section>
    )
}

export default DeckDownload

const LaunchingItem = ({text, src}) => {
    return (
        <div className="deck_download_content_item">
            <div className="deck_launching_content_item_img-block">
                <Image alt="text" src={src}/>
            </div>
            <h5>{text}</h5>
        </div>
    )
}
