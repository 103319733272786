import React, {memo} from "react"
import classNames from "classnames";

import FollowUsFooter from "components/atoms/FollowUsFooter";
import FooterModernize from "components/atoms/ModernizeFooter";

import "./style.scss"

const Footer = ({isFollowUsShow, isFollowUsBack}) => {

    const footerStyle = classNames("footer", {"background": isFollowUsShow}, {"background": isFollowUsBack})

    return (
        <footer className={footerStyle}>
            {isFollowUsShow && <FollowUsFooter/>}
            <FooterModernize/>
        </footer>
    )
}

export default memo(Footer)