import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";

import {useFixScrollBar} from "hooks";
import {SIZE} from "utils";

import {Icon} from "components/atoms";

import "./style.scss";

const Modal = ({isOpen, title, onClose, children, size = SIZE.LARGE}) => {
    const modalClass = classNames("modal-content", `modal-${size}`, `modal-${onClose ? "close" : "without-close"}`);

    useFixScrollBar(isOpen)

    return ReactDOM.createPortal(
        <div className="modal-overlay">
            <div className={modalClass}>
                {onClose && <button onClick={onClose}>
                    <Icon name="close"/>
                </button>}
                {title && <h2>{title}</h2>}
                {children}
            </div>
        </div>,
        document.querySelector("body")
    );
};

export default Modal;