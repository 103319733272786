import React from "react";
import classNames from "classnames";

import {Icon} from "components/atoms";

import "./style.scss";

const ButtonLink = ({view, size, icon, iconDirection, path, disabled, href, children, isTarget}) => {
    const buttonStyle = classNames("buttonV3", view ?? "secondary", size ?? "lg", {path: path});
    const iconStyle = classNames("buttonV3_icon", iconDirection ?? "");

    return (
        <a rel="noreferrer" href={href} disabled={disabled} className={buttonStyle} target={isTarget ? "_blank" : ""}>
            {icon && <div className={iconStyle}><Icon name={icon}/></div>}
            <div>{children}</div>
        </a>
    );
};

export default ButtonLink;
