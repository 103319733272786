import React from "react";
import {useNavigate} from "react-router-dom"

import ProgressiveImage from "components/atoms/ProgressiveImage";
import Button from "components/atoms/Button";

import {png} from "assets/png";
import Image from "components/atoms/Image";
import "./style.scss"

const MainDeck = () => {
    const navigate = useNavigate()

    return (
        <section className="deck_main">
            <div className="black-gradient-filter"/>
            <ProgressiveImage
                imgSrc={png.Hero3}
                previewSrc={png.Hero3Resized}
                className="deck_main_back-img"
            />
            <div className="deck_main_content">
                <div>
                    <Image alt="kompete" src={png.KOMPETEAccess} className="deck_main_content_img"/>
                </div>
                <div>
                    <h1>A TEAM-BASED <br/> MULTIPLAYER GAME</h1>
                    <Button view="secondary" onClick={() => navigate("/download")}>Download now</Button>
                </div>
            </div>
        </section>
    )
}

export default MainDeck