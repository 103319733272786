import {useNavigate} from "react-router-dom";
import React from "react";

import {useModalsContext} from "layouts";

import CosmeticSuccessModalTemplate from "components/molecules/CosmeticModalTemplate";

const BazaarCurrentAlreadyListedWeb3CosmeticSuccessModal = ({setCurrentModal, title}) => {
    const {currentModal} = useModalsContext()
    const navigate = useNavigate()

    const {name, rarity, image_url} = currentModal.data

    return (
        <CosmeticSuccessModalTemplate
            show={true}
            name={name}
            quantity="1"
            title={title}
            rarity={rarity}
            image={image_url}
            isShowClose={true}
            closeClick={() => setCurrentModal()}
            buttonOne={{text: "go to locker", callBack: () => navigate("/locker")}}
        />
    )
}

export default BazaarCurrentAlreadyListedWeb3CosmeticSuccessModal