import React from "react";
import classNames from "classnames";

import {Icon} from "components/atoms";

import "./style.scss"

const DeviceCard = ({device, icon, link, isTarget = false, size}) => {
    const cartStyle = classNames("device-card", icon, size ?? "sm")

    return (
        <div className={cartStyle}>
            <a rel="noreferrer" href={link} target={isTarget ? "_blank" : ""}>
                <div className="device-card_icon-block">
                    <Icon name={icon}/>
                </div>
                <span className="device-card_device">{device}</span>
            </a>
        </div>
    )
}

export default DeviceCard