import {errorHandler} from "../utils";

export const createFetchBaseServerQuery = ({baseUrl, isAuthorization, headers}) => async ({url, method, body}) => {
    try {
        if (isAuthorization) {
            const jwt = localStorage.getItem('accessToken');
            body = {...body, jwt}
        }

        const response = await fetch(`${baseUrl}/${url}`, {
            method,
            body: body ? JSON.stringify(body) : undefined,
            headers
        });

        if (!response.ok) {
            const error = await response.json();
            errorHandler(error?.errors?.[0] || error?.message || "Something went wrong");
        }

        const data = await response.json()

        return {data, error: null};
    } catch (fetchError) {
        let error = {status: 'fetch error', data: fetchError.message};
        return {data: null, error};
    }
}

export default createFetchBaseServerQuery;

