import React from "react";
import classNames from "classnames";
import {useCountdownTimer} from "hooks";

const LimitedTimeCountDown = ({targetDateTime, isLoading}) => {
    const {countdown} = useCountdownTimer(targetDateTime, true)
    const timeStyle = classNames("shop-limited_content_countDown", {"skeleton": isLoading})

    if (
        targetDateTime && countdown?.days === "0" &&
        countdown?.hours === "00" &&
        countdown?.minutes === "00" &&
        countdown?.seconds === "00"
    ) window.location.reload()

    const renderTimeItemBlock = (unit, label) => {
        return (
            unit !== "00" && (
                <TimeItemBlock
                    isComma={true}
                    countdown={unit}
                    time={`${label}${unit > 1 ? "s" : ""}`}
                />
            )
        );
    };

    return (
        <div className={timeStyle}>
            <div className="shop-limited_content_countDown_block">
                {!isLoading && countdown &&
                    <React.Fragment>
                        <h4 className="shop-limited_content_countDown_block_open">
                            <div><span> Time left: </span></div>
                            {renderTimeItemBlock(countdown.days, "day")}
                            {renderTimeItemBlock(countdown.hours, "hour")}
                            {renderTimeItemBlock(countdown.minutes, "minute")}
                            <TimeItemBlock
                                isComma={false}
                                countdown={countdown.seconds}
                                time={`second${countdown.seconds > 1 ? "s" : ""}`}
                            />
                        </h4>
                    </React.Fragment>}
            </div>
        </div>
    )
}

export default LimitedTimeCountDown

const TimeItemBlock = ({countdown, time, isComma}) => (
    <div>
        <span className="time"> {countdown} </span>
        <span> {time} {isComma ? "," : ""}</span>
    </div>
)