import React from "react";
import {useNavigate} from "react-router-dom";

import {useModalsContext} from "layouts";
import {STEPS_STATUS} from "utils";

import CosmeticSuccessModalTemplate from "components/molecules/CosmeticModalTemplate";

const FreeItemClaimSuccessModal = ({show}) => {
    const {currentModal, setCurrentModal} = useModalsContext()
    const navigate = useNavigate()
    const {inventory} = currentModal.data

    return (
        <CosmeticSuccessModalTemplate
            show={show}
            quantity={1}
            isShowClose={true}
            name={inventory.name}
            rarity={inventory.rarity}
            image={inventory.image_url}
            title="Claim successful!"
            closeClick={() => setCurrentModal({status: STEPS_STATUS.CLOSE})}
            buttonOne={{text: "Download game", callBack: () => navigate("/download")}}
            buttonTwo={{text: "Go to locker", callBack: () => navigate("/locker")}}
        />
    )
}

export default FreeItemClaimSuccessModal