import React from "react";
import classNames from "classnames";
import {useSelector} from "react-redux";

import {checkinDecimalAndToFixed} from "utils";

const LimitedTimeCurrentCosmeticEthPrice = ({voolahPrice, quantity, className}) => {
    const {ethPrice} = useSelector(state => state.global)

    const oneVoolahPrice = 0.01
    const cosmeticEthPrice = voolahPrice ?
        (((quantity * (parseFloat(voolahPrice.toString().replace(/,/g, '')) * oneVoolahPrice)) / ethPrice) * 1000000) / 1000000 : ""

    const priceStyle = classNames(className, {"skeleton": !ethPrice})

    return <span className={priceStyle}>{ethPrice ? checkinDecimalAndToFixed(cosmeticEthPrice, 4) : ""}</span>
}

export default LimitedTimeCurrentCosmeticEthPrice