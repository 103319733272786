import React from "react";
import {png} from "assets/png";
import Title from "components/atoms/TitleTemplate";
import Image from "components/atoms/Image";
import "./style.scss"

const TokenNine = () => {
    return (
        <section className="token-nine">
            <div className="container">
                <Title underline={true}>Financial <span>Disclosure</span></Title>
                <div className="book-financial">
                    <div className="book-financial_left">
                        <h3>There is <span>no <br/>expectation of profit</span></h3>
                        <p>You should not expect to profit from purchasing and holding KOMPETE Token. KOMPETE Token is a
                            utility token within the KOMPETE game economy. KOMPETE Token is not an investment or
                            security, so don’t view it as such. </p>
                    </div>
                    <div className="book-financial_right">
                        <Image className="c1" alt="coin" src={png.C1}/>
                    </div>
                    <Image className="c2" alt="coin" src={png.C2}/>
                    <Image className="c5" alt="coin" src={png.C5}/>
                    <Image className="c4" alt="coin" src={png.C4}/>
                </div>
            </div>
        </section>
    )
}
export default TokenNine