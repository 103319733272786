import React from "react";
import {Icon} from "components/atoms";

const TablePriceBlock = ({currency, price}) => (
    <div className="price-block">
        <div className="price-block_icon"><Icon name={currency}/></div>
        <div className="price-block_value"><span>{price}</span></div>
    </div>
)

export default TablePriceBlock