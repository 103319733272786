import React from "react";
import UnderLine from "components/atoms/UnderLine";

const DeckOwnerMessage = () => {
    return (
        <section className="deck_owner-message">
            <div className="deck_owner-message_text">
                <UnderLine/>
                <p className="deck_owner-message_text_mes">
                    “I’m tired of playing 7 different games and most of them have massive barriers to entry that prevent my friends and
                    I from playing together.”
                </p>
                <p className="deck_owner-message_text_owner">-Justin Meister</p>
                <UnderLine/>
            </div>
        </section>
    )
}
export default DeckOwnerMessage