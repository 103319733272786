import {ENVIRONMENTS} from "../utils";

const URL = {
    ONE: process.env.REACT_APP_HOST,
    TWO: process.env.REACT_APP_MARKET_SERVER_HOST,
    THREE: ENVIRONMENTS.XSOLLA_STORE_TARGET_SERVER,
}

export const createFetch = async (base, path, method = "GET", options = {}, data = {}) => {
    const url = `${!base ? URL.ONE : URL[base]}/${path}`

    const headers = {
        ...options.headers
    }

    const fetchOptions = {
        method,
        headers,
    };

    if (options.authorization && localStorage.getItem("accessToken")) {
        data.jwt = localStorage.getItem("accessToken")
    }

    if (method !== "GET") {
        fetchOptions.body = JSON.stringify(data);
    }

    const response = await fetch(url, fetchOptions);

    if (!response.ok) {
        const error = await response.json();
        throw new Error(error?.errors?.[0] || error?.message || "Something went wrong");
    }

    return response.json();
}