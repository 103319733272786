import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {globalService} from "./globalService";

const initialState = {
    backgroundImgClass: "",
    ethPrice: 0
}

export const getEthPrice = createAsyncThunk("get/eth/price", async (_, thunkAPI) => {
    try {
        return await globalService.getEthPrice()
    } catch {
        return thunkAPI.rejectWithValue("Something went wrong")
    }
})

const globalSlice = createSlice({
    name: "global",
    initialState,
    reducers: {
        setBackgroundClass: (state, action) => {
            state.backgroundImgClass = action.payload
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getEthPrice.fulfilled, (state, action) => {
                state.ethPrice = action.payload
            })
    }
})

export const {setBackgroundClass, setEthPrice} = globalSlice.actions
export default globalSlice.reducer