import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import classNames from "classnames";

import {getUserEligibility} from "store/Locker/lockerSlice";

import {Icon} from "components/atoms";

const LockerConvertEligibility = () => {
    const {loading, data: eligibilityItems, success} = useSelector(state => state.locker.konvert_eligibility)
    const isEligibility = success ? eligibilityItems.find(item => item.eligibility === true) : false
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getUserEligibility())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const textStyle = classNames(
        {"eligibility": isEligibility && !loading},
        {"no-eligibility": !isEligibility && !loading},
        {"skeleton": loading}
    )

    const text = success ? `You are ${isEligibility ? "" : "not"} eligible to konvert your in-game items.` : ""

    return (
        <div className="locker_cosmetics_content_general_konvert_eligibility">
            <h2>Eligibility</h2>
            <p className={textStyle}>{text}</p>
            <div className="locker_cosmetics_content_general_konvert_eligibility_items">
                {eligibilityItems.map((item, index) =>
                    <EligibilityItem
                        key={index}
                        isLoading={loading}
                        text={item.description}
                        isEligibility={item.eligibility}
                    />
                )}
            </div>
        </div>
    )
}
export default LockerConvertEligibility

const EligibilityItem = ({isEligibility, text, isLoading}) => {
    const eligibilityItemStyle = classNames(
        "locker_cosmetics_content_general_konvert_eligibility_items_item",
        {"skeleton": isLoading},
        {"display-hidden": isLoading},
    )

    const eligibilityIcon = isEligibility ? "eligibility" : "noEligibility"

    return (
        <div className={eligibilityItemStyle}>
            <div>{text}</div>
            <div><Icon name={eligibilityIcon}/></div>
        </div>
    )
}


