import {useSelector} from "react-redux";

export const useAuthorization = () => {
    const {signIn} = useSelector(state => state.authV2)
    const accessToken = localStorage.getItem("accessToken")
    const isAuth = accessToken && signIn.success

    return {
        isAuth,
        accessToken,
    }
}