import classNames from "classnames";

import React, {useRef} from "react";

import {SelectDropDownDisplay} from "components/atoms";
import {useWindowSize} from "hooks";

import Pagination from "../index";

import "./style.scss"

const PaginationManageBlock = (
    {
        isChange,
        limitData,
        limitValue,
        callBackLimit,
        currentPage,
        totalNft,
        callBackCurrentPage
    }
) => {
    const {width} = useWindowSize()
    const totalPage = Math.ceil(totalNft / limitValue.value)
    const totalInterval = (currentPage * Number(limitValue.value))
    const paginationRef = useRef(null)
    const paginationMaxWidth = (totalPage > 4 && width < 1200) ? paginationRef.current?.getBoundingClientRect().width : ""

    const paginationStyle = classNames(
        "pagination-manage-block",
        {"changed-order": width > 991 && width < 1300 && isChange},
        {"pagination-wrap": totalPage > 4},
        {"pagination-unwrap": totalPage <= 4}
    )

    return (
        <React.Fragment>
            {totalNft > 12 &&
                <div className={paginationStyle} style={{"maxWidth": paginationMaxWidth}}>
                    <div className="pagination-manage-block_limit-select">
                        <SelectDropDownDisplay
                            optionsList={limitData}
                            optionListPosition="top"
                            name="limit"
                            onChange={(e) => callBackLimit(e, "limit")}
                            value={limitValue}
                            btnText="12"
                            size="sm"
                        />
                    </div>
                    <div className="pagination-manage-block_pagination">
                        {limitValue.value < totalNft &&
                            <Pagination
                                currentButton={Number(currentPage)}
                                setCurrentButton={(e) => callBackCurrentPage(e, "page")}
                                page={totalPage}
                                paginationRef={paginationRef}
                            />}
                    </div>
                    <div className="pagination-manage-block_results">
                <span>Results: &nbsp;
                    {Number(currentPage) === 1 ? "0" : (Number(currentPage) - 1) * limitValue.value} -&nbsp;
                    {Number(currentPage) === 1
                        ? limitValue.value >= totalNft ? totalNft : limitValue.value
                        : totalInterval >= totalNft
                            ? totalNft
                            : totalInterval
                    } of {totalNft}
                </span>
                    </div>
                </div>}
        </React.Fragment>
    )
}
export default PaginationManageBlock