import React from 'react';

import {Icon} from "components/atoms";

const LimitedTimeShopLoading = () => (
    <div className="shop-limited_loading-container">
        <div className="shop-limited_loading-container_block">
            <Icon name="loading"/>
            <p>loading please wait...</p>
        </div>
    </div>
)


export default LimitedTimeShopLoading