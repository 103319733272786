import {useEffect, useState} from "react";

export const useDetectMobile = () => {

    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        setIsMobile(result)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];

    let result = toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });

    return {
        isMobile
    };
}