import {Link} from "react-router-dom";
import React from "react";

import WebsiteRightsContent from "../PrivacyPolicy/WebsiteRightsContent";
import DefaultLayout from "layouts/DefaultLayout";
import {Seo, PageMain} from "components/atoms";

import {png} from "assets/png";
import "./style.scss"

const Delete = () => {
    return (
        <DefaultLayout>
            <Seo
                title="Delete - KOMPETE"
                description=""
            />
            <PageMain
                page="delete"
                originalImage={png.HeroPrivacy}
                resizeImage={png.HeroPrivacyResized}
                title="DELETE KOMPETE ACCOUNT OR DATA"
                filter={true}
            />
            <WebsiteRightsContent className="delete">
                <h6>Updated December 2, 2023</h6>
                <div className="delete_under-title">
                    <p><span>Game:</span> KOMPETE</p>
                    <p><span>Publisher & Studio:</span> Modernize Games</p>
                </div>
                <div className="delete_content">
                    <h3>How To Delete Your KOMPETE Account</h3>
                    <p>To request account deletion, contact us at&nbsp;<a href="mailto:legal@modernizegames.com">
                        legal@modernizegames.com</a> with the subject 'Account Deletion'. In your email, include your
                        account's email address and in-game nickname.</p>

                    <h3>How To Delete Your KOMPETE Data</h3>
                    <p>If you have previously agreed to KOMPETE using your personal information and data, you may
                        request to have your data deleted. To request data deletion, contact us at&nbsp;<a
                            href="mailto:legal@modernizegames.com">legal@modernizegames.com</a> with the subject
                        ‘Account Data’. In your email, include your account’s e-mail and in-game nickname.</p>

                    <h3>What Type of Data is Deleted or Kept?</h3>
                    <p>Please note that after deleting your KOMPETE Account, you will lose all of your personal info and
                        the data about your purchases and subscriptions that are linked to it. You also will not be able
                        to link these transactions in the future if you decide to create a new KOMPETE Account.</p>

                    <h3>Learn More</h3>
                    <p>To learn more about how we manage account data, please go review our&nbsp;
                        <Link to="/privacy">Privacy Policy</Link> and <Link to="/terms">Terms</Link>.</p>
                </div>
            </WebsiteRightsContent>
        </DefaultLayout>
    )
}

export default Delete