import {useEffect, useState} from "react";
export const useWindowResize = (element, updateSize) => {
    const [componentLoad, setComponentLoad] = useState(false)
    const [data, setData] = useState({
        width: !!element ? element?.current?.offsetWidth : window.innerWidth,
        height: !!element ? element?.current?.offsetHeight : window.innerHeight,
    });
    useEffect(() => {
        setComponentLoad(true)
    }, [])

    useEffect(() => {
        const handleResize = () => {
            setData({
                width: !!element ? element?.current?.offsetWidth : window.innerWidth,
                height: !!element ? element?.current?.offsetHeight : window.innerHeight,
            });
        };
        handleResize();
        window.addEventListener?.("resize", handleResize);
        return () => window.removeEventListener?.("resize", handleResize);
    }, [element, componentLoad, updateSize]);
    return {width: data.width ?? 0, height: data.height ?? 0};
};