import {Navigate, useLocation, useParams} from "react-router-dom";
import classNames from "classnames";
import React, {memo} from 'react';

import {useGetCurrentInventoryQuery} from "store/Locker/locker.api";

import LockerCurrentCosmeticDescription from "./LockerCurrentCosmeticDescription";
import CosmeticViewTemplate from "components/molecules/CosmeticViewTemplate";
import {Icon} from "components/atoms";

import "./style.scss"

const LockerCurrentCosmeticWeb2Section = () => {
    const {state} = useLocation()
    const {sku} = useParams()

    const {data, isLoading} = useGetCurrentInventoryQuery(sku)

    const currentCosmeticStyle = classNames("locker-current-cosmetic_section", data?.rarity ?? "ordinary")

    if (!state?.request) {
        return <Navigate to={"/locker"}/>
    }

    return (
        <section className={currentCosmeticStyle}>
            <CosmeticViewTemplate
                isShare={false}
                title={data?.name}
                loading={isLoading}
                rarity={data?.rarity}
                img={data?.image_url}
                attribute={data?.groups}
                classname="locker-current-cosmetic_section_content container"
            >
                <div className="locker-current-cosmetic_section_web2">
                    <p><Icon name="unTradeable"/> This cosmetic item cannot be listed</p>
                </div>
                <LockerCurrentCosmeticDescription description={data?.description} isLoading={isLoading}/>
            </CosmeticViewTemplate>
        </section>
    )
}

export default memo(LockerCurrentCosmeticWeb2Section)