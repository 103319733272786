import {ReactPlayer} from "components/atoms";
import React from "react";

const DownloadGameVideo = () => {
    return <ReactPlayer
        src="https://player.vimeo.com/video/894403641?h=3a75b39898&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&muted=1&autoplay=1&loop=1&controls=0"
        title="VSL_02_4K.mp4"
    />
}

export default DownloadGameVideo