import React from "react"

import {useWindowSize} from "hooks";

import HomeCurrentGameMode from "components/organisms/Home/HomeCurrentGameMode";
import DeviceCardBlock from "components/organisms/Home/DeviceCardBlock";
import HomeComingSoon from "components/organisms/Home/HomeComingSoon";
import HomeEngine from "components/organisms/Home/HomeEngine";
import HomeSpacs from "components/organisms/Home/HomeSpacs";
import HomeNews from "components/organisms/Home/HomeNews";
import HomeMain from "components/organisms/Home/HomeMain";
import HomeWhy from "components/organisms/Home/HomeWhy";
import DefaultLayout from "layouts/DefaultLayout";
import Seo from "components/atoms/Seo";

const Home = () => {
    const {width} = useWindowSize()

    return (
        <DefaultLayout>
            <Seo
                title="KOMPETE: Team-Based Multiplayer Game"
                description="KOMPETE is a team-based multiplayer game with Battle Royale, Kart Race, Golf, Basketball & More. Free to play with Crossplay."
            />
            <HomeMain/>
            <React.Fragment>{width < 991 && <DeviceCardBlock/>}</React.Fragment>
            <HomeCurrentGameMode/>
            <HomeComingSoon/>
            <HomeWhy/>
            <HomeNews/>
            <HomeEngine/>
            <HomeSpacs/>
        </DefaultLayout>
    )
}
export default Home