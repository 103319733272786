import {createSlice, createAsyncThunk} from "@reduxjs/toolkit"
import postService from "./postService"

const initialState = {
    posts: null,
    loading: false,
    success: false,

    content: {
        loading: false,
        success: false,
        currentPost: null
    },

    faqs: {
        loading: false,
        success: false,
        result: null
    }
}

//get frequently asked questions
export const getFAQ = createAsyncThunk("get/faq", async (_, thunkAPI) => {
    try {
        return await postService.getFAQ()
    } catch (error) {
        return thunkAPI.rejectWithValue(error)
    }
})

//get first nine posts
export const getPosts = createAsyncThunk('get/posts/limited/9', async (data, thunkAPI) => {
    try {
        return await postService.getPostsLimited(data)
    } catch (error) {
        return thunkAPI.rejectWithValue(error)
    }
})

//get current post content
export const getCurrentPostContent = createAsyncThunk('news/post/content', async (slag, thunkAPI) => {
    try {
        return await postService.getCurrentPostContent(slag)
    } catch (error) {
        return thunkAPI.rejectWithValue(error)
    }
})

export const postSlice = createSlice({
    name: 'posts',
    initialState,
    reducers: {
        reset: (state) => {
            state.content.loading = false
            state.content.success = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPosts.pending, (state) => {
                state.loading = true
            })
            .addCase(getPosts.fulfilled, (state, action) => {
                state.loading = false
                state.success = true
                state.posts = action.payload.posts
            })
            .addCase(getPosts.rejected, (state, action) => {
                state.loading = false
                state.success = false
            })

            .addCase(getCurrentPostContent.pending, (state) => {
                state.content.loading = true
            })
            .addCase(getCurrentPostContent.fulfilled, (state, action) => {
                state.content.loading = false
                state.content.success = true
                state.content.currentPost = action.payload.post
            })
            .addCase(getCurrentPostContent.rejected, (state, action) => {
                state.content.loading = false
                state.content.success = false
            })

            .addCase(getFAQ.pending, (state) => {
                state.faqs.loading = true
            })
            .addCase(getFAQ.fulfilled, (state, action) => {
                state.faqs.loading = false
                state.faqs.success = true
                state.faqs.result = action.payload.faqs
            })
            .addCase(getFAQ.rejected, (state, action) => {
                state.faqs.loading = false
                state.faqs.success = false
            })
    }
})

export const {reset} = postSlice.actions
export default postSlice.reducer

