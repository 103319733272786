import React from "react";

import {Image} from "components/atoms";

import {svg} from "assets/svg";
import "./style.scss"

const Blocked = ({text}) => {
    return (
        <div className="blocked">
            <div>
                <Image src={svg.Require}/>
                <p>{text}</p>
            </div>
        </div>
    )
}

export default Blocked