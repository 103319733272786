import {png} from "assets/png";

import {
    SORT_OPTIONS_V2,
    GROUPS_FILTRATION_DATA,
    STANDARD_LIMIT_OPTIONS,
    CHAINS_FILTRATION_DATA,
    RARITIES_FILTRATION_DATA
} from "./global";

export const BAZAAR_TABS = {
    ITEMS: "items",
    ACTIVITY: "activity",
    TOKEN: "token",
}

export const BAZAAR_TABS_OPTION = [
    {id: BAZAAR_TABS.ITEMS, name: "Items for Sale", icon: png.OwnedItemsTab},
    {id: BAZAAR_TABS.ACTIVITY, name: "Activity", icon: png.ActivityTab},
    {id: BAZAAR_TABS.TOKEN, name: "KOMPETE Token", icon: png.KompeteCoin},
]

export const INVENTORY_FILTRATION_DATA_BAZAAR = {
    page: {value: 1},
    orderBy: {
        data: SORT_OPTIONS_V2,
        value: SORT_OPTIONS_V2[3]
    },
    limit: {
        data: STANDARD_LIMIT_OPTIONS,
        value: STANDARD_LIMIT_OPTIONS[2],
    },
    filter: [
        CHAINS_FILTRATION_DATA,
        RARITIES_FILTRATION_DATA,
        GROUPS_FILTRATION_DATA
    ]
}

export const ORDERS_REQUEST_DEFAULT_DATA_BAZAAR = {
    limit: 36,
    offset: 0,
    value: "",
    orderBy: "EXPIRES_SOON",
    groups: [],
    rarities: [],
    chains: []
}