import {CHAIN_ID} from "./enum";
import config from "web3/config";

export const CHAINS_ENVIRONMENT = config.networkEnvironment ?? "mainNet"

export const SUPPORTED_CHAINS_ARRAY_MAINNET = [CHAIN_ID.ETH_MAINNET, CHAIN_ID.BASE_MAINNET]

export const SUPPORTED_CHAINS_ARRAY_TESTNET = [CHAIN_ID.ETH_SEPOLIA, CHAIN_ID.BASE_SEPOLIA]

export const SUPPORTED_CHAINS_OBJECT_MAINNET = {
    ETH: CHAIN_ID.ETH_MAINNET,
    BASE: CHAIN_ID.BASE_MAINNET,
}

export const SUPPORTED_CHAINS_OBJECT_TESTNET = {
    ETH: CHAIN_ID.ETH_SEPOLIA,
    BASE: CHAIN_ID.BASE_SEPOLIA,
}

export const SUPPORTED_CHAINS = {
    mainNet: SUPPORTED_CHAINS_ARRAY_MAINNET,
    testNet: SUPPORTED_CHAINS_ARRAY_TESTNET
}

export const CHAINS_MAP = {
    mainNet: SUPPORTED_CHAINS_OBJECT_MAINNET,
    testNet: SUPPORTED_CHAINS_OBJECT_TESTNET,
}