import React from "react";

import Image from "components/atoms/Image";
import Title from "components/atoms/TitleTemplate";

import {png} from "assets/png";
import "./style.scss"

const DeckIsland = () => {
    return (
        <section className="deck_island">
            <Title underline={true}><span>Mahzookoo</span>island</Title>
            <p className="deck_island_underTitle">Get ready for an adventure like no other on Mahzookoo Island, the ultimate
                destination for KOMPETETITORS. You'll find yourself amid a battle royale-style landscape filled with POIs, race tracks,
                basketball courts, golf courses, and more.</p>
            <p className="deck_island_underTitle">
                But the fun doesn't stop there! Mahzookoo Island is constantly expanding with new playable locations and exciting
                future game modes that are added all the time. Say goodbye to the mainland and hello to Mahzookoo Island!!!
            </p>
            <div className="deck_island_content container">
                <div className="deck_island_content_island-block">
                    <Image alt="island" src={png.GameMap}/>
                </div>
                <div className="deck_island_content_game-view-block">
                    <div className="deck_island_content_game-view-block_column">
                        <Image alt="game-view" src={png.IslandPart}/>
                        <Image alt="game-view" src={png.IslandPart2}/>
                    </div>
                    <div className="deck_island_content_game-view-block_column">
                        <Image alt="game-view" src={png.IslandPart3}/>
                        <Image alt="game-view" src={png.IslandPart4}/>
                    </div>
                    <div className="deck_island_content_game-view-block_column">
                        <Image alt="game-view" src={png.IslandPart5}/>
                        <Image alt="game-view" src={png.IslandPart6}/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default DeckIsland