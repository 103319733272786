import React, {memo} from "react";

import {useGlobalModalsContext} from "layouts/context/GlobalModalsProvider";
import {GLOBAL_MODALS} from "utils";

import ForgotPasswordModal from "./forgotPasswordModal";
import CreateNicknameModal from "./createNicknameModal";
import ConnectWalletModal from "./connectWalletModal";
import LoginAlertModal from "./loginAlertModal";
import ProcessModal from "./processModal";
import SuccessModal from "./successModal";
import SignInModal from "./signInModal";
import SignUpModal from "./signUpModal";
import ErrorModal from "./errorModal";

const GlobalModals = () => {
    const {modal} = useGlobalModalsContext()

    return (
        <React.Fragment>
            {
                {
                    [GLOBAL_MODALS.ERROR]: <ErrorModal/>,
                    [GLOBAL_MODALS.SIGN_IN]: <SignInModal/>,
                    [GLOBAL_MODALS.SUCCESS]: <SuccessModal/>,
                    [GLOBAL_MODALS.PROCESS]: <ProcessModal/>,
                    [GLOBAL_MODALS.CREATE_ACCOUNT]: <SignUpModal/>,
                    [GLOBAL_MODALS.LOGIN_ALERT]: <LoginAlertModal/>,
                    [GLOBAL_MODALS.CONNECT_WALLET]: <ConnectWalletModal/>,
                    [GLOBAL_MODALS.CHANGE_PASSWORD]: <ForgotPasswordModal/>,
                    [GLOBAL_MODALS.CREATE_NICKNAME]: <CreateNicknameModal/>
                }[modal]
            }
        </React.Fragment>
    )
}

export default memo(GlobalModals);