import React, {memo} from "react";

import {TransactionHistorySection} from "components/molecules";
import {Accordion} from "components/atoms";

const LockerCurrentCosmeticTransactionHistory = ({data, transactionHistorySection}) => (
    <section className="container locker-transaction-history-section">
        <Accordion
            open={true}
            title="Activity"
            icon="activities"
        >
            <TransactionHistorySection
                data={data}
                transactionHistorySection={transactionHistorySection}
            />
        </Accordion>
    </section>

)

export default memo(LockerCurrentCosmeticTransactionHistory)