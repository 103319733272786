import React from "react";
import {Image} from "components/atoms";
import {png} from "assets/png";

const TableNoBody = ({headLength}) => {
    return (
        <tbody>
        <tr>
            <td className="table-container_main_no-body" colSpan={headLength}>
                <Image alt="reject" src={png.SadSmile}/>
                <p> You don’t have any activity yet!</p>
            </td>
        </tr>
        </tbody>
    )
}

export default TableNoBody