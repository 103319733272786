import React, {useRef} from "react";

import {BAZAAR_TABS, BAZAAR_TABS_OPTION} from "utils";

import HowToBuyTokenTwo from "../Token/HowToBuyKompeteToken/HowToBuyTokenTwo";
import {TabContent, TabDynamicBlock} from "components/molecules";
import BazaarListedCosmetics from "./BazaarListedCosmetics";
import TokenWhitePaper from "../Token/TokenWhitePaper";
import TokenERC20 from "./KompeteToken/tokenERC20";
import BazaarActivity from "./BazaarActivity";
import TokenSeven from "../Token/TokenSeven";
import TokenThree from "../Token/TokenThree";
import TokenNine from "../Token/TokenNine";
import Token from "./KompeteToken/token";
import TokenSix from "../Token/TokenSix";

import "./style.scss"

const BazaarCosmetics = ({activeTab, changeTab}) => {
    const bazaarTabRef = useRef(null)

    return (
        <section className="bazaar_cosmetics">
            <div className="bazaar_cosmetics_tabs" ref={bazaarTabRef}>
                <TabDynamicBlock
                    activeTab={activeTab}
                    isSetUrlParams={false}
                    setActiveTab={changeTab}
                    options={BAZAAR_TABS_OPTION}
                />
            </div>
            <div className="bazaar_cosmetics_content">
                <TabContent id={BAZAAR_TABS.ITEMS} activeTab={activeTab}>
                    <BazaarListedCosmetics/>
                </TabContent>
                <TabContent id={BAZAAR_TABS.ACTIVITY} activeTab={activeTab}>
                    <BazaarActivity/>
                </TabContent>
                <TabContent id={BAZAAR_TABS.TOKEN} activeTab={activeTab}>
                    <Token/>
                    <TokenERC20/>
                    <HowToBuyTokenTwo/>
                    <TokenSix  setActiveTab={changeTab}/>
                    <TokenSeven/>
                    <TokenWhitePaper/>
                    <TokenThree/>
                    <TokenNine/>
                </TabContent>
            </div>
        </section>
    )
}

export default BazaarCosmetics