import React,{memo} from "react";
import classNames from "classnames";
import "./style.scss"

const Rarity = ({text, rarity, size}) => {
    const rarityDetails = classNames("rarity-block", rarity, size ?? "lg")

    return (
        <span className={rarityDetails}>
            <span>{text}</span>
        </span>
    )
}

export default memo(Rarity)