import ParentSize from '@visx/responsive/lib/components/ParentSize';
import React from 'react';

import AreaPeriodButtons from "./AreaPeriodButtons";
import Area from './Area';

import "./style.scss"

const Areas = ({isAxisRight, isAxisBottom, data, margin, isPeriodButtons, currentPeriodCallBack, checkedPeriod}) => (
    <div className="area">
        {isPeriodButtons &&
            <AreaPeriodButtons
                checkedPeriod={checkedPeriod}
                currentPeriodCallBack={currentPeriodCallBack}
                isDisabled={data.length < 2 && checkedPeriod === "ALL"}
            />}
        <ParentSize>{({width}) =>
            <Area
                data={data}
                width={width}
                margin={margin}
                isAxisRight={isAxisRight}
                isAxisBottom={isAxisBottom}
                height={width > 991 ? 350 : 200}
            />}
        </ParentSize>
    </div>
)

export default Areas