import React from "react";

import {useGlobalModalsContext} from "layouts";
import {PROCESS} from "utils";

import {Modal, LoadSpinner} from "components/atoms";

import "./style.scss"

const ProcessModal = () => {
    const {message} = useGlobalModalsContext()

    const fallbackMessage = {
        process: PROCESS.SIGNATURE,
        info: PROCESS.APPROVAL
    };

    const displayedMessage = message ? message : fallbackMessage;

    return (
        <Modal isOpen={true}>
            <div className="process-modal">
                <LoadSpinner/>
                <p className="process-modal_process-text">{displayedMessage.process}</p>
                <p className="process-modal_info-text">{displayedMessage.info}</p>
            </div>
        </Modal>
    )
}

export default ProcessModal;