import React from "react"
import "./style.scss"

const TabBlockMobile = ({children}) =>{
    return(
        <div className={`tab-block-mobile`}>
            {children}
        </div>
    )
}

export default TabBlockMobile