import React from "react";
import classNames from "classnames";

const EligibilityBlock = ({isEligible}) => {
    const blockStyle = classNames("shop-divine_content_eligibility-block", {eligible: isEligible})

    return (
        <div className={blockStyle}>
            <span>{isEligible ? "Eligible" : "Not Eligible"}</span>
        </div>
    )
}

export default EligibilityBlock