import React from "react";

import {useModalsContext} from "layouts";
import {STEPS_STATUS} from "utils";

import LimitedTimeCurrentCosmeticSuccessful from "./LimitedTimeCurrentCosmeticSuccessful";
import LimitedTimeCurrentCosmeticOrderModal from "./LimitedTimeCurrentCosmeticOrderModal";

const LimitedTimeCurrentCosmeticSteps = ({quantity}) => {
    const {currentModal, setCurrentModal} = useModalsContext()

    return (
        <React.Fragment>
            {
                {
                    [STEPS_STATUS.ORDER]:
                        <LimitedTimeCurrentCosmeticOrderModal
                            show={true}
                            quantity={quantity}
                        />,
                    [STEPS_STATUS.SUCCESS]:
                        <LimitedTimeCurrentCosmeticSuccessful
                            show={true}
                            quantity={quantity}
                            setWhichModalOpen={() => setCurrentModal({...currentModal, status: ""})}
                        />,
                }[currentModal.status]
            }
        </React.Fragment>
    )
}

export default LimitedTimeCurrentCosmeticSteps