import React, {memo} from "react";
import "./style.scss"

const QuantityCalculator = ({value, setValue, limit}) => {

    const handleChange = (event) => {
        if (event === "minus") if (value > 1) setValue(--value)
        if (event === "plus") if (value < limit) setValue(++value)
        if (event.target) {
            const {value} = event.target
            if (value && value > 1) {
                if (value[0] === "0") {
                    setValue(value.split("").splice(1).join(""))
                } else {
                    if (value >= 1 && value <= 5) setValue(value)
                }
            } else {
                setValue(1)
            }
        }
    }

    return (
        <div className="quantity-calculator">
            <button onClick={() => handleChange("minus")} className="quantity-calculator_minus">
                <span>-</span>
            </button>
            <div className="quantity-calculator_input-blok">
                <input onChange={(e) => handleChange(e)} value={value} type="number"/>
            </div>
            <button onClick={() => handleChange("plus")} className="quantity-calculator_plus">
                <span>+</span>
            </button>
        </div>
    )
}

export default memo(QuantityCalculator)