import {createFetch} from "config/createFetch";
import {errorHandler, SERVER} from "utils";
import {BAZAAR} from "../endpoint";

const checkOrderAvailability = async (data) => {
    return createFetch(SERVER.MARKET, BAZAAR.CHECK_ORDER, "POST", {authorization: false}, data)
        .then(res => res.data)
        .catch(error => errorHandler(error?.message ?? error))
}

export const bazaarService = {
    checkOrderAvailability
}