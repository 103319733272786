import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {lockerService} from "./lockerService";
import {eligibility} from "utils";

const initialState = {
    konvert_eligibility: {
        loading: true,
        error: false,
        success: false,
        errorMessage: null,
        data: new Array(3).fill(""),
    },
    listCurrentCosmetic: {
        loading: false,
        error: false,
        success: false,
        errorMessage: null,
        data: null,
    },
    currentCosmetic: {
        loading: true,
        error: false,
        success: false,
        errorMessage: null,
        data: null,
    },
    currentListedCosmetic: {
        loading: true,
        error: false,
        success: false,
        errorMessage: null,
        data: null,
    },

    refetchInventory: false
}

export const getUserEligibility = createAsyncThunk("get/user/eligibility", async (data, thunkAPI) => {
    try {
        return await lockerService.getUserEligibility(thunkAPI)
    } catch (error) {
        return thunkAPI.rejectWithValue("Something went wrong")
    }
})

export const getUserCurrentListedCosmetic = createAsyncThunk("get/user/current/listed/cosmetics", async (data, thunkAPI) => {
    try {
        return await lockerService.getUserListedCurrentCosmetic(data, thunkAPI)
    } catch(error) {
        return thunkAPI.rejectWithValue("Something went wrong")
    }
})

export const getCurrentCosmetic = createAsyncThunk("get/current/cosmetic", async (data, thunkAPI) => {
    try {
        return await lockerService.getCurrentCosmetic(data, thunkAPI)
    } catch (error) {
        return thunkAPI.rejectWithValue("Something went wrong")
    }
})

const lockerSlice = createSlice({
    name: "locker",
    initialState,
    reducers: {
        reset: (state, data) => {
            const {section, defaultData, isSetLoading = true} = data.payload

            state[section].loading = isSetLoading
            state[section].error = false
            state[section].success = false
            state[section].errorMessage = null
            state[section].data = defaultData ?? null
        },
        setData: (state, action) => {
            const {path1, path2, data} = action.payload

            state[path1][path2] = data
        },
        setRefetchInventory: (state) => {
            state.refetchInventory = true;
        },
        resetRefetchInventory: (state) => {
            state.refetchInventory = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserEligibility.pending, (state) => {
                state.konvert_eligibility.loading = true
            })
            .addCase(getUserEligibility.fulfilled, (state, action) => {
                state.konvert_eligibility.loading = false
                state.konvert_eligibility.success = true
                state.konvert_eligibility.error = false
                state.konvert_eligibility.errorMessage = null
                state.konvert_eligibility.data = action.payload
            })
            .addCase(getUserEligibility.rejected, (state, action) => {
                state.konvert_eligibility.loading = false
                state.konvert_eligibility.success = false
                state.konvert_eligibility.error = true
                state.konvert_eligibility.data = eligibility
                state.konvert_eligibility.errorMessage = action.payload
            })

            .addCase(getUserCurrentListedCosmetic.pending, (state) => {
                state.currentListedCosmetic.loading = true
            })
            .addCase(getUserCurrentListedCosmetic.fulfilled, (state, action) => {
                state.currentListedCosmetic.loading = false
                state.currentListedCosmetic.success = true
                state.currentListedCosmetic.error = false
                state.currentListedCosmetic.errorMessage = null
                state.currentListedCosmetic.data = action.payload
            })
            .addCase(getUserCurrentListedCosmetic.rejected, (state, action) => {
                state.currentListedCosmetic.loading = false
                state.currentListedCosmetic.success = false
                state.currentListedCosmetic.error = true
                state.currentListedCosmetic.errorMessage = action.payload
            })

            .addCase(getCurrentCosmetic.fulfilled, (state, action) => {
                state.currentCosmetic.loading = false
                state.currentCosmetic.success = true
                state.currentCosmetic.error = false
                state.currentCosmetic.errorMessage = null
                state.currentCosmetic.data = action.payload
            })
            .addCase(getCurrentCosmetic.rejected, (state, action) => {
                state.currentCosmetic.loading = false
                state.currentCosmetic.success = false
                state.currentCosmetic.error = true
                state.currentCosmetic.errorMessage = action.payload
            })
    }
})

export const {reset, setData, setRefetchInventory, resetRefetchInventory} = lockerSlice.actions
export default lockerSlice.reducer