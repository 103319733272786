import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import reportWebVitals from './reportWebVitals';

import {Web3ModalProvider} from "web3"

import App from './App';

import {store} from "./store";

import "./scss/main.scss"

ReactDOM.render(
    <Provider store={store}>
        <Web3ModalProvider>
            <App/>
        </Web3ModalProvider>
    </Provider>,
    document.getElementById('root')
);

reportWebVitals();
