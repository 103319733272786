import {useLocation} from "react-router-dom";
import classNames from "classnames";
import React from "react";

import DownloadGeneralContent from "./DownloadGeneralContent";
import DownloadMobileContent from "./DownloadMobileContent";
import DownloadPcContent from "./DownloadPcContent";

import "./style.scss"

const DownloadMain = ({isBanner}) => {
    const downloadMainStyle = classNames("download_main", {'banner': isBanner})
    const {pathname} = useLocation()

    return (
        <section className={downloadMainStyle}>
            {
                {
                    "/lp/download": <DownloadGeneralContent/>,
                    "/download": <DownloadGeneralContent/>,
                    "/lp/pc-01": <DownloadPcContent/>,
                    "/lp/mobile-01": <DownloadMobileContent/>
                }[pathname]
            }
        </section>
    )
}

export default DownloadMain
