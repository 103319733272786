module.exports = {
    networkEnvironment: process.env.REACT_APP_NETWORK_ENVIRONMENT || "mainNet",
    chains: {
        mainNet: [
            {
                network: "Ethereum",
                chainId: 1,
                chainIdHex: "0x1",
                explorerUrl: "https://etherscan.io",
                rpcUrl: "https://eth-mainnet.g.alchemy.com/v2/Au6czW17VYwPv3FXb2HoBOrnjYtWCQW_",
                rpcUrlPublic: "https://eth.llamarpc.com",
                rpcApiKey: "Au6czW17VYwPv3FXb2HoBOrnjYtWCQW_",
                graphqlAPI: "/0f947dd65fb4/kompete--382490/KOMPETE-ERC1155-MAINNET/api",
                contractAddress: {
                    marketplace: "0x9feca5714f6Bd3dE25C85de72d3953f6E13dC6fa",
                    factory: "0xBdd0D42F51f5865391CfD988DF1d536f97dC7dc6",
                    collection: "0xc149B933DF9C68b1e4cb0656958C66e80bF3858d",
                    payment: "0x1E0b2992079B620AA13A7c2E7c88D2e1E18E46E9",
                    pool: "0x07840B4825b74cC6ce264bF2743DEe647194f49b"
                },
                nativeCurrency: {
                    name: "ETH",
                    symbol: "ETH",
                    decimals: 18,
                },
            },
            {
                network: "Base",
                chainId: 8453,
                chainIdHex: "0x2105",
                explorerUrl: "https://basescan.org/",
                rpcUrl: "https://base-mainnet.g.alchemy.com/v2/x_eVZhLmGbE3TMaikFifO2a9gbXYO3OY",
                rpcUrlPublic: "https://mainnet.base.org",
                rpcApiKey: "x_eVZhLmGbE3TMaikFifO2a9gbXYO3OY",
                graphqlAPI: "/0f947dd65fb4/kompete--382490/KOMPETE-ERC1155-BASE/api",
                contractAddress: {
                    marketplace: "0xbd4ab595f66d2f13983160964b70bf9b0d4c0cc3",
                    factory: "0x478ECd0e373Bf125e7b375D7352dBe317789fbE9",
                    collection: "0xB52e48048b02D5248D2Fb39774a636880EC7bea6",
                    payment: "0x8f019931375454Fe4EE353427eB94E2E0C9e0a8C",
                    pool: "0xD9CAa6dBe6791fcb7fC9FB59d1A6b3DD8C1C2339"
                },
                nativeCurrency: {
                    name: "Ether",
                    symbol: "ETH",
                    decimals: 18,
                },
            }
        ],
        testNet: [
            {
                network: "Ethereum Sepolia",
                chainId: 11155111,
                chainIdHex: "0xaa36a7",
                explorerUrl: "https://sepolia.etherscan.io/",
                rpcUrl: "https://eth-sepolia.g.alchemy.com/v2/T9M4DV-LUN1MmIcUskTtorKm999R8z4R",
                rpcUrlPublic: "https://rpc.sepolia.org",
                rpcApiKey: "7vj9G0fh2vAWvI1CfWE1cXps0CzvwzBC",
                graphqlAPI: "/0f947dd65fb4/kompete--382490/KOMPETE-ERC1155-SEPOLIA/api",
                contractAddress: {
                    marketplace: "0xBdD83b33BdB1198Daf4651D9aE5e344A6045ff46",
                    factory: "0x6dA563401a24c4539A4e77173Ffd83EC50527ce3",
                    collection: "0x06b8e5C6B5c5aFe1B2C2BBd05c7df449Cf8636ea",
                    payment: "0x77b38AF5404F0A921Ba38d9D99170d2E634B06D8"
                },
                nativeCurrency: {
                    name: 'Ether',
                    symbol: 'ETH',
                    decimals: 18
                },
            },
            {
                network: "Base Sepolia",
                chainId: 84532,
                chainIdHex: "0x14a34",
                currency: "ETH",
                explorerUrl: "https://sepolia.basescan.org/",
                rpcUrl: "https://base-sepolia.g.alchemy.com/v2/XoOCLHkcIJOnOtlgqxA6ZrjTd9nb8UF0",
                rpcUrlPublic: "https://sepolia.base.org",
                rpcApiKey: "XoOCLHkcIJOnOtlgqxA6ZrjTd9nb8UF0",
                graphqlAPI: "/0f947dd65fb4/kompete--382490/KOMPETE-ERC1155-BASE-SEPOLIA/api",
                contractAddress: {
                    marketplace: "0x18aE54889B60BF9aF4dce8f92AC0fe1050c2C15A",
                    factory: "0xcC5f471293957c77D6e35F7e8F630e5fAF3257C4",
                    collection: "0x478ECd0e373Bf125e7b375D7352dBe317789fbE9",
                    payment: "0x8c34ce443b0684455a689100CC0839Db1bdb5e6B",
                    manager: "0x2b5b4549e89Fa1E3e31781dDBcc2f4e36A297993",
                    pool: "0xD9CAa6dBe6791fcb7fC9FB59d1A6b3DD8C1C2339" //mainnet
                },
                nativeCurrency: {
                    name: 'Ether',
                    symbol: 'ETH',
                    decimals: 18
                },
            }
        ]
    }
}