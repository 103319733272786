import React, {memo} from "react";

import {useModalsContext} from "layouts";
import {STEPS_STATUS} from "utils";

import FreeItemClaimSuccessModal from "./FreeItemClaimSuccessModal";

const FreeItemModals = () => {
    const {currentModal} = useModalsContext()

    return (
        <React.Fragment>
            {
                {
                    [STEPS_STATUS.CLAIM_SUCCESS]:
                        <FreeItemClaimSuccessModal show={true}/>
                }[currentModal.status]
            }
        </React.Fragment>
    )
}

export default memo(FreeItemModals)